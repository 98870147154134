import { useState } from "react";
import modalStatusContext from "./modalStatusContext";

const ModalStatusProvider = ({ children }) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [contentModal, setContentModal] = useState();

  function openModal(e) {
    e.stopPropagation();
    setIsOpen(true);
    setContentModal(e.currentTarget.id);
  }

  function closeModal() {
    setIsOpen(false);
  }

  function handleBackdropClick(e) {
    if (e.target.classList.contains("backdrop-modal")) {
      closeModal(); // закрити модальне вікно, якщо клікнуто на бекдроп
    }
  }

  return (
    <modalStatusContext.Provider
      value={{
        modalIsOpen,
        openModal,
        closeModal,
        contentModal,
        handleBackdropClick,
      }}
    >
      {children}
    </modalStatusContext.Provider>
  );
};

export default ModalStatusProvider;
