import apiaxios from "../../common/apiaxios";
import { changeUserCompareCount, changeUserEmail, changeUserPrice, changeUserFavoriteCount, changeUserName, changeUserStatus, changeUserSurname, changeUserDefaultClient, changeUserDefaultFirm, changeUserStaffMember, changeUserTelephone } from "../../redux/userSlice";
import { changeBasketAmount, changeBasketAmountIn, changeBasketClient, changeBasketComent, changeBasketConfirm, changeBasketCount, changeBasketDelivery, changeBasketFirm, changeBasketItems, changeBasketManager, changeBasketOrderID, changeBasketPlanDate, changeBasketPriceScheme, changeBasketUpdateDate } from "../../redux/basketSlice";

export const getStartUserData = async (dispatch) => {
    if (localStorage.getItem("access_token")) {
      await apiaxios
        .get("/users/get_status/")
        .then((result) => {
            dispatch(changeUserStatus(result.data.status));
            dispatch(changeUserFavoriteCount(result.data.favoriteCount));
            dispatch(changeUserCompareCount(result.data.compareCount));
            dispatch(changeUserName(result.data.info.first_name));
            dispatch(changeUserSurname(result.data.info.last_name));
            dispatch(changeUserEmail(result.data.info.email));
            dispatch(changeUserTelephone(result.data.info.telephone));
            dispatch(changeUserPrice(result.data.info.price));
            dispatch(changeUserDefaultClient(result.data.info.default_client));
            dispatch(changeUserDefaultFirm(result.data.info.default_firm));
            dispatch(changeUserStaffMember(result.data.info.staff_member));
            const res = result.data.basket
            let actualOrderID = res.data.basketOrderID ? res.data.basketOrderID : 0;
            let actual_basket = res.data.items ? [...res.data.items] : [];
            let actual_count = res.data.basketCount ? res.data.basketCount : 0;
            let actual_amount = res.data.basketAmount ? res.data.basketAmount : 0;
            let actual_amount_in = res.data.basketAmountIn ? res.data.basketAmountIn : 0;
            let actual_date = res.data.basketDate ? res.data.basketDate : Date().slice(0, 15);
            let actualClient = ((res.data.basketClient['id'] === 0) || (!res.data.basketClient)) ? result.data.info.default_client : res.data.basketClient;
            let actualFirm = ((res.data.basketFirm['id'] === 0) || (!res.data.basketFirm)) ? result.data.info.default_firm : res.data.basketFirm;
            let actualManager = (((res.data.basketManager['id'] === 0) || (!res.data.basketManager))&&(!actualOrderID)) ? result.data.info.staff_member : res.data.basketManager;
            let actualConfirm = res.data.basketConfirm ? res.data.basketConfirm : false;
            let actualPriceScheme = (actual_count > 0) ? res.data.basketPriceScheme : ((result.data.info.price) ? result.data.info.price : "RTL");
            let actualPlanDate = res.data.basketPlanDate ? res.data.basketPlanDate : "";
            let actualComent = res.data.basketComent ? res.data.basketComent : "";
            let actualDelivery = {};
            if (result.data.status === "user") {
                actualDelivery = {
                                    deliveryType: res.data.basketDelivery['deliveryType'] ? res.data.basketDelivery['deliveryType'] : "office",
                                    firstname: res.data.basketDelivery['firstname'] ? res.data.basketDelivery['firstname'] : result.data.info.first_name,
                                    surname: res.data.basketDelivery['surname'] ? res.data.basketDelivery['surname'] : result.data.info.last_name,
                                    telephone: res.data.basketDelivery['telephone'] ? res.data.basketDelivery['telephone'] : result.data.info.telephone,
                                    email: res.data.basketDelivery['email'] ? res.data.basketDelivery['email'] : result.data.info.email,
                                    regionName: "",
                                    cityName: "",
                                    pointName: "",
                                    adressDelivery: "",
                                };
            } else {
                actualDelivery = {
                                    deliveryType: "office",
                                    firstname: "",
                                    surname: "",
                                    telephone: "",
                                    email: "",
                                    regionName: "",
                                    cityName: "",
                                    pointName: "",
                                    adressDelivery: "",
                                };
            }
            let needToUpdateBasket = false
            if (JSON.parse(localStorage.getItem("basketStorage"))) {
                if (
                JSON.parse(localStorage.getItem("basketStorage")).items
                    .length > 0
                ) {
                    let storage_basket = [
                        ...JSON.parse(localStorage.getItem("basketStorage")).items,
                    ];
                    let new_items = [];
                    if (actual_basket.length === 0) {
                        actual_basket = [...storage_basket];
                        actual_amount = JSON.parse(
                        localStorage.getItem("basketStorage")
                        ).basketAmount;
                        actual_count = JSON.parse(
                        localStorage.getItem("basketStorage")
                        ).basketCount;
                    } else {
                        let done = 0;
                        actual_amount = 0;
                        actual_count = 0;
                        for (const item of storage_basket) {
                            done = 0;
                            for (const elem of actual_basket) {
                                if (item.id === elem.id) {
                                    elem.quantity =
                                        Number(elem.quantity) + Number(item.quantity);
                                    done = 1;
                                    break;
                                }
                            }
                            if (done === 0) {
                                new_items.push(item);
                            }
                        }
                    }
                    actual_basket = [...actual_basket, ...new_items];
                    needToUpdateBasket = true
                    localStorage.removeItem("basketStorage");
                }
            }
            if (((actual_date)&&(actual_date !== Date().slice(0, 15)))||(needToUpdateBasket)) {
                const oldBasket = actual_basket;
                apiaxios
                    .post(
                    "/users/update_user_basket/",
                    JSON.stringify(oldBasket),
                    {
                        headers: {
                        "Content-Type": "application/json",
                        },
                    },
                    { withCredentials: true }
                    )
                    .then((result) => {
                        dispatch(changeBasketUpdateDate(Date().slice(0, 15)));
                        dispatch(changeBasketItems([...result.data.basket]));
                        dispatch(changeBasketCount(result.data.basketCount));
                        dispatch(changeBasketAmount(result.data.basketAmount));
                        dispatch(changeBasketAmountIn(result.data.basketAmountIn));
                        if (res.data.basketPriceScheme !== "RTL") {
                            if (!res.data.basketConfirm) {
                                apiaxios
                                    .get("/users/update_basket/", {
                                    params: {
                                        price_scheme: res.data.basketPriceScheme,
                                    },
                                    })
                                    .then((res) => {
                                    dispatch(changeBasketItems(res.data.basket["items"]));
                                    dispatch(changeBasketAmount(res.data.basket["basketAmount"]));
                                    })
                                    .catch((err) => {
                                    console.log(err);
                                    });
                                }
                        }
                    });
            } else {
                dispatch(changeBasketUpdateDate(actual_date));
                dispatch(changeBasketItems(actual_basket));
                dispatch(changeBasketCount(actual_count));
                dispatch(changeBasketAmount(actual_amount));
                dispatch(changeBasketAmountIn(actual_amount_in));
            }
            dispatch(changeBasketClient(actualClient));
            dispatch(changeBasketManager(actualManager));
            dispatch(changeBasketFirm(actualFirm));
            dispatch(changeBasketOrderID(actualOrderID));
            dispatch(changeBasketComent(actualComent));
            dispatch(changeBasketPlanDate(actualPlanDate));
            dispatch(changeBasketConfirm(actualConfirm));
            dispatch(changeBasketPriceScheme(actualPriceScheme));
            dispatch(changeBasketDelivery(actualDelivery));
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
        let actual_basket = [];
        let actual_amount = 0;
        let actual_count = 0;
        let actual_date = Date().slice(0, 15);
        if (JSON.parse(localStorage.getItem("basketStorage"))) {
            actual_basket = JSON.parse(
                localStorage.getItem("basketStorage")
            ).items;
            actual_amount = JSON.parse(
                localStorage.getItem("basketStorage")
            ).basketAmount;
            actual_count = JSON.parse(
                localStorage.getItem("basketStorage")
            ).basketCount;
            actual_date = JSON.parse(
                localStorage.getItem("basketStorage")
            ).basketDate;
            if (actual_date !== Date().slice(0, 15)) {
                const oldBasket = actual_basket;
                apiaxios
                .post(
                    "/users/update_user_basket/",
                    JSON.stringify(oldBasket),
                    {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    },
                    { withCredentials: true }
                )
                .then((res) => {
                    dispatch(changeBasketUpdateDate(Date().slice(0, 15)));
                    dispatch(changeBasketItems([...res.data.basket]));
                    dispatch(changeBasketCount(res.data.basketCount));
                    dispatch(changeBasketAmount(res.data.basketAmount));
                });
            } else {
                dispatch(changeBasketUpdateDate(actual_date));
                dispatch(changeBasketItems(actual_basket));
                dispatch(changeBasketCount(actual_count));
                dispatch(changeBasketAmount(actual_amount));
            }
        } else {
            const newStorage = {
                basketDate: Date().slice(0, 15),
                basketAmount: 0,
                basketCount: 0,
                items: [],
            };
            localStorage.setItem("basketStorage", JSON.stringify(newStorage));
            dispatch(changeBasketUpdateDate(Date().slice(0, 15)));
            dispatch(changeBasketItems([]));
            dispatch(changeBasketCount(0));
            dispatch(changeBasketAmount(0));
        }
        dispatch(changeUserStatus("guest"));
    }
  };