import css from "./Orders.module.css";
import { Link } from "react-router-dom";
import { useState } from "react";
import apiaxios from "../../common/apiaxios";
import { useDispatch, useSelector } from "react-redux";
import {
  changeBasketAmount,
  changeBasketAmountIn,
  changeBasketClient,
  changeBasketComent,
  changeBasketConfirm,
  changeBasketCount,
  changeBasketDelivery,
  changeBasketFirm,
  changeBasketItems,
  changeBasketManager,
  changeBasketOrderID,
  changeBasketPlanDate,
  changeBasketPriceScheme,
  changeBasketUpdateDate,
} from "../../redux/basketSlice";

const OrderInfoStaff = ({ item, statuses, statusList }) => {
  const userStatus = useSelector((state) => state.user.status);
  const [statusOnOff, setStatusOnOff] = useState(false);
  const [activeStatus, setActiveStatus] = useState(statuses[item.status]);
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();

  const selectStatus = (e) => {
    setActiveStatus(e.currentTarget.id);
    for (let key in statuses) {
      if (statuses[key] === e.currentTarget.id) {
        apiaxios.post(
          "/orders/change_status/",
          {
            status: key,
            order: item.id,
          },
          { headers: { "Content-Type": "application/json" } },
          { withCredentials: true }
        );
      }
    }
    setStatusOnOff(!statusOnOff);
  };

  const edit = () => {
    apiaxios
      .get("/orders/get_order_by_id?id=" + item.id)
      .then((res) => {
        dispatch(changeBasketItems([...res.data.items]));
        dispatch(
          changeBasketCount(
            res.data.items.reduce((sum, item) => sum + item.quantity, 0)
          )
        );
        dispatch(changeBasketAmount(res.data.amount));
        dispatch(
          changeBasketAmountIn(
            res.data.items.reduce(
              (sum, item) => sum + item.quantity * item.price_in,
              0
            )
          )
        );
        dispatch(changeBasketOrderID(item.id));
        dispatch(changeBasketUpdateDate(Date().slice(0, 15)));
        dispatch(changeBasketClient(res.data.client));
        dispatch(changeBasketFirm(res.data.firm));
        dispatch(changeBasketManager(res.data.manager));
        dispatch(changeBasketConfirm(res.data.confirm));
        dispatch(changeBasketPriceScheme(res.data.price_scheme));
        dispatch(changeBasketPlanDate(res.data.plan_date));
        dispatch(changeBasketComent(res.data.coment));
        dispatch(changeBasketDelivery(res.data.delivery));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <tr>
        <td className={css["td-open-details"]} onClick={() => setShow(!show)}>
          {item.id}
        </td>
        <td className={css["td-open-details"]} onClick={() => setShow(!show)}>
          {item.date.slice(0, 19).replace("T", " ")}
        </td>
        <td>{item.name}</td>
        <td>{item.amount} ₴</td>
        <td>{item.manager}</td>
        <td>{item.author}</td>
        <td>
          <div
            className={css["status-select-td"]}
            onClick={(e) => {
              if (userStatus === "staff" || userStatus === "super") {
                setStatusOnOff(!statusOnOff);
                e.stopPropagation();
              }
            }}
          >
            {activeStatus}
            {statusOnOff && (
              <div className={css["status-select-container-td"]}>
                {statusList.map((item) => (
                  <div
                    className={css["status-select-item"]}
                    id={item}
                    key={item}
                    onClick={selectStatus}
                  >
                    {item}
                  </div>
                ))}
              </div>
            )}
          </div>
        </td>
        <td onClick={() => edit()}>редагувати</td>
      </tr>
      {show && (
        <tr>
          <td
            colSpan="8"
            style={{ padding: "15px", background: "rgb(250, 250, 250)" }}
          >
            <table>
              <thead>
                <tr>
                  <th>№</th>
                  <th>Товар</th>
                  <th>Ціна</th>
                  <th>К-ть</th>
                  <th>Сума</th>
                  <th>Вендор</th>
                  <th>Ціна вхід</th>
                  <th>К-ть</th>
                  <th>Сума вхід</th>
                </tr>
              </thead>
              <tbody>
                {item.items.map((row) => (
                  <tr key={row.id}>
                    <td>{row.id}</td>
                    <td>
                      <Link
                        to={"/product_details/" + row.id}
                        target="_blank"
                        rel="noopener noreferrer" // Це рекомендовано з міркувань безпеки
                        style={{
                          color: "black",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        className={css["order-history-row-name1"]}
                      >
                        {row.name}
                      </Link>
                    </td>
                    <td>{row.price_im} ₴</td>
                    <td>{row.quantity}</td>
                    <td>{row.price_im * row.quantity} ₴</td>
                    <td>{row.source}</td>
                    <td>{row.price_in} ₴</td>
                    <td>{row.quantity}</td>
                    <td>{row.price_in * row.quantity} ₴</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className={css["order-info-delivery-staff"]}>
              {(userStatus === "super" || userStatus === "staff") &&
                item.delivery === "office" &&
                item.delivery_details && <div>Самовивіз з магазину</div>}
              {(userStatus === "super" || userStatus === "staff") &&
                item.delivery["deliveryType"] !== "office" && (
                  <div>Відправка новою поштою: {item.delivery_details}</div>
                )}
            </div>
            {item.coment && (
              <div className={css["order-info-delivery-staff"]}>
                Коментар: {item.coment}
              </div>
            )}
          </td>
        </tr>
      )}
    </>
  );
};

export default OrderInfoStaff;
