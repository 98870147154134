import css from "./ButtonClose.module.css";

const ButtonClose = ({ redirect = "" }) => {
  return (
    <div
      className={css["button"]}
      onClick={() => {
        if (redirect !== "") {
          window.location.href = redirect;
        }
      }}
    >
      Закрити
    </div>
  );
};

export default ButtonClose;
