import Contacts from "../components/Contacts/Contacts";

const About = () => {
  return (
    <div className="pages-container">
      <div className="page-title">Про Суми ІТ-сервіс</div>
      <div>
        <p>
          Запрошуємо вас в світ інновацій та якісного сервісу з компанією "Суми
          ІТ-сервіс". Наша компанія вже протягом багатьох років надає послуги з
          продажу та обслуговування комп'ютерної та офісної техніки у місті Суми
          та області.
        </p>
      </div>
      <div>
        <p>
          У нас ви знайдете не лише великий вибір якісних товарів, але й
          висококваліфікованих спеціалістів, готових надати вам консультації з
          вибору обладнання, допомогу у вирішенні технічних питань та
          професійний сервіс. Наша компетентна команда завжди на зв'язку і
          готова відповісти на ваші запитання, щоб забезпечити вам максимальний
          комфорт під час вибору та експлуатації техніки.
        </p>
      </div>
      <div>
        <p>
          Наша фірма працює у сфері IT-послуг та продажу комп'ютерної і офісної
          техніки з 2006 року. За цей час ми здобули великий досвід, підготували
          штат досвідчених та висококваліфікованих спеціалістів, які зможуть
          допомогти вам як у виборі нової якісної техніки, так і у ремонті
          техніки, яку ви вже використовуєте. Ми прагнемо максимально
          задовольняти потреби наших клієнтів і для цього використовуємо сучасне
          обладнання, постійно розширюємо і поповнюємо базу запчастин та
          витратних матеріалів. Завдяки багаторічній співпраці з надійними і
          перевіреними постачальниками, ми маємо можливість у стислі терміни
          організувати поставку необхідних вам товарів чи запчастин. Ми
          пропонуємо нашим клієнтам послуги доставки замовленого товару до
          офісу. Багато наших постійних клієнтів високо цінує наші додаткові
          послуги з доставки техніки або картриджів до нашого сервісного центру
          з подальшим поверненням її клієнту з оформленими документами.
        </p>
      </div>
      <div>
        <p>
          Головна мета цього ресурсу - це створення "вітрини" в мережі, де наші
          клієнти, а це, здебільшого, фірми та організації різних форм власності
          з міста Суми та Сумської області, можуть ознайомитись з асортиментом
          товарів, які ми можемо поставляти. Даний ресурс не є роздрібним
          інтернет-магазином в загальному розумінні. У своїй роботі ми керуємося
          принципами постійного та взаємовигідного співробітництва за договорами
          з проведенням взаєморозрахунків у безготівковій формі з повним
          оформленням відповідних документів.
        </p>
      </div>
      <div>
        <p>
          Ми розуміємо, наскільки важливо для вас мати надійного партнера у
          сфері IT, тому наша команда зосереджена на наданні вам якісних послуг
          та високого рівня обслуговування. Ми пропонуємо широкий спектр товарів
          від провідних виробників, а також регулярно оновлюємо наш асортимент,
          щоб задовольнити ваші потреби та вимоги.
        </p>
      </div>
      <div>
        <p>
          Наша мета - це створення довгострокових відносин з кожним клієнтом і
          забезпечення йому максимального комфорту та задоволення від співпраці
          з нами. Ми працюємо для вас, тому будемо раді бачити вас серед наших
          клієнтів та допомогти вам у вирішенні будь-яких завдань у сфері IT.
        </p>
      </div>
      <div>
        <Contacts />
      </div>
      {/* <div><p>Запрошуємо до співпраці з нами - не пошкодуєте!</p></div> */}
      {/* <div>
        <p>
          Наша фірма ТОВ «Суми ІТ-сервіс» працює у сфері IT-послуг та продажу
          комп’ютерної і офісної техніки з 2006 року. За цей час ми здобули
          великий досвід, підготували штат досвідчених та висококваліфікованих
          спеціалістів, які зможуть допомогти Вам як у виборі нової якісної
          техніки, яка буде відповідати Вашим індивідуальним потребам, так і у
          ремонті техніки, яку Ви вже використовуєте. Ми прагнемо максимально
          задовольняти потреби наших клієнтів і для цього використовуємо сучасне
          обладнання, постійно розширюємо і поповнюємо базу запчастин та
          витратних матеріалів. Завдяки багаторічній співпраці з надійними і
          перевіреними постачальниками, ми маємо можливість у стислі терміни
          організувати поставку необхідних Вам товарів чи запчастин. Ми
          пропонуємо нашим клієнтам послуги доставки замовленого товару до
          офісу. Багато наших постійних клієнтів високо цінує наші додаткові
          послуги з доставки техніки або картриджів до нашого сервісного центру
          з подальшим поверненням її клієнту з оформленими документами.
        </p>
      </div>
      <div>
        <p>
          Головна мета цього ресурсу – це створення «вітрини» в мережі, де наші
          клієнти, а це, здебільшого, фірми та організації різних форм власності
          з міста Суми та Сумської області, можуть ознайомитись з асортиментом
          товарів, які ми можемо поставляти. Даний ресурс не є роздрібним
          інтернет-магазином в загальному розумінні. У своїй роботі ми керуємося
          принципами постійного та взаємовигідного співробітництва за договорами
          з проведенням взаєморозрахунків у безготівковій формі з повним
          оформленням відповідних документів.
        </p>
      </div> */}
    </div>
  );
};

export default About;
