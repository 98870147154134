// import map from "../../map-min.jpg";
import office from "../../office.jpg";
import sc from "../../sc.jpg";
import { MdPhone } from "react-icons/md";
import { BsClock, BsSkype, BsTelegram } from "react-icons/bs";
import { FaMapMarkerAlt, FaViber, FaInstagram } from "react-icons/fa";
import { IconContext } from "react-icons";
import styles from "./Contacts.module.css";

const Contacts = () => {
  return (
    <div className={styles.container}>
      <div className={styles.section}>
        <div className="page-title">
          <div className={styles.caption}>
            <div>ОФІС ТА МАГАЗИН</div>
            <div>
              <a
                href="https://www.itservice.net.ua"
                target="_blank"
                rel="noreferrer"
                className={styles.link}
              >
                www.itservice.net.ua
              </a>
            </div>
          </div>
        </div>
        <div className={styles.contacts}>
          <div className={styles.info}>
            <div className={styles.block}>
              <IconContext.Provider value={{ size: "1.3em", color: "grey" }}>
                <div className={styles.icon}>
                  <FaMapMarkerAlt />
                </div>
              </IconContext.Provider>
              <ul className={styles.list}>
                <li>вул. Привокзальна, 15</li>
                <li>
                  <b>м. Суми</b>
                </li>
              </ul>
            </div>
            <div className={styles.block}>
              <IconContext.Provider value={{ size: "1.3em", color: "grey" }}>
                <div className={styles.icon}>
                  <BsClock />
                </div>
              </IconContext.Provider>
              <ul className={styles.list}>
                <li>
                  <b>пн-пт:</b> 08:00 - 17:00
                </li>
                <li>
                  <b>сб-нд:</b> вихідні
                </li>
              </ul>
            </div>
            <div className={styles.block}>
              <IconContext.Provider value={{ size: "1.5em", color: "grey" }}>
                <div className={styles.icon}>
                  <MdPhone />
                </div>
              </IconContext.Provider>
              <ul className={styles.list}>
                <li>
                  <a href="tel:+380542619125">
                    <b>(0542)</b> 61-91-25
                  </a>
                </li>
                <li>
                  <a href="tel:+380667779125">
                    <b>(066)</b> 777-91-25
                  </a>
                </li>
                <li>
                  <a href="tel:+380677779125">
                    <b>(067)</b> 777-91-25
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className={styles.image}>
            <img src={office} alt="logo" width={"350px"} />
          </div>
        </div>
      </div>
      <div className={styles.section}>
        <div className="page-title">
          <div className={styles.caption}>
            <div>СЕРВІСНИЙ ЦЕНТР</div>
            <div>
              <a
                href="https://www.it-service.sumy.ua"
                target="_blank"
                rel="noreferrer"
                className={styles.link}
              >
                www.it-service.sumy.ua
              </a>
            </div>
          </div>
        </div>
        <div className={styles.contacts}>
          <div className={styles.info}>
            <div className={styles.block}>
              <IconContext.Provider value={{ size: "1.3em", color: "grey" }}>
                <div className={styles.icon}>
                  <FaMapMarkerAlt />
                </div>
              </IconContext.Provider>
              <ul className={styles.list}>
                <li>вул. Привокзальна, 25/2</li>
                <li>
                  <b>м. Суми</b>
                </li>
              </ul>
            </div>
            <div className={styles.block}>
              <IconContext.Provider value={{ size: "1.3em", color: "grey" }}>
                <div className={styles.icon}>
                  <BsClock />
                </div>
              </IconContext.Provider>
              <ul className={styles.list}>
                <li>
                  <b>пн-пт:</b> 08:00 - 18:00
                </li>
                <li>
                  <b>сб-нд:</b> вихідні
                </li>
              </ul>
            </div>
            <div className={styles.block}>
              <IconContext.Provider value={{ size: "1.5em", color: "grey" }}>
                <div className={styles.icon}>
                  <MdPhone />
                </div>
              </IconContext.Provider>
              <ul className={styles.list}>
                <li>
                  <a href="tel:+380542619126">
                    <b>(0542)</b> 619-126
                  </a>
                </li>
                <li>
                  <a href="tel:+380509464775">
                    <b>(050)</b> 94-64-775
                  </a>
                </li>
              </ul>
            </div>
            <div className={styles.icon_list}>
              <a
                href="viber://chat?number=+380667779125"
                className={styles.icon_list_item}
              >
                <IconContext.Provider
                  value={{ size: "2em", color: "rgb(95,56,130)" }}
                >
                  <div className={styles.icon}>
                    <FaViber />
                  </div>
                </IconContext.Provider>
              </a>
              <a
                href="https://instagram.com/sumyitservice"
                className={styles.icon_list_item}
              >
                <IconContext.Provider value={{ size: "2em", color: "red" }}>
                  <div className={styles.icon}>
                    <FaInstagram />
                  </div>
                </IconContext.Provider>
              </a>
              <a
                href="https://t.me/sumy_it_service"
                className={styles.icon_list_item}
              >
                <IconContext.Provider
                  value={{ size: "2em", color: "rgb(32,185,242)" }}
                >
                  <div className={styles.icon}>
                    <BsTelegram />
                  </div>
                </IconContext.Provider>
              </a>
              <a href="skype:troynick87?chat" className={styles.icon_list_item}>
                <IconContext.Provider
                  value={{ size: "2em", color: "rgb(32,185,242)" }}
                >
                  <div className={styles.icon}>
                    <BsSkype />
                  </div>
                </IconContext.Provider>
              </a>
            </div>
          </div>
          <div className={styles.image}>
            <img src={sc} alt="logo" width={"350px"} />
          </div>
        </div>
      </div>
      {/* <div className="page-title">Карта</div>
      <div>
        <img src={map} alt="logo" width={"800px"} />
      </div> */}
    </div>
  );
};

export default Contacts;
