import banner from "../../banner_sc.png";
import { Link } from "react-router-dom";

const BannerSC = () => {
  return (
    <div style={{ width: "250px", marginTop: "10px", marginRight: "15px" }}>
      <Link to="https://www.it-service.sumy.ua" target="_blank">
        <img src={banner} alt="banner" width={"250px"} />
      </Link>
    </div>
  );
};

export default BannerSC;
