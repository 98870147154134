import BannerSC from "../components/BannerSC/BannerSC";
import CatalogMenu from "../components/CatalogMenu/CatalogMenu";
import ProductsListPage from "./ProductsListPage";

const Home = () => {
  return (
    <>
      <div className="home-sidebar">
        <CatalogMenu />
        <BannerSC />
      </div>

      <ProductsListPage />
    </>
  );
};

export default Home;
