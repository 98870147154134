import { Outlet } from "react-router-dom";
import Header from "../components/Header/Header";

const Main = () => {
  return (
    <>
      <Header />
      <div className="container-all" id="all">
        <div className="main">
          <Outlet className="content" />
        </div>
      </div>
      <footer>&copy; 2023-2024 SUMY IT-SERVICE</footer>
    </>
  );
};

export default Main;
