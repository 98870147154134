import { useState } from "react";
import css from "./Dictionaries.module.css";
import { IconContext } from "react-icons";
import { FaInfo } from "react-icons/fa";

const InfoBtn = ({ text }) => {
  const [showInfo, setShowInfo] = useState(false);
  return (
    <div
      className={css["dictionary-btn-info"]}
      onClick={() => {
        setShowInfo(!showInfo);
      }}
    >
      <IconContext.Provider value={{ size: "1em" }}>
        <FaInfo />
      </IconContext.Provider>
      {showInfo && text && (
        <div
          className={css["dictionary-show-info"]}
          title="інформація"
          style={{ whiteSpace: "pre-line" }}
        >
          {text}
        </div>
      )}
    </div>
  );
};

export default InfoBtn;
