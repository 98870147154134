import { useState } from "react";
import userStatusContext from "./userStatusContext";

const UserStatusProvider = ({ children }) => {
  const [filtersLabels, setFiltersLabels] = useState([]);
  const [sortTypePrice, setSortTypePrice] = useState("none");
  const [search, setSearch] = useState("");
  const [filters, setFilters] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(
    JSON.parse(localStorage.getItem("productsPerPage"))
      ? JSON.parse(localStorage.getItem("productsPerPage"))
      : 20
  );
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(1000000);
  // const [priceScheme, setPriceScheme] = useState("RTL");
  const [selectVendor, setSelectVendor] = useState("ALL");
  const [vendorBest, setVendorBest] = useState(false);

  return (
    <userStatusContext.Provider
      value={{
        filtersLabels,
        setFiltersLabels,
        sortTypePrice,
        setSortTypePrice,
        search,
        setSearch,
        filters,
        setFilters,
        page,
        setPage,
        perPage,
        setPerPage,
        minPrice,
        setMinPrice,
        maxPrice,
        setMaxPrice,
        // priceScheme,
        // setPriceScheme,
        selectVendor,
        setSelectVendor,
        vendorBest,
        setVendorBest,
      }}
    >
      {children}
    </userStatusContext.Provider>
  );
};

export default UserStatusProvider;
