import Contacts from "../components/Contacts/Contacts";

const Contact = () => {
  return (
    <div className="pages-container">
      <Contacts />
    </div>
  );
};

export default Contact;
