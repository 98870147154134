import css from "./Auth.module.css";
import apiaxios from "../../common/apiaxios";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { NavLink, useSearchParams } from "react-router-dom";

const RestorePassword = ({ modalClose }) => {
  const [searchParams] = useSearchParams();
  const [username, setUsername] = useState("");

  const restorePassword = () => {
    if (username === "") {
      toast.warning("Введіть адресу електронної пошти!");
      return;
    }
    apiaxios
      .get("/users/restore_password/" + username)
      .then((res) => {
        toast.info(res.data.message);
      })
      .catch((err) => {
        console.log(err);
      });
    window.location.href = "/login?type=restore";
  };

  return (
    <div className={css["reg-container"]}>
      <div className="page-title">Відновлення пароля</div>
      {searchParams.get("type") && (
        <div className={css["restore-reg-message"]}>
          Користувач з такою адресою електронної пошти зареєстрований. Спробуйте
          відновити пароль.
        </div>
      )}
      {/* <div className={css["modal-x"]} onClick={modalClose}>
        <svg width="20" height="20">
          <use xlinkHref="#svg-icon__close" />
        </svg>
      </div> */}
      <div className={css["form-group"]}>
        <label>Електронна пошта:</label>
        <input
          className={css["form-control"]}
          name="username"
          type="email"
          autoComplete="username"
          value={username}
          required
          onChange={(e) => setUsername(e.target.value)}
        />
        <div className={css["restore-info"]}>
          Ваш пароль буде скинуто і відправлено Вам на пошту новий, який Ви
          зможете змінити у особистому кабінеті.
        </div>
      </div>
      <div className={css["form-footer"]}>
        <div className={css["form-footer-link-block"]}>
          <NavLink to="/login" className={css["form-footer-link"]}>
            <div>Згадали пароль? Увійти</div>
          </NavLink>
          <NavLink to="/registration" className={css["form-footer-link"]}>
            <div>Реєстрація</div>
          </NavLink>
        </div>
        <div>
          <button onClick={restorePassword} className={css["form-btn"]}>
            Відновити
          </button>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default RestorePassword;
