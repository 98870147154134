import { useContext } from "react";
import modalStatusContext from "../context/modalStatusContext";

const Modal = ({ children, type }) => {
  const { handleBackdropClick } = useContext(modalStatusContext);

  return (
    <div
      className={
        type === "transparent" ? "backdrop-modal-transparent" : "backdrop-modal"
      }
      onClick={handleBackdropClick}
    >
      {children}
    </div>
  );
};

export default Modal;
