import { useState } from "react";
import css from "../Dictionaries.module.css";
import SelectCategoriesGroup from "./SelectCategoriesGroup";

const SelectCategoriesRoot = ({
  item,
  selectedID,
  setSelectedCategory,
  modalClose,
}) => {
  const [show, setShow] = useState(false);

  return (
    <>
      <div
        className={css["modal-root-group-block"]}
        onClick={() => setShow(!show)}
      >
        {show ? "- " : "+ "}
        {item.name}
      </div>
      {show && (
        <div
          className={
            item.top_bottom === "top" ? css["subgroup"] : css["subgroup-bottom"]
          }
        >
          <ul className={css["subgroup-ul"]}>
            {item.items
              .sort((a, b) => a.count - b.count)
              .map(
                (elem) =>
                  elem.items.length === 0 && (
                    <li
                      key={elem.categoryID}
                      id={elem.categoryID}
                      data-name={item.name + " / " + elem.name}
                      className={css["modal-group-block"]}
                      onClick={(e) => {
                        setSelectedCategory({
                          categoryID: e.currentTarget.id,
                          category_name:
                            e.currentTarget.getAttribute("data-name"),
                          id: selectedID,
                        });
                        modalClose();
                      }}
                    >
                      <div className={css["link-block"]}>{elem.name}</div>
                    </li>
                  )
              )}
          </ul>
          <ul className={css["subgroup-ul"]}>
            {item.items
              .sort((a, b) => a.count - b.count)
              .map(
                (elem) =>
                  elem.items.length !== 0 && (
                    <li key={elem.categoryID} className={css["subgroup-wrap"]}>
                      <SelectCategoriesGroup
                        elem={elem}
                        selectedID={selectedID}
                        setSelectedCategory={setSelectedCategory}
                        modalClose={modalClose}
                        rootCategory={item.name}
                      />
                    </li>
                  )
              )}
          </ul>
        </div>
      )}
    </>
  );
};

export default SelectCategoriesRoot;
