import css from "./SelectFromList.module.css";
import { useEffect, useRef, useState } from "react";
import apiaxios from "../../../common/apiaxios";
import { useSelector } from "react-redux";

const SelectFromList = ({
  requestPath,
  label,
  value,
  setValue,
  width = "250px",
  clear = true,
}) => {
  const [valueShow, setValueShow] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [resultList, setResultList] = useState([]);
  const [resultListShow, setResultListShow] = useState(false);
  const [activeElement, setActiveElement] = useState(1);
  const userStatus = useSelector((state) => state.user.status);
  const scrollRef = useRef(null);

  useEffect(() => {
    if (userStatus !== "guest") {
      apiaxios
        .post(
          requestPath,
          { searchText: searchText },
          { headers: { "Content-Type": "application/json" } },
          { withCredentials: true }
        )
        .then((res) => {
          setResultList(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setResultList([]);
    }
  }, [searchText, userStatus, requestPath]);

  const selectElement = (e) => {
    setResultListShow(false);
    setValueShow(true);
    for (const item of resultList) {
      if (item["id"] === Number(e.target.id)) {
        setValue({ id: item["id"], name: item["name"] });
        setSearchText("");
        break;
      }
    }
  };

  const keyPress = (e) => {
    if (e.key === "ArrowUp") {
      if (activeElement > 1) {
        setActiveElement(activeElement - 1);
      }
    } else if (e.key === "ArrowDown") {
      if (activeElement < resultList.length) {
        setActiveElement(activeElement + 1);
      }
    } else if (e.key === "Enter") {
      for (const item of resultList) {
        if (item["num"] === activeElement) {
          setValue({ id: item["id"], name: item["name"] });
          setResultListShow(false);
          setValueShow(true);
          setSearchText("");
          break;
        }
      }
    } else if (e.key === "Escape") {
      setResultListShow(false);
    }
    if (scrollRef.current && activeElement !== 0) {
      const active = scrollRef.current.querySelector(
        `[data-num="${activeElement + 1}"]`
      );
      if (active) {
        const direction = activeElement > active.scrollTop ? "end" : "start";
        active.scrollIntoView({ behavior: "smooth", block: direction });
      }
    }
  };

  const changeElement = () => {
    setValueShow(false);
  };

  const [modalIsOpen, setIsOpen] = useState(false);

  // function openModal(e) {
  //   e.stopPropagation();
  //   setIsOpen(true);
  //   // setContentModal(e.currentTarget.id);
  // }

  function closeModal() {
    setIsOpen(false);
  }

  function handleBackdropClick(e) {
    if (e.target.classList.contains("backdrop-modal")) {
      closeModal(); // закрити модальне вікно, якщо клікнуто на бекдроп
    }
  }

  return (
    <>
      <div className={css["container"]}>
        <div className={css["input-label"]}>
          <label htmlFor="searchText">{label}</label>
        </div>
        <div className={css["input-container"]} style={{ width: width }}>
          {!valueShow && (
            <input
              className={css["input"]}
              id="searchText"
              name="searchText"
              value={searchText}
              placeholder={value ? value["name"] : ""}
              autoComplete="off"
              autoFocus
              required
              onChange={(e) => {
                setSearchText(e.target.value);
                setActiveElement(1);
              }}
              onFocus={() => {
                setResultListShow(true);
                setValueShow(false);
              }}
              onBlur={() => {
                // Затримка зміни стану valueShow на 200 мс, щоб всигав спрацювати onClick={changeElement}>
                setTimeout(() => {
                  setResultListShow(false);
                  setValueShow(true);
                  setSearchText("");
                }, 200);
              }}
              onKeyDown={keyPress}
            />
          )}
          {valueShow && (
            <div style={{ display: "flex" }}>
              <div
                className={css["select-element"]}
                title={value}
                onClick={changeElement}
              >
                {value ? value["name"] : ""}
              </div>
              {clear && (
                <div
                  className={css["select-element-x"]}
                  onClick={() => {
                    setValue({ id: 0, name: "" });
                  }}
                >
                  X
                </div>
              )}
            </div>
          )}
          {resultListShow && (
            <div ref={scrollRef} className={css["select-list"]}>
              {resultList.map((item) => (
                <div
                  key={item["id"]}
                  id={item["id"]}
                  onClick={selectElement}
                  onMouseEnter={() => {
                    setActiveElement(item["num"]);
                  }}
                  onMouseLeave={() => {
                    setActiveElement(0);
                  }}
                  className={
                    activeElement === item["num"]
                      ? css["select-item-active"]
                      : css["select-item"]
                  }
                  data-num={item["num"]} // Додайте атрибут для визначення елементів по їхньому номеру
                >
                  {item["name"]}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      {modalIsOpen && (
        <div className={css["backdrop-modal"]} onClick={handleBackdropClick}>
          <div className={css["dictonary-container"]}>FFFFFFFFFFFFFFF</div>
        </div>
      )}
    </>
  );
};

export default SelectFromList;
