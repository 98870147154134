// import { useState } from "react";
import css from "./CustomInput.module.css";

const CostomInput = ({
  value,
  setValue,
  label,
  type = "text",
  maxlength = "255",
  width = "250px",
}) => {
  // const [value, setValue] = useState(defaultValue);
  return (
    <div className={css["container"]} style={{ display: "flex" }}>
      <div className={css["input-label"]}>
        <label htmlFor="input">{label}</label>
      </div>
      <input
        className={css["input"]}
        id="input"
        name="input"
        type={type}
        maxLength={maxlength}
        value={value}
        // placeholder={elementName}
        autoComplete="off"
        // autoFocus
        // required
        onChange={(e) => {
          setValue(e.target.value);
        }}
        style={{ width: width }}
        // onFocus={() => {
        //   setResultListShow(true);
        //   setElementNameShow(false);
        // }}
        // onBlur={() => {
        //   // Затримка зміни стану elementNameShow на 200 мс, щоб всигав спрацювати onClick={changeElement}>
        //   setTimeout(() => {
        //     setResultListShow(false);
        //     setElementNameShow(true);
        //     setSearchText("");
        //   }, 200);
        // }}
        // onKeyDown={keyPress}
      />
    </div>
  );
};

export default CostomInput;
