import { createSelector } from 'reselect';

// Базовий селектор для корзини
const selectBasket = state => state.basket;

// Меморізований селектор для отримання елементів корзини
export const selectBasketItems = createSelector(
    [selectBasket],
    basket => basket.items
);

// Ви можете додати інші мемоізовані селектори для інших властивостей корзини
export const selectBasketOrderID = createSelector(
    [selectBasket],
    basket => basket.orderID
);

export const selectBasketCount = createSelector(
    [selectBasket],
    basket => basket.count
);

export const selectBasketAmount = createSelector(
    [selectBasket],
    basket => basket.amount
);

export const selectBasketAmountIn = createSelector(
    [selectBasket],
    basket => basket.amountIn
);


export const selectBasketUpdateDate = createSelector(
    [selectBasket],
    basket => basket.updateDate
);


export const selectBasketClient = createSelector(
    [selectBasket],
    basket => basket.client
);

export const selectBasketFirm = createSelector(
    [selectBasket],
    basket => basket.firm
);


export const selectBasketManager = createSelector(
    [selectBasket],
    basket => basket.manager
);


export const selectBasketConfirm = createSelector(
    [selectBasket],
    basket => basket.confirm
);

export const selectBasketPriceScheme = createSelector(
    [selectBasket],
    basket => basket.priceScheme
);


export const selectBasketPlanDate = createSelector(
    [selectBasket],
    basket => basket.planDate
);


export const selectBasketComent = createSelector(
    [selectBasket],
    basket => basket.coment
);


export const selectBasketDelivery = createSelector(
    [selectBasket],
    basket => basket.delivery
);