import { useEffect, useState } from "react";
import css from "../Dictionaries.module.css";
import SelectCategoriesRoot from "./SelectCategoriesRoot";
import apiaxios from "../../../common/apiaxios";

const SelectCategory = ({
  title,
  selectedID,
  setSelectedCategory,
  modalClose,
}) => {
  const [catList, setCatList] = useState([]);

  useEffect(() => {
    apiaxios
      .get("/catalog/get_category_for_catalog_menu/full")
      .then((res) => {
        setCatList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleClear = () => {
    setSelectedCategory({
      categoryID: "",
      category_name: "",
      id: selectedID,
    });
    modalClose();
  };

  return (
    <div className={css["modal-container"]}>
      <div className={css["modal-content"]}>
        <div className={css["modal-header-container"]}>
          <div className={css["modal-header-title"]}>{title}</div>
          <div className={css["modal-header-close-btn"]} onClick={modalClose}>
            <svg width="20" height="20">
              <use xlinkHref="#svg-icon__close" />
            </svg>
          </div>
        </div>
        <div>
          {catList.length !== 0 && (
            <div className={css["catalog-container"]}>
              <ul className={css["menu"]}>
                {catList.map((item) => (
                  <li className={css["group"]} key={item.categoryID}>
                    <SelectCategoriesRoot
                      item={item}
                      selectedID={selectedID}
                      setSelectedCategory={setSelectedCategory}
                      modalClose={modalClose}
                    />
                  </li>
                ))}
              </ul>
            </div>
          )}
          <div className={css["modal-value-clear"]} onClick={handleClear}>
            Очистити значення
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectCategory;
