import { createSlice } from "@reduxjs/toolkit";
import { saveBasket } from "../components/Basket/saveBasket";

export const saveBasketMiddleware = store => next => action => {
    const result = next(action);
    const state = store.getState().basket;
    const userStatus = store.getState().user.status;
    saveBasket(userStatus, state.orderID, state.updateDate, state.items, state.count, state.amount, state.amountIn, state.client, state.firm, state.manager, state.confirm, state.priceScheme, state.planDate, state.coment, state.delivery);
    return result;
};

const basketSlice = createSlice({
    name: 'basket',
    initialState: {
        orderID: null,
        items: [],
        count: 0,
        amount: 0,
        amountIn: 0,
        updateDate: Date().slice(0, 15),
        client: {"id": 0, "name": ""},
        firm: {"id": 0, "name": ""},
        manager: {"id": 0, "name": ""},
        confirm: false,
        priceScheme: "RTL",
        planDate: "",
        coment: "",
        delivery: {
                    deliveryType: "office",
                    firstname: "",
                    surname: "",
                    telephone: "",
                    email: "",
                    regionName: "",
                    cityName: "",
                    pointName: "",
                    adressDelivery: "",
                },
    },
    reducers: {
        changeBasketItems(state, action) {
            state.items = action.payload;
        },
        changeBasketOrderID(state, action) {
            state.orderID = action.payload;
        },
        changeBasketCount(state, action) {
            state.count = action.payload;
        },
        changeBasketAmount(state, action) {
            state.amount = action.payload;
        },
        changeBasketAmountIn(state, action) {
            state.amountIn = action.payload;
        },
        changeBasketUpdateDate(state, action) {
            state.updateDate = action.payload;
        },
        changeBasketClient(state, action) {
            state.client = action.payload;
        },
        changeBasketFirm(state, action) {
            state.firm = action.payload;
        },
        changeBasketManager(state, action) {
            state.manager = action.payload;
        },
        changeBasketConfirm(state, action) {
            state.confirm = action.payload;
        },
        changeBasketPriceScheme(state, action) {
            state.priceScheme = action.payload;
        },
        changeBasketPlanDate(state, action) {
            state.planDate = action.payload;
        },
        changeBasketComent(state, action) {
            state.coment = action.payload;
        },
        changeBasketDelivery(state, action) {
            state.delivery = action.payload;
        },
        changeBasketToNull(state, action) {
            state.items = [];
            state.orderID = action.payload;
            state.count = 0;
            state.amount = 0;
            state.amountIn = 0;
            state.updateDate = Date().slice(0, 15);
            // state.client = {"id": 0, "name": ""};
            // state.firm = {"id": 0, "name": ""};
            // state.manager = {"id": 0, "name": ""};
            state.confirm = false;
            state.priceScheme = 'RTL';
            state.planDate = '';
            state.coment = '';
            state.delivery = {};
        },

    },
});

export const basketReducer = basketSlice.reducer;

export const { changeBasketOrderID, changeBasketItems, changeBasketCount, changeBasketAmount, changeBasketAmountIn, changeBasketUpdateDate, changeBasketClientID, changeBasketClient, changeBasketFirm, changeBasketManager, changeBasketConfirm, changeBasketPriceScheme, changeBasketPlanDate, changeBasketComent, changeBasketDelivery, changeBasketToNull } = basketSlice.actions;