import css from "./ButtonSave.module.css";
import apiaxios from "../../../common/apiaxios";

const ButtonSave = ({ active = true, data, redirect = "", requestPath }) => {
  const saveData = () => {
    if (active) {
      apiaxios
        .post(
          requestPath,
          data,
          { headers: { "Content-Type": "application/json" } },
          { withCredentials: true }
        )
        .then(() => {
          if (redirect !== "") {
            window.location.href = redirect;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <div
      className={active ? css["button"] : css["button-deactive"]}
      onClick={saveData}
    >
      Зберегти
    </div>
  );
};

export default ButtonSave;
