import { Link, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import apiaxios from "../../common/apiaxios";
import css from "./ProductsList.module.css";

const ProductListPath = ({ categoryID, name }) => {
  const [searchParams] = useSearchParams();
  const [list, setList] = useState([]);
  const perPage = JSON.parse(localStorage.getItem("productsPerPage"))
    ? JSON.parse(localStorage.getItem("productsPerPage"))
    : 20;
  useEffect(() => {
    if (categoryID) {
      apiaxios
        .get("/catalog/category_path/" + categoryID)
        .then((res) => {
          setList(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [categoryID]);

  return (
    <div className={css["path-block"]}>
      {searchParams.get("search") && (
        <div className={css["path-item-saerch"]}>
          Сформовано на запит: "{searchParams.get("search")}"
        </div>
      )}
      {!searchParams.get("search") &&
        list &&
        list.map((item) => (
          <div key={item.id} className={css["path-item"]}>
            <Link
              to={
                item.dir === false
                  ? "/products_list/" +
                    item.categoryID +
                    "?page=1&perPage=" +
                    perPage
                  : item.categoryID === 1
                  ? "/"
                  : "/group_menu/" + item.categoryID
              }
              className={css["path-link"]}
            >
              {item.name + " /"}
            </Link>
          </div>
        ))}
      {name && <div className={css["path-link-name"]}>{name}</div>}
    </div>
  );
};

export default ProductListPath;
