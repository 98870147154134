import { useEffect, useState } from "react";
import apiaxios from "../../common/apiaxios";
import css from "./CategoriesFilters.module.css";
import CategoriesFiltersGroup from "./CategoriesFiltersGroup";
import BannerSC from "../BannerSC/BannerSC";
import CategoriesFiltersPrice from "./CategoriesFiltersPrice";

const CategoriesFilters = ({ categoryID, catalogNavigate }) => {
  const [list, setList] = useState([]);

  useEffect(() => {
    apiaxios
      .get("/catalog/get_category_filters/" + categoryID)
      .then((res) => {
        setList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [categoryID]);

  return (
    <>
      {list.length !== 0 && (
        <>
          <ul className={css["filter-ul-container"]}>
            <CategoriesFiltersPrice catalogNavigate={catalogNavigate} />
            {list.map((item) => (
              <li key={item.group.optionID}>
                <CategoriesFiltersGroup
                  name={item.group.name}
                  allFilters={item.filters}
                  catalogNavigate={catalogNavigate}
                />
              </li>
            ))}
          </ul>
          <BannerSC />
        </>
      )}
    </>
  );
};

export default CategoriesFilters;
