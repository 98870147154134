import { useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import css from "../components/ProductsList/ProductsList.module.css";
import ProductListPath from "../components/ProductsList/ProductListPath";
import CategoriesFilters from "../components/CategoriesFilters/CategoriesFilters";
import CategoriesFiltersSearch from "../components/CategoriesFilters/CategoriesFiltersSearch";
import ProductsList from "../components/ProductsList/ProductsList";

const ProductsListPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { categoryID } = useParams();
  const [filtersSearchAll, setFiltersSearchAll] = useState([]);
  const [modalIsOpen, setIsOpen] = useState(false);

  const catalogNavigate = (
    filters,
    perPage,
    page,
    sort,
    search,
    minPrice,
    maxPrice
  ) => {
    let path = "?page=" + page + "&perPage=" + perPage;
    if (search !== "") {
      path += "&search=" + search;
    }
    path += "&gt=" + minPrice;
    path += "&lt=" + maxPrice;
    if (sort !== "none") {
      path += "&sort=" + sort;
    }
    if (sort !== "none") {
      path += "&sort=" + sort;
    }
    let filtersStr = "";
    if (filters.length !== 0) {
      for (const item of filters) {
        if (filtersStr === "") {
          filtersStr = filtersStr + item;
        } else {
          filtersStr = filtersStr + "|" + item;
        }
      }
      path += "&filters=" + filtersStr;
    }
    navigate(path);
  };

  return (
    <>
      <div className={css["product-list-container"]}>
        {(categoryID || searchParams.get("search")) &&
          categoryID !== "0000" && <ProductListPath categoryID={categoryID} />}
        <div className={css["product-list-filters-block"]}>
          {categoryID && categoryID !== "0000" && (
            <div className={css["filters-block"]}>
              <CategoriesFilters
                categoryID={categoryID}
                catalogNavigate={catalogNavigate}
              />
            </div>
          )}
          {searchParams.get("search") && (
            <div className={css["filters-block"]}>
              <CategoriesFiltersSearch
                allFiltersSearch={filtersSearchAll}
                catalogNavigate={catalogNavigate}
              />
            </div>
          )}
          <ProductsList
            setIsOpen={setIsOpen}
            setFiltersSearchAll={setFiltersSearchAll}
            catalogNavigate={catalogNavigate}
          />
        </div>
      </div>
      {modalIsOpen && (
        <div className="backdrop-modal">
          <div className={css["modal-filters-container"]}>
            <div className={css["modal-filters-header"]}>
              <div
                className={css["modal-filters-header-btn"]}
                onClick={() => {
                  setIsOpen(false);
                }}
              >
                Результат
              </div>
              {/* <div
                className={css["modal-filters-header-btn"]}
                onClick={() => {
                  setFilters([]);
                }}
              >
                Скинути фільтри
              </div> */}
              <div
                onClick={() => {
                  setIsOpen(false);
                }}
              >
                <svg width="20" height="20">
                  <use xlinkHref="#svg-icon__close" />
                </svg>
              </div>
            </div>
            {categoryID && (
              <CategoriesFilters
                categoryID={categoryID}
                catalogNavigate={catalogNavigate}
              />
            )}
            {searchParams.get("search") && (
              <CategoriesFiltersSearch
                allFiltersSearch={filtersSearchAll}
                catalogNavigate={catalogNavigate}
              />
            )}
            {/* <CategoriesFilters
                categoryID={categoryID}
                catalogNavigate={catalogNavigate}
            /> */}
          </div>
        </div>
      )}
    </>
  );
};

export default ProductsListPage;
