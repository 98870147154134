import css from "./CountCircle.module.css";

const CountCircle = ({ count }) => {
  let diameter = 0;
  if (count < 10) {
    diameter = 20;
  } else if (count < 100) {
    diameter = 40;
  } else if (count < 1000) {
    diameter = 60;
  }

  return (
    <div id={diameter} className={css["container"]}>
      <div>{count}</div>
    </div>
  );
};

export default CountCircle;
