import { useEffect, useState } from "react";
import apiaxios from "../../common/apiaxios";
import css from "./Dictionaries.module.css";
import { IconContext } from "react-icons";
import { FaEdit, FaRegPlusSquare, FaCaretSquareRight } from "react-icons/fa";
import InfoBtn from "./InfoBtn";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const Sources = () => {
  const userStatus = useSelector((state) => state.user.status);
  const [sourceList, setSourceList] = useState([]);
  const [updateVendor, setUpdateVendor] = useState("");
  const [JSONMenu, setJSONMenu] = useState(false);

  useEffect(() => {
    apiaxios
      .get("/sources/get_source_list/")
      .then((res) => {
        setSourceList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (updateVendor !== "") {
      apiaxios
        .get("/catalog/update_current_vendor?vendor=" + updateVendor)
        .then((res) => {
          // setUpdateVendor("");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [updateVendor]);

  return (
    <div className={css["dictionary-container"]}>
      <div className={css["dictionary-header"]}>
        <div className={css["dictionary-title"]}>Постачальники та склади</div>
        <div className={css["dictionary-header-btn-block"]}>
          {userStatus === "super" && (
            <div
              className={css["dictionary-header-json-btn"]}
              onMouseEnter={() => {
                setJSONMenu(true);
              }}
              onMouseLeave={() => {
                setJSONMenu(false);
              }}
            >
              JSON
              {JSONMenu && (
                <div className={css["dictionary-header-json-menu"]}>
                  <div className={css["dictionary-header-json-menu-item"]}>
                    Вивантажити
                  </div>
                  <div className={css["dictionary-header-json-menu-item"]}>
                    Завантажити
                  </div>
                </div>
              )}
            </div>
          )}
          <div
            className={css["dictionary-title-add-item"]}
            title="додати новий елемент довідника"
          >
            <Link to={"./create"} className={css["dictionary-edit-icon"]}>
              <IconContext.Provider value={{ size: "1.5em" }}>
                <FaRegPlusSquare />
              </IconContext.Provider>
            </Link>
          </div>
        </div>
      </div>
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Код</th>
            <th>Назва</th>
            <th>Тип</th>
            <th>Контрагент</th>
            <th>Останнє оновлення</th>
            <th title="оновити дані по постачальнику">Now</th>
            <th title="автоматичне оновлення данних по постачальнику">
              Update
            </th>
            <th title="показувати ціни постачальника в картці">Show</th>
            <th title="інформація">Info</th>
            <th title="редагувати елемент довідника">E</th>
          </tr>
        </thead>
        <tbody>
          {sourceList.map((item) => (
            <tr key={item["id"]}>
              <td>{item["id"]}</td>
              <td>{item["code"] ? item["code"] : "-"}</td>
              <td>{item["name"] ? item["name"] : "-"}</td>
              <td>
                {item["warehouse"] === 1 && "склад"}
                {item["warehouse"] === 0 && "постачальник"}
              </td>
              <td>{item["distributor"] ? item["distributor"] : "-"}</td>
              <td>{item["update_time"] ? item["update_time"] : "-"}</td>
              <td>
                <div
                  onClick={() => {
                    setUpdateVendor(item["code"]);
                    console.log(item["code"]);
                  }}
                  className={css["dictionary-edit-icon"]}
                >
                  <IconContext.Provider value={{ size: "1em" }}>
                    <FaCaretSquareRight />
                  </IconContext.Provider>
                </div>
              </td>
              <td>
                {item["import_data"] === 1 && "+"}
                {item["import_data"] === 0 && "-"}
              </td>
              <td>
                {item["show_price"] === 1 && "+"}
                {item["show_price"] === 0 && "-"}
              </td>
              <td>
                <InfoBtn text={item["discription"]} />
              </td>
              <td>
                <Link
                  to={"./" + item["id"]}
                  className={css["dictionary-edit-icon"]}
                >
                  <IconContext.Provider value={{ size: "1em" }}>
                    <FaEdit />
                  </IconContext.Provider>
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* <div>
        <div className={css["dictionary-row-head"]}>
          <div className={css["dictionary-item"]} style={{ width: "5%" }}>
            ID
          </div>
          <div className={css["dictionary-item"]} style={{ width: "10%" }}>
            Код
          </div>
          <div className={css["dictionary-item"]} style={{ width: "30%" }}>
            Назва
          </div>
          <div className={css["dictionary-item"]} style={{ width: "20%" }}>
            Тип
          </div>
          <div className={css["dictionary-item"]} style={{ width: "35%" }}>
            Контрагент
          </div>
          <div className={css["dictionary-item"]} style={{ width: "40px" }}>
            Дії
          </div>
        </div>
        {sourceList.map((item) => (
          <div key={item["id"]} className={css["dictionary-row"]}>
            <div className={css["dictionary-item"]} style={{ width: "5%" }}>
              {item["id"]}
            </div>
            <div className={css["dictionary-item"]} style={{ width: "10%" }}>
              {item["code"] ? item["code"] : "-"}
            </div>
            <div className={css["dictionary-item"]} style={{ width: "30%" }}>
              {item["name"] ? item["name"] : "-"}
            </div>
            <div className={css["dictionary-item"]} style={{ width: "20%" }}>
              {item["warehouse"] === 1 && "склад"}
              {item["warehouse"] === 0 && "постачальник"}
            </div>
            <div className={css["dictionary-item"]} style={{ width: "35%" }}>
              {item["distributor"] ? item["distributor"] : "-"}
            </div>
            <div
              className={css["dictionary-item-icon"]}
              style={{ width: "20px" }}
            >
              <InfoBtn text={item["discription"]} />
            </div>
            <div
              className={css["dictionary-item-icon"]}
              title="редагувати елемент довідника"
              style={{ width: "20px" }}
            >
              <Link
                to={"./" + item["id"]}
                className={css["dictionary-edit-icon"]}
              >
                <IconContext.Provider value={{ size: "1em" }}>
                  <FaEdit />
                </IconContext.Provider>
              </Link>
            </div>
          </div>
        ))}
      </div> */}
    </div>
  );
};

export default Sources;
