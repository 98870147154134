import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import css from "./Profile.module.css";
import apiaxios from "../../common/apiaxios";

const SideMenu = ({ active, setActive }) => {
  const [email, setEmail] = useState("");
  const [firstname, setFirstname] = useState("");
  const [surname, setSurname] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("access_token")) {
      const path = window.location.href;
      apiaxios
        .get("/users/get_user_info/")
        .then((res) => {
          setFirstname(res.data.first_name);
          setSurname(res.data.last_name);
          setEmail(res.data.email);
        })
        .catch((err) => {
          console.log(err);
          window.location.href = path;
        });
    }
  }, []);

  return (
    <>
      <div className={css["sidebar-header"]}>
        <div className={css["sidebar-header-name"]}>
          {firstname} {surname}
        </div>
        <div className={css["sidebar-header-email"]}>{email}</div>
      </div>
      <ul className={css["sidebar-block"]}>
        <li
          className={
            active === "order_user"
              ? css["sidebar-item-active"]
              : css["sidebar-item"]
          }
          onClick={() => {
            navigate("/profile/orders", { replace: true });
            setActive("order_user");
          }}
        >
          Замовлення
        </li>
        <li
          className={
            active === "favorite"
              ? css["sidebar-item-active"]
              : css["sidebar-item"]
          }
          onClick={() => {
            navigate("/profile/favorite", { replace: true });
            setActive("favorite");
          }}
        >
          Обране
        </li>
        <li
          className={
            active === "compare"
              ? css["sidebar-item-active"]
              : css["sidebar-item"]
          }
          onClick={() => {
            navigate("/profile/compare", { replace: true });
            setActive("compare");
          }}
        >
          Порівняння
        </li>
        <li
          className={
            active === "info" ? css["sidebar-item-active"] : css["sidebar-item"]
          }
          onClick={() => {
            navigate("/profile/info", { replace: true });
            setActive("info");
          }}
        >
          Особисті дані
        </li>
      </ul>
    </>
  );
};

export default SideMenu;
