const CheckBool = ({ value, setValue, label }) => {
  return (
    <div style={{ display: "flex", marginLeft: "5px" }}>
      <input
        id="check"
        name="check"
        type="checkbox"
        checked={value}
        autoComplete="off"
        onChange={(e) => {
          setValue(e.target.checked);
        }}
      />
      <div style={{ marginLeft: "5px" }}>
        <label htmlFor="check">{label}</label>
      </div>
    </div>
  );
};

export default CheckBool;
