import { useState } from "react";
import apiaxios from "../../common/apiaxios";
import css from "../ProductsList/ProductsList.module.css";
import css2 from "./ProductFavoriteCompare.module.css";
import ProductCard from "../ProductCard/ProductCard";
import { IconContext } from "react-icons";
import { FaEdit, FaStar, FaTrashAlt } from "react-icons/fa";

const ProductsFavoriteList = ({ elem }) => {
  const [editName, setEditName] = useState(false);
  const [inputName, setInputName] = useState(elem.listName);

  const editListName = (e) => {
    apiaxios
      .post(
        "/users/change_favorite_list_name?name=" +
          inputName +
          "&id=" +
          e.currentTarget.id
      )
      .then((res) => {
        window.location.reload();
      });
  };

  const changeMainList = () => {
    apiaxios
      .post("/users/change_main_favorite_list?id=" + elem.listID)
      .then((res) => {
        window.location.reload();
      });
  };

  const deleteList = () => {
    apiaxios
      .post("/users/delete_favorite_list?id=" + elem.listID)
      .then((res) => {
        window.location.reload();
      });
  };

  return (
    <>
      <div className={css2["favorite-list-header-block"]}>
        {editName && (
          <div className={css2["edit-list-name-block"]}>
            <span>Назва списку:</span>
            <input
              className={css2["edit-list-name-input"]}
              type="text"
              value={inputName}
              onChange={(e) => setInputName(e.target.value)}
            />
            <button onClick={editListName} id={elem.listID}>
              Зберегти
            </button>
          </div>
        )}
        {!editName && (
          <div className={css2["favorite-list-header-title-block"]}>
            <div className={css2["favorite-list-header-title"]}>
              {elem.listName}
            </div>
            {elem.listMain && (
              <div className={css2["favorite-list-header-title-main"]}>
                (основний)
              </div>
            )}
            <div className={css["header-product-list-count"]}>
              {elem.listCount}
            </div>
          </div>
        )}
        <div className={css2["favorite-list-header-icon-block"]}>
          <div
            className={css2["favorite-list-header-icon-item"]}
            onClick={() => {
              setEditName(true);
            }}
            title="Редагувати назву списку"
          >
            <IconContext.Provider value={{ size: "1em" }}>
              <FaEdit />
            </IconContext.Provider>
          </div>
          <div
            className={css2["favorite-list-header-icon-item"]}
            onClick={changeMainList}
            title="Зробити список основним"
          >
            <IconContext.Provider value={{ size: "1em" }}>
              <FaStar />
            </IconContext.Provider>
          </div>
          {!elem.listMain && (
            <div
              className={css2["favorite-list-header-icon-item"]}
              onClick={deleteList}
              title="Видалити список"
            >
              <IconContext.Provider value={{ size: "1em" }}>
                <FaTrashAlt />
              </IconContext.Provider>
            </div>
          )}
        </div>
      </div>
      {elem.list.length !== 0 && (
        <ul className={css["product-list-pag"]}>
          {elem.list.map((item) => (
            <li key={item.id} className={css["product-list-wrap"]}>
              <ProductCard
                id={item.id}
                name={item.name}
                onoff={item.onoff}
                brief_description={item.brief_description}
                date_modified={item.date_modified}
                price_im={item.user_price}
                price_max={item.price_im}
                artline_price_pdv={item.artline_price_pdv}
                artline_price_bez_pdv={item.artline_price_bez_pdv}
                full_image={item.full_image}
                favorite={item.favorite}
                compare={item.compare}
                prices={item.prices}
                sale_prices={item.sale_prices}
                iconLineView={"icon"}
              />
            </li>
          ))}
        </ul>
      )}
      {elem.list.length === 0 && (
        <div className={css["no-products-text"]}>
          У вас ще немає обраних товарів...
        </div>
      )}
      <div className={css2["favorite-list-header-block"]}></div>
    </>
  );
};

export default ProductsFavoriteList;
