import { useEffect, useState } from "react";
import { FaRegEyeSlash, FaRegEye } from "react-icons/fa";
import apiaxios from "../../common/apiaxios";
import css from "../ProductsList/ProductsList.module.css";
import css2 from "./ProductFavoriteCompare.module.css";
import { Circles } from "react-loader-spinner";
import { IconContext } from "react-icons";
import ProductCompareList from "./ProductCompareList";
import { useSelector } from "react-redux";

const ProductCompareLists = () => {
  const userStatus = useSelector((state) => state.user.status);
  const priceScheme = useSelector((state) => state.basket.priceScheme);
  const [list, setList] = useState([]);
  const [listCount, setListCount] = useState();
  const [circle, setCircle] = useState(true);

  const [showPrice, setShowPrice] = useState(
    JSON.parse(localStorage.getItem("showPrice"))
  );

  useEffect(() => {
    apiaxios
      .get("/users/get_compare_list/", {
        params: {
          price_scheme: priceScheme,
        },
      })
      .then((res) => {
        setList(res.data["result"]);
        setListCount(res.data["result"].length);
        setCircle(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [priceScheme]);

  return (
    <>
      {circle && (
        <div className="spinner">
          <Circles
            height="150"
            width="150"
            color="rgb(50, 130, 250)"
            ariaLabel="circles-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      )}
      {!circle && (
        <div className={css2["list-container"]}>
          <div className={css["header-product-list"]}>
            <div className={css["header-product-list-title-count-block"]}>
              <div className={css["header-product-list-title"]}>
                Списки порівняння
              </div>
              <div className={css["header-product-list-count"]}>
                {listCount}
              </div>
            </div>
            <div className={css2["favorite-list-menu-block"]}>
              {(userStatus === "super" || userStatus === "staff") && (
                <div
                  className={css["price-eye"]}
                  onClick={() => {
                    setShowPrice(!showPrice);
                    localStorage.setItem(
                      "showPrice",
                      JSON.stringify(!showPrice)
                    );
                  }}
                  title="показувати ціни"
                >
                  <IconContext.Provider value={{ size: "1.5em" }}>
                    {showPrice ? <FaRegEye /> : <FaRegEyeSlash />}
                  </IconContext.Provider>
                </div>
              )}
            </div>
          </div>
          {list.length !== 0 && (
            <div className={css["product-list"]}>
              {list.map((elem) => (
                <div key={elem.listID}>
                  <ProductCompareList elem={elem} />
                </div>
              ))}
            </div>
          )}
          {list.length === 0 && (
            <div className={css["no-products-text"]}>
              У вас ще немає списків товарів для порівняння...
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default ProductCompareLists;
