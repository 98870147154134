import Contacts from "../components/Contacts/Contacts";

const SC = () => {
  return (
    <div className="pages-container">
      <div className="page-title">Сервісний центр</div>
      <div>
        <p>
          Пріоритетними напрямками роботи нашого сервісного центру Суми
          ІТ-сервіс є ремонт та технічне обслуговування комп`ютерної та офісної
          техніки, заправка картриджів. У цій сфері ми працюємо з 2006 року і
          досягли значних успіхів, що підтверджується великою кількістю
          постійних клієнтів.
        </p>
      </div>
      <ul>
        <div>
          <i>
            <b>Основні переваги роботи з нами, які відзначають наші клієнти:</b>
          </i>
        </div>
        <li>
          <i>
            <b>- низькі ціни</b> (підтверджено аналізом пропозицій у даному
            сегменті ринку);
          </i>
        </li>
        <li>
          <i>
            <b>- висока якість наданих послуг та якісні витратні матеріали;</b>
          </i>
        </li>
        <li>
          <i>
            <b>- оперативність виконання робіт, </b> що обумовлюється
            професійною підготовкою та досвідом наших інженерів, а також широкою
            базою обладнання та інструментів;
          </i>
        </li>
        <li>
          <i>
            <b>- доставка картриджів та техніки.</b>
          </i>
        </li>
      </ul>
      <ul>
        <div>
          <i>
            <b>Проводимо діагностику, ремонт, технічне обслуговування:</b>
          </i>
        </div>
        <li>
          <i>
            - ноутбуків та їх компонентів, заміна деталей, у тому числі складні
            ремонти по перепайці чіпів (маємо власне обладнання);
          </i>
        </li>
        <li>
          <i>
            - системних блоків комп’ютера та їх компонентів, заміна деталей;
          </i>
        </li>
        <li>
          <i>
            - принтерів, багатофункціональних пристроїв, копіювальних апаратів
            (повний спектр послуг);
          </i>
        </li>
        <li>
          <i>
            - моніторів, пристроїв безперервного живлення, навушників,
            акустичних систем та іншого периферійного обладнання.
          </i>
        </li>
      </ul>
      <div>
        <p>
          <b>
            <i>Заправка картриджів</i>
          </b>{" "}
          – окремий напрямок роботи нашого СЦ, маємо великий досвід, спеціальне
          обладнання та збалансовані «ціна-якість» витратні матеріали. Завдяки
          цьому ми пропонуємо самі низькі ціни на заправку картриджів у нашому
          місті з повним оформленням бухгалтерських документів.
        </p>
      </div>
      <ul>
        <div>
          <i>
            <b>«Вічний картридж»</b> – це нова послуги, яку ми пропонуємо нашим
            клієнтам. Це схема роботи з клієнтом, яка знижує собівартість робіт
            по відновленню роботи картриджу для нас і, як наслідок, вартість
            друку для клієнта завдяки наступним факторам:
          </i>
        </div>
        <li>
          <i>
            - клієнт завжди платить фіксовану ціну за обслуговування картриджа,
            не залежно від того, які запчастини потрібно замінити в картриджі;
          </i>
        </li>
        <li>
          <i>
            - клієнту не потрібно купувати новий картридж, на заміну зношеного –
            він завжди отримує готовий до роботи картридж;
          </i>
        </li>
        <li>
          <i>
            - клієнту отримує картридж відразу із підмінного фонду і не чекає на
            заправку чи відновлення картриджу.
          </i>
        </li>
      </ul>
      <div>
        <p>
          Наш СЦ має парк тестових принтерів для перевірки більшості моделей
          розповсюджених картриджів, що додатково сприяє підвищенню якості
          надання послуг.
        </p>
      </div>
      <div>
        <p>
          Серед наших клієнтів{" "}
          <b>
            <i>
              велика кількість бюджетних установ та організацій (359 виконаних
              договорів за 2023 рік)
            </i>
          </b>
          , багато з яких працює з нами вже не один рік. Ми з розумінням
          ставимось до специфіки, яка обумовлюється перерахуванням коштів через
          казначейську службу України. Завдяки досвіду, ми готуємо повний
          комплект документів необхідних для подання зобов`язань до
          казначейської служби, включаючи неодноразово перевірений договір.
        </p>
      </div>
      <div>
        <p>
          Більш детальну інформацію з приводу послуг нашого сервісного центру
          можна знайти на сторінках сайту та дізнатися по телефону у наших
          менеджерів.
        </p>
      </div>
      <div>
        <Contacts />
        {/* <p>
          <b>
            <i>
              <a
                href="https://www.it-service.sumy.ua"
                target="_blank"
                rel="noreferrer"
              >
                www.it-service.sumy.ua
              </a>
            </i>
          </b>
        </p> */}
      </div>
    </div>
  );
};

export default SC;
