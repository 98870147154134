import { useState } from "react";
import css from "./Profile.module.css";
import Utility from "./Utility";
import CatalogMenu from "../CatalogMenu/CatalogMenu";
import ProductsList from "../ProductsList/ProductsList";
import Info from "./Info";
import SideMenu from "./SideMenu";
import { useSearchParams } from "react-router-dom";
import OrdersList from "../Orders/OrdersList";
import ProductFavoriteLists from "../ProductFavoriteCompare/ProductFavoriteLists";
import ProductCompareLists from "../ProductFavoriteCompare/ProductCompareLists";
import { useSelector } from "react-redux";

const Profile = ({ active_menu }) => {
  const [searchParams] = useSearchParams();
  const userStatus = useSelector((state) => state.user.status);
  const [active, setActive] = useState(active_menu);

  return (
    <>
      {userStatus !== "guest" && (
        <div className="profile-all-container">
          <div className="profile-container">
            <div className={css["side-menu-block"]}>
              <SideMenu active={active} setActive={setActive} />
            </div>
            {active === "info" && <Info />}
            {active === "order_user" && (
              <div className={css["order-history-block"]}>
                <div className="page-title">Замовлення</div>
                {searchParams.get("type") === "new_order" && (
                  <div className={css["new-order-message"]}>
                    Нове замовлення успішно створено!
                  </div>
                )}
                <OrdersList type={"order_user"} />
              </div>
            )}
            {active === "favorite" && <ProductFavoriteLists />}
            {active === "compare" && <ProductCompareLists />}
            {active === "utility" && <Utility />}
          </div>
        </div>
      )}
      {userStatus === "guest" && (
        <>
          <CatalogMenu />
          <ProductsList />
        </>
      )}
    </>
  );
};

export default Profile;
