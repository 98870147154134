import { useEffect, useState } from "react";
import apiaxios from "../../common/apiaxios";
import css from "./Dictionaries.module.css";
import { useParams } from "react-router-dom";
import CustomInput from "../control/CustomInput/CustomInput";
import CustomSelect from "../control/CustomSelect/CustomSelect";
import SelectFromList from "../control/SelectFromList/SelectFromList";
import CustomTextArea from "../control/CustomTextArea/CustomTextArea";
import ButtonSave from "../control/ButtonSave/ButtonSave";
import ButtonClose from "../control/ButtonClose/ButtonClose";

const SourceItem = () => {
  const { id } = useParams();
  const [data, setData] = useState();
  const [newData, setNewData] = useState();
  const [code, setCode] = useState();
  const [name, setName] = useState();
  const [warehouse, setWarehouse] = useState();
  const [importData, setImportData] = useState();
  const [showPrice, setShowPrice] = useState();
  const [distributor, setDistributor] = useState();
  const [distributorID, setDistributorID] = useState();
  const [discription, setDiscription] = useState();
  const [requestPath, setRequestPath] = useState();

  useEffect(() => {
    if (id) {
      apiaxios
        .get("/sources/get_source_item?id=" + id)
        .then((res) => {
          setData(res.data);
          setName(res.data["name"]);
          setCode(res.data["code"]);
          setWarehouse(res.data["warehouse"]);
          setImportData(res.data["import_data"]);
          setShowPrice(res.data["show_price"]);
          setDistributor(res.data["distributor"]);
          setDistributorID(res.data["distributor_id"]);
          setDiscription(res.data["discription"]);
          setRequestPath("/sources/save_source_item/");
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setData({
        name: "",
        code: "",
        warehouse: 0,
        import_data: 1,
        show_price: 1,
        distributor: "",
        distributor_id: "",
        discription: "",
      });
      setName("");
      setCode("");
      setWarehouse(0);
      setImportData(1);
      setShowPrice(1);
      setDistributor("");
      setDistributorID("");
      setDiscription("");
      setRequestPath("/sources/create_source_item/");
    }
  }, [id]);

  useEffect(() => {
    setNewData({
      id: id,
      name: name,
      code: code,
      warehouse: warehouse,
      distributor: distributorID,
      discription: discription,
      import_data: importData,
      show_price: showPrice,
    });
  }, [
    id,
    name,
    code,
    warehouse,
    distributorID,
    discription,
    importData,
    showPrice,
  ]);

  return (
    <div className={css["dictionary-container"]}>
      {data && (
        <>
          <div className="page-title">
            Довідник постачальників та складів - <i>{data["name"]}</i>
          </div>
          <div style={{ width: "300px" }}>
            <div className={css["dictionary-item-row"]}>
              <div className={css["dictionary-item-col1"]}>
                ID: {data["id"]}
              </div>
              {/* <div className={css["dictionary-item-col2"]}>
                Код: {data["code"]}
              </div> */}
            </div>
            <CustomInput value={code} setValue={setCode} label={"Код:"} />
            <CustomInput value={name} setValue={setName} label={"Назва:"} />
            <CustomSelect
              value={warehouse}
              setValue={setWarehouse}
              valueList={[
                { id: 0, name: "постачальник" },
                { id: 1, name: "склад" },
              ]}
              label={"Тип:"}
            />
            <SelectFromList
              requestPath={"/partners/get_clients/"}
              label={"Контрагент:"}
              value={distributor}
              setValue={setDistributor}
              valueID={distributorID}
              setValueID={setDistributorID}
            />
            <CustomSelect
              value={importData}
              setValue={setImportData}
              valueList={[
                { id: 1, name: "так" },
                { id: 0, name: "ні" },
              ]}
              label={"Оновлювати:"}
            />
            <CustomSelect
              value={showPrice}
              setValue={setShowPrice}
              valueList={[
                { id: 1, name: "так" },
                { id: 0, name: "ні" },
              ]}
              label={"Показувати:"}
            />
            <CustomTextArea
              value={discription}
              setValue={setDiscription}
              label={"Опис:"}
            />
          </div>
          <div className={css["dictionary-button-block"]}>
            <ButtonSave
              data={newData}
              redirect={"/portal/sources_list"}
              requestPath={requestPath}
            />
            <ButtonClose redirect={"/portal/sources_list"} />
          </div>
        </>
      )}
    </div>
  );
};

export default SourceItem;
