import { useState } from "react";
import css from "../Dictionaries.module.css";

const SelectCategoriesGroup = ({
  elem,
  selectedID,
  setSelectedCategory,
  modalClose,
  rootCategory,
}) => {
  const [show, setShow] = useState(false);

  return (
    <div className={css["modal-group-block"]}>
      <div onClick={() => setShow(!show)}>
        {show ? "- " : "+ "}
        {elem.name}
      </div>
      {show && (
        <>
          {elem.items.map((element) => (
            <div key={element.categoryID}>
              <div
                className={css["modal-group-block-item"]}
                id={element.categoryID}
                data-name={
                  rootCategory + " / " + elem.name + " / " + element.name
                }
                onClick={(e) => {
                  setSelectedCategory({
                    categoryID: e.currentTarget.id,
                    category_name: e.currentTarget.getAttribute("data-name"),
                    id: selectedID,
                  });
                  modalClose();
                }}
              >
                {element.name}
              </div>
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default SelectCategoriesGroup;
