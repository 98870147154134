import logo from "../../ITS_Logo.png";
import { useEffect, useState } from "react";
import apiaxios from "../../common/apiaxios";
import css from "./CatalogMobile.module.css";
import { Link, NavLink } from "react-router-dom";
import { useSelector } from "react-redux";

const CatalogMobile = ({
  modalClose,
  openModal,
  logout,
  firstname,
  surname,
  email,
}) => {
  const [list, setList] = useState([]);
  const [showCatalog, setShowCatalog] = useState(false);
  const userStatus = useSelector((state) => state.user.status);
  useEffect(() => {
    apiaxios
      .get("/catalog/get_category_for_catalog_menu/mobile")
      .then((res) => {
        setList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className={css["main-container"]}>
      <div className={css["header"]} onClick={modalClose}>
        <div>
          <NavLink to="/">
            <img src={logo} alt="logo" width={"50px"} />
          </NavLink>
        </div>
        <div>
          <div className={css["user-name"]}>
            {firstname} {surname}
          </div>
          <div className={css["user-email"]}>{email}</div>
        </div>
        <svg width="15" height="15">
          <use xlinkHref="#svg-icon__close" />
        </svg>
      </div>
      <div
        className={css["catalog-title"]}
        onClick={() => {
          setShowCatalog(!showCatalog);
        }}
      >
        Каталог
      </div>
      {list.length !== 0 && showCatalog && (
        <ul className={css["catalog-menu"]}>
          {list.map((item) => (
            <li className={css["catalog-group"]} key={item.categoryID}>
              <Link
                to={"/group_menu/" + item.categoryID}
                className={css["catalog-group-name"]}
                onClick={modalClose}
              >
                {item.name}
              </Link>
            </li>
          ))}
        </ul>
      )}
      <ul className={css["nav-menu"]}>
        <li onClick={modalClose}>
          <NavLink to="about" className={css["nav-menu-item"]}>
            Про Суми ІТ-сервіс
          </NavLink>
        </li>
        <li onClick={modalClose}>
          <NavLink to="sc" className={css["nav-menu-item"]}>
            Сервісний центр
          </NavLink>
        </li>
        <li onClick={modalClose}>
          <NavLink to="delivery" className={css["nav-menu-item"]}>
            Доставка і оплата
          </NavLink>
        </li>
        <li onClick={modalClose}>
          <NavLink to="contacts" className={css["nav-menu-item"]}>
            Контакти
          </NavLink>
        </li>
      </ul>
      <ul className={css["profile-menu"]}>
        {userStatus === "guest" && (
          <li>
            <div
              className={css["nav-menu-item"]}
              id="login"
              onClick={openModal}
            >
              <div>Вхід</div>
            </div>
          </li>
        )}
        {userStatus !== "guest" && (
          <>
            <li onClick={modalClose}>
              <NavLink to="/profile/orders" className={css["nav-menu-item"]}>
                <div>Замовлення</div>
              </NavLink>
            </li>
            {/* {(userStatus === "super" || userStatus === "staff") && (
              <li onClick={modalClose}>
                <NavLink
                  to="profile/orders_list"
                  className={css["nav-menu-item"]}
                >
                  <div>Всі замовлення</div>
                </NavLink>
              </li>
            )} */}
            <li onClick={modalClose}>
              <NavLink to="/profile/info_mob" className={css["nav-menu-item"]}>
                <div>Особисті дані</div>
              </NavLink>
            </li>
          </>
        )}

        <li>
          <div className={css["nav-menu-item"]} id="basket" onClick={openModal}>
            <div>Кошик</div>
          </div>
        </li>
        {userStatus === "super" && (
          <>
            <li onClick={modalClose}>
              <NavLink to="/orders_list" className={css["nav-menu-item"]}>
                <div>Журнал замовлень</div>
              </NavLink>
            </li>
            <li onClick={modalClose}>
              <NavLink to="/utility" className={css["nav-menu-item"]}>
                <div>Утіліти</div>
              </NavLink>
            </li>
            <li onClick={modalClose}>
              <NavLink to="/statistic" className={css["nav-menu-item"]}>
                <div>Статистика</div>
              </NavLink>
            </li>
          </>
        )}
        {userStatus !== "guest" && (
          <li>
            <div className={css["nav-menu-item"]} onClick={logout}>
              <div>Вихід</div>
            </div>
          </li>
        )}
      </ul>
    </div>
  );
};

export default CatalogMobile;
