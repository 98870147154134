import { FaRegEyeSlash, FaRegEye } from "react-icons/fa";
import { IconContext } from "react-icons";
import css from "./Auth.module.css";
import apiaxios from "../../common/apiaxios";
import InputMask from "react-input-mask";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { NavLink } from "react-router-dom";

const Registration = () => {
  const [email, setEmail] = useState("");
  const [firstname, setFirstname] = useState("");
  const [surname, setSurname] = useState("");
  const [telephone, setTelephone] = useState("");
  const [password, setPassword] = useState("");
  const [passwordAgain, setPasswordAgain] = useState("");
  const [passNewShow, setPassNewShow] = useState(false);
  const [passAgainShow, setPassAgainShow] = useState(false);

  const new_user_request = async () => {
    const user = {
      username: email,
      firstname: firstname,
      surname: surname,
      email: email,
      telephone: telephone,
      password: password,
    };

    await apiaxios
      .post(
        "/users/newuser/",
        user,
        { headers: { "Content-Type": "application/json" } },
        { withCredentials: true }
      )
      .then((res) => {
        if (res.data.result === "success") {
          toast.success("Реєстрація успішна!");
          window.location.href = "/login?type=reg";
        } else if (res.data.result === "exist") {
          toast.error("Користувач з такою поштою вже зареєстрований!");
          // window.location.href = "/restore_password?type=reg";
        } else {
          toast.error("Помилка реєстрації!");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const submit = (e) => {
    if (password !== passwordAgain) {
      toast.error("Паролі не співпадають.");
      e.preventDefault();
    } else {
      e.preventDefault();
      new_user_request();
      // alert(regStatus);
      // if (regStatus === "success") {
      //   toast.success("Реєстрація пройшла успішно.");
      //   window.location.href = "/login";
      //   window.location.reload();
      // } else {
      //   e.preventDefault();
      //   window.location.href = "/restore_password?type=reg";
      // }
    }
  };

  const passwordNewField = document.getElementById("passwordNew");

  const passwordNewShow = () => {
    if (passwordNewField.type === "password") {
      passwordNewField.type = "text";
      setPassNewShow(true);
    } else {
      passwordNewField.type = "password";
      setPassNewShow(false);
    }
  };

  const passwordAgainField = document.getElementById("passwordAgain");

  const passwordAgainShow = () => {
    if (passwordAgainField.type === "password") {
      passwordAgainField.type = "text";
      setPassAgainShow(true);
    } else {
      passwordAgainField.type = "password";
      setPassAgainShow(false);
    }
  };

  return (
    // <div className={css["Auth-form-container"]}>
    <div className={css["reg-container"]}>
      <div className="page-title">Реєстрація</div>
      {/* <div className={css["modal-x"]} onClick={modalClose}>
        <svg width="20" height="20">
          <use xlinkHref="#svg-icon__close" />
        </svg>
      </div> */}
      <form onSubmit={submit}>
        <div className={css["form-group"]}>
          <label>Електронна пошта (логін):</label>
          <input
            className={css["form-control"]}
            name="username"
            type="email"
            autoComplete="username"
            value={email}
            required
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className={css["form-group"]}>
          <label>Ім'я:</label>
          <input
            className={css["form-control"]}
            name="firstname"
            type="text"
            value={firstname}
            required
            onChange={(e) => setFirstname(e.target.value)}
          />
        </div>
        <div className={css["form-group"]}>
          <label>Прізвище:</label>
          <input
            className={css["form-control"]}
            name="surname"
            type="text"
            value={surname}
            required
            onChange={(e) => setSurname(e.target.value)}
          />
        </div>
        <div className={css["form-group"]}>
          <label>Номер телефону:</label>
          <InputMask
            className={css["form-control"]}
            name="telephone"
            type="text"
            autoComplete="telephone"
            mask="(999)9999999"
            value={telephone}
            required
            onChange={(e) => setTelephone(e.target.value)}
          />
        </div>
        <div className={css["form-group"]}>
          <label>Ввести пароль:</label>
          <div className={css["password-eye-block"]}>
            <input
              name="password"
              type="password"
              id="passwordNew"
              autoComplete="new-password"
              className={css["form-control"]}
              value={password}
              required
              onChange={(e) => setPassword(e.target.value)}
            />
            <div
              className={css["password-btn-eye"]}
              onMouseDown={passwordNewShow}
              onMouseUp={passwordNewShow}
            >
              <IconContext.Provider value={{ size: "0.9em" }}>
                {passNewShow ? <FaRegEye /> : <FaRegEyeSlash />}
              </IconContext.Provider>
            </div>
          </div>
        </div>
        <div className={css["form-group"]}>
          <label>Підтвердити пароль:</label>
          <div className={css["password-eye-block"]}>
            <input
              className={css["form-control"]}
              name="passwordAgain"
              type="password"
              id="passwordAgain"
              autoComplete="new-password"
              value={passwordAgain}
              required
              onChange={(e) => setPasswordAgain(e.target.value)}
            />
            <div
              className={css["password-btn-eye"]}
              onMouseDown={passwordAgainShow}
              onMouseUp={passwordAgainShow}
            >
              <IconContext.Provider value={{ size: "0.9em" }}>
                {passAgainShow ? <FaRegEye /> : <FaRegEyeSlash />}
              </IconContext.Provider>
            </div>
          </div>
        </div>
        <div className={css["form-footer"]}>
          <NavLink to="/login" className={css["form-footer-link"]}>
            <div>Вже зареєстровані? Увійти до кабінета.</div>
          </NavLink>
          <button type="submit" className={css["form-btn"]}>
            Зареєструвати
          </button>
        </div>
      </form>
      {/* {modalIsOpen && contentModal === "login" && (
        <Modal>
          <div className={css["modal-basket-container"]}>
            <Login modalClose={closeModal} />
          </div>
        </Modal>
      )}
      {modalIsOpen &&
        contentModal === "reg succsess" &&
        regStatus === "succsess" && (
          <Modal>
            <div className={css["modal-message-container"]}>
              <Message
                modalClose={closeModal}
                message={"Реєстрація пройшла успішно!"}
                redirect={"/"}
              />
            </div>
          </Modal>
        )}
      {modalIsOpen && contentModal === "reg fail" && regStatus === "fail" && (
        <Modal>
          <div className={css["modal-message-container"]}>
            <Message
              modalClose={closeModal}
              message={
                "Користувач з таким логіном вже існую. Спробуйте відновити пароль."
              }
              redirect={"/"}
            />
          </div>
        </Modal>
      )} */}
      <ToastContainer />
    </div>
  );
};

export default Registration;
