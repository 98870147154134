import React, { useState } from "react";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // стилі для компонента
import "react-date-range/dist/theme/default.css"; // стилі для теми за замовчуванням
import "react-datepicker/dist/react-datepicker.css";
import ukLocale from "date-fns/locale/uk"; // Імпорт локалізації

const DateInterval = ({ setStartDate, setEndDate }) => {
  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });

  const handleSelect = (ranges) => {
    setSelectionRange({
      startDate: ranges.selection.startDate,
      endDate: ranges.selection.endDate,
      key: "selection",
    });
    const currentDate = new Date(ranges.selection.endDate);
    currentDate.setHours(23, 59, 59, 999);
    setStartDate(ranges.selection.startDate.toISOString());
    setEndDate(currentDate.toISOString());
  };

  return (
    <div>
      <DateRangePicker
        locale={ukLocale} // Установка української локалізації
        ranges={[selectionRange]}
        onChange={handleSelect}
      />
    </div>
  );
};

export default DateInterval;
