import { useEffect, useState } from "react";
import apiaxios from "../../common/apiaxios";
import { useDispatch, useSelector } from "react-redux";
import {
  changeBasketPriceScheme,
  changeBasketItems,
  changeBasketAmount,
} from "../../redux/basketSlice";
import {
  selectBasketConfirm,
  selectBasketPriceScheme,
} from "../../redux/basketSelectors";

const SelectPriceScheme = () => {
  const dispatch = useDispatch();
  const priceScheme = useSelector(selectBasketPriceScheme);
  const basketConfirm = useSelector(selectBasketConfirm);
  const [priceList, setPriceList] = useState([]);

  useEffect(() => {
    apiaxios
      .get("/catalog/get_prices_out_list_for_scheme/")
      .then((res) => {
        setPriceList([{ code: "RTL" }, ...res.data]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (!basketConfirm) {
      apiaxios
        .get("/users/update_basket/", {
          params: {
            price_scheme: priceScheme,
          },
        })
        .then((res) => {
          dispatch(changeBasketItems(res.data.basket["items"]));
          dispatch(changeBasketAmount(res.data.basket["basketAmount"]));
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [priceScheme, basketConfirm, dispatch]);

  return (
    <select
      id="select"
      name="select"
      value={priceScheme}
      onChange={(e) => {
        dispatch(changeBasketPriceScheme(e.target.value));
      }}
    >
      {priceList.map((item) => (
        <option key={item["code"]} value={item["code"]}>
          {item["code"]}
        </option>
      ))}
    </select>
  );
};

export default SelectPriceScheme;
