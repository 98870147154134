import { useContext, useEffect, useState } from "react";
import apiaxios from "../../common/apiaxios";
import css from "./CatalogMenu.module.css";
import { Link } from "react-router-dom";
import userStatusContext from "../../context/userStatusContext";

const CatalogMenu = () => {
  const [list, setList] = useState([]);
  const { selectVendor } = useContext(userStatusContext);
  const perPage = JSON.parse(localStorage.getItem("productsPerPage"))
    ? JSON.parse(localStorage.getItem("productsPerPage"))
    : 20;
  useEffect(() => {
    apiaxios
      .get("/catalog/get_category_for_catalog_menu/standart", {
        params: {
          vendor: selectVendor,
        },
      })
      .then((res) => {
        setList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [selectVendor]);

  return (
    <>
      {list.length !== 0 && (
        <div className={css["catalog-container"]}>
          <ul className={css["menu"]}>
            {list.map((item) => (
              <li className={css["group"]} key={item.categoryID}>
                <Link
                  className={css["menu-link"]}
                  to={"/group_menu/" + item.categoryID}
                >
                  {item.name}
                </Link>
                <div
                  className={
                    item.top_bottom === "top"
                      ? css["subgroup"]
                      : css["subgroup-bottom"]
                  }
                  style={{ minHeight: String(list.length * 27 + 4) + "px" }}
                >
                  <ul className={css["subgroup-ul"]}>
                    {item.items
                      .sort((a, b) => a.count - b.count)
                      .map(
                        (elem) =>
                          elem.items.length === 0 && (
                            <li
                              key={elem.categoryID}
                              className={css["subgroup-wrap"]}
                            >
                              <Link
                                className={css["menu-link"]}
                                to={
                                  "/products_list/" +
                                  elem.categoryID +
                                  "?page=1&perPage=" +
                                  perPage
                                }
                              >
                                <div className={css["link-block"]}>
                                  {elem.name}
                                </div>
                              </Link>
                            </li>
                          )
                      )}
                  </ul>
                  <ul className={css["subgroup-ul"]}>
                    {item.items
                      .sort((a, b) => a.count - b.count)
                      .map(
                        (elem) =>
                          elem.items.length !== 0 &&
                          (elem.items.length < 7 ? (
                            <li
                              key={elem.categoryID}
                              className={css["subgroup-wrap"]}
                            >
                              <div className={css["group-block"]}>
                                <Link
                                  className={css["menu-link"]}
                                  to={"/group_menu/" + elem.categoryID}
                                >
                                  {elem.name}
                                </Link>
                                {elem.items.map((elem) => (
                                  <div key={elem.categoryID}>
                                    <Link
                                      className={css["menu-link"]}
                                      to={
                                        "/products_list/" +
                                        elem.categoryID +
                                        "?page=1&perPage=" +
                                        perPage
                                      }
                                    >
                                      <div className={css["group-block-item"]}>
                                        {elem.name}
                                      </div>
                                    </Link>
                                  </div>
                                ))}
                              </div>
                            </li>
                          ) : (
                            <li
                              key={elem.categoryID}
                              className={css["subgroup-wrap"]}
                            >
                              <div className={css["group-block"]}>
                                <Link
                                  className={css["menu-link"]}
                                  to={"/group_menu/" + elem.categoryID}
                                >
                                  {elem.name}
                                </Link>
                                {elem.items.slice(0, 7).map((elem) => (
                                  <div key={elem.categoryID}>
                                    <Link
                                      className={css["menu-link"]}
                                      to={
                                        "/products_list/" +
                                        elem.categoryID +
                                        "?page=1&perPage=" +
                                        perPage
                                      }
                                    >
                                      <div className={css["group-block-item"]}>
                                        {elem.name}
                                      </div>
                                    </Link>
                                  </div>
                                ))}
                                <div className={css["show-more"]}>
                                  {elem.items.length > 7 && (
                                    <span className={css["show-more-hide"]}>
                                      Показати більше!
                                    </span>
                                  )}
                                  {elem.items
                                    .slice(7, elem.items.length)
                                    .map((elem) => (
                                      <div key={elem.categoryID}>
                                        <Link
                                          className={css["menu-link"]}
                                          to={
                                            "/products_list/" +
                                            elem.categoryID +
                                            "?page=1&perPage=" +
                                            perPage
                                          }
                                        >
                                          <div
                                            className={
                                              css["group-block-item-more"]
                                            }
                                          >
                                            {elem.name}
                                          </div>
                                        </Link>
                                      </div>
                                    ))}
                                </div>
                              </div>
                            </li>
                          ))
                      )}
                  </ul>
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
};

export default CatalogMenu;
