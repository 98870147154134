import { Outlet } from "react-router-dom";
import PortalMenu from "../components/PortalMenu/PortalMenu";

const Portal = () => {
  return (
    <div className="main">
      <div className="home-sidebar">
        <PortalMenu />
      </div>
      <div className="portal-content">
        <Outlet />
      </div>
    </div>
  );
};

export default Portal;
