import { useEffect, useState } from "react";
import css from "./Dictionaries.module.css";
import apiaxios from "../../common/apiaxios";
import CustomInput from "../control/CustomInput/CustomInput";
import CheckBool from "../control/CheckBool/CheckBool";
import ButtonSave from "../control/ButtonSave/ButtonSave";
import ButtonClose from "../control/ButtonClose/ButtonClose";
import SelectFromList from "../control/SelectFromList/SelectFromList";

const FirmCard = ({ id, modalClose, width = "500px" }) => {
  const [mode, setMode] = useState("general");
  const [data, setData] = useState({});
  const [change, setChange] = useState(false);
  const [firmID, setFirmID] = useState();
  const [name, setName] = useState("");
  const [fullName, setFullName] = useState("");
  const [prefix, setPrefix] = useState("");
  const [director, setDirector] = useState("");
  const [adress, setAdress] = useState("");
  const [adressPost, setAdressPost] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [EDRPOU, setEDRPOU] = useState("");
  const [IPN, setIPN] = useState("");
  const [account, setAccount] = useState("");
  const [tax, setTax] = useState("");
  const [blocking, setBlocking] = useState("");
  const [registration, setRegistration] = useState("");
  const [fiscalNumber, setFiscalNumber] = useState("");

  useEffect(() => {
    apiaxios
      .get("/firms/get_firm_by_id?id=" + id)
      .then((res) => {
        setFirmID(res.data.id);
        setName(res.data.name);
        setFullName(res.data.full_name);
        setPrefix(res.data.prefix);
        setDirector(res.data.director);
        setAdress(res.data.adress);
        setAdressPost(res.data.adress_post);
        setPhone(res.data.phone);
        setEmail(res.data.email);
        setEDRPOU(res.data.EDRPOU);
        setIPN(res.data.IPN);
        setAccount(res.data.account);
        setTax(res.data.tax);
        setBlocking(res.data.blocking);
        setRegistration(res.data.registration);
        setFiscalNumber(res.data.fiscal_number);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id]);

  useEffect(() => {
    setData({
      id: firmID,
      name: name,
      full_name: fullName,
      prefix: prefix,
      director: director,
      adress: adress,
      adress_post: adressPost,
      phone: phone,
      email: email,
      EDRPOU: EDRPOU,
      IPN: IPN,
      account: account,
      tax: tax,
      blocking: blocking,
      registration: registration,
      fiscal_number: fiscalNumber,
    });
  }, [
    id,
    name,
    fullName,
    adress,
    phone,
    email,
    EDRPOU,
    IPN,
    account,
    firmID,
    prefix,
    director,
    adressPost,
    tax,
    blocking,
    registration,
    fiscalNumber,
  ]);

  return (
    <div className={css["modal-container"]}>
      <div className={css["modal-content"]} style={{ width: width }}>
        <div className={css["modal-header-container"]}>
          <div style={{ display: "flex" }}>
            <div
              className={
                mode === "general"
                  ? css["modal-header-mode-active"]
                  : css["modal-header-mode"]
              }
              onClick={() => {
                setMode("general");
              }}
            >
              Загальне
            </div>
            <div
              className={
                mode === "registration"
                  ? css["modal-header-mode-active"]
                  : css["modal-header-mode"]
              }
              onClick={() => {
                setMode("registration");
              }}
            >
              Реквізити
            </div>
          </div>
          <div className={css["modal-header-close-btn"]} onClick={modalClose}>
            <svg width="20" height="20">
              <use xlinkHref="#svg-icon__close" />
            </svg>
          </div>
        </div>
        <div style={{ display: "flex" }}>
          <div
            className={css["modal-atribute-block"]}
            onClick={() => {
              setChange(true);
            }}
          >
            <CustomInput
              value={name}
              setValue={setName}
              label={"Назва:"}
              width={"150px"}
            />
          </div>
          <div
            className={css["modal-atribute-block"]}
            onClick={() => {
              setChange(true);
            }}
          >
            <CustomInput
              value={prefix}
              setValue={setPrefix}
              label={"Префікс:"}
              maxlength="3"
              width={"50px"}
            />
          </div>
          <div
            // className={css["modal-atribute-block"]}
            style={{ marginTop: "13px", marginLeft: "13px" }}
            onClick={() => {
              setChange(true);
            }}
          >
            <CheckBool
              value={blocking}
              setValue={setBlocking}
              label={"заблоковано"}
            />
          </div>
        </div>
        {mode === "general" && (
          <>
            <div
              className={css["modal-atribute-block"]}
              onClick={() => {
                setChange(true);
              }}
            >
              <CustomInput
                value={fullName}
                setValue={setFullName}
                label={"Повна назва:"}
                width={"375px"}
              />
            </div>
            <div
              className={css["modal-atribute-block"]}
              onClick={() => {
                setChange(true);
              }}
            >
              <CustomInput
                value={adress}
                setValue={setAdress}
                label={"Адреса:"}
                width={"420px"}
              />
            </div>
            <div
              className={css["modal-atribute-block"]}
              onClick={() => {
                setChange(true);
              }}
            >
              <CustomInput
                value={adressPost}
                setValue={setAdressPost}
                label={"Поштова адреса:"}
                width={"350px"}
              />
            </div>
            <div style={{ display: "flex" }}>
              <div
                className={css["modal-atribute-block"]}
                onClick={() => {
                  setChange(true);
                }}
              >
                <CustomInput
                  value={phone}
                  setValue={setPhone}
                  label={"Телефон:"}
                  width={"130px"}
                />
              </div>
              <div
                className={css["modal-atribute-block"]}
                onClick={() => {
                  setChange(true);
                }}
              >
                <CustomInput
                  value={email}
                  setValue={setEmail}
                  label={"Email:"}
                  width={"200px"}
                />
              </div>
            </div>
          </>
        )}
        {mode === "registration" && (
          <>
            <div style={{ display: "flex" }}>
              <div
                className={css["modal-atribute-block"]}
                onClick={() => {
                  setChange(true);
                }}
              >
                <CustomInput
                  value={EDRPOU}
                  setValue={setEDRPOU}
                  label={"ЄДРПОУ:"}
                  width={"150px"}
                />
              </div>
              <div
                className={css["modal-atribute-block"]}
                onClick={() => {
                  setChange(true);
                }}
              >
                <CustomInput
                  value={IPN}
                  setValue={setIPN}
                  label={"ІПН:"}
                  width={"170px"}
                />
              </div>
            </div>
            <div style={{ display: "flex", margin: "0px 0px 10px 10px" }}>
              <div
                // className={css["modal-atribute-block"]}
                onClick={() => {
                  setChange(true);
                }}
              >
                <SelectFromList
                  requestPath={"/staff/get_stafflist/"}
                  label={"Директор:"}
                  value={director}
                  setValue={setDirector}
                  width="180px"
                />
              </div>
            </div>
            <div
              className={css["modal-atribute-block"]}
              onClick={() => {
                setChange(true);
              }}
            >
              <CustomInput
                value={tax}
                setValue={setTax}
                label={"Податки:"}
                width={"400px"}
              />
            </div>
            <div
              className={css["modal-atribute-block"]}
              onClick={() => {
                setChange(true);
              }}
            >
              <CustomInput
                value={registration}
                setValue={setRegistration}
                label={"Реєстрація:"}
                width={"370px"}
              />
            </div>
          </>
        )}
        <div style={{ display: "flex" }}>
          <div
            onClick={() => {
              if (change) {
                modalClose();
              }
            }}
          >
            <ButtonSave
              active={change}
              data={data}
              redirect="firms_dictionary"
              requestPath={"firms/save_firm_card/"}
            />
          </div>
          <div onClick={modalClose}>
            <ButtonClose />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FirmCard;
