import { useEffect, useState } from "react";
import css from "./Dictionaries.module.css";
import apiaxios from "../../common/apiaxios";
import CustomInput from "../control/CustomInput/CustomInput";
import ButtonSave from "../control/ButtonSave/ButtonSave";
import ButtonClose from "../control/ButtonClose/ButtonClose";

const AccountCard = ({ id, modalClose, width = "500px" }) => {
  const [data, setData] = useState({});
  const [change, setChange] = useState(false);
  const [accountID, setAccountID] = useState();
  const [name, setName] = useState("");
  const [prefix, setPrefix] = useState("");
  const [account, setAccount] = useState("");
  const [MFO, setMFO] = useState("");
  const [bank, setBank] = useState("");

  useEffect(() => {
    apiaxios
      .get("/firms/get_account_by_id?id=" + id)
      .then((res) => {
        setAccountID(res.data.id);
        setName(res.data.name);
        setPrefix(res.data.prefix);
        setAccount(res.data.account);
        setMFO(res.data.MFO);
        setBank(res.data.bank);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id]);

  useEffect(() => {
    setData({
      id: accountID,
      name: name,
      prefix: prefix,
      account: account,
      MFO: MFO,
      bank: bank,
    });
  }, [id, name, account, prefix, accountID, MFO, bank]);

  return (
    <div className={css["modal-container"]}>
      <div className={css["modal-content"]} style={{ width: width }}>
        <div className={css["modal-header-container"]}>
          <div style={{ display: "flex" }}>
            <div>Рахунок (каса): {name}</div>
            <div style={{ marginLeft: "15px" }}>ID: {accountID}</div>
          </div>
          <div className={css["modal-header-close-btn"]} onClick={modalClose}>
            <svg width="20" height="20">
              <use xlinkHref="#svg-icon__close" />
            </svg>
          </div>
        </div>
        <div style={{ display: "flex" }}>
          <div
            className={css["modal-atribute-block"]}
            onClick={() => {
              setChange(true);
            }}
          >
            <CustomInput
              value={name}
              setValue={setName}
              label={"Назва:"}
              width={"270px"}
            />
          </div>
          <div
            className={css["modal-atribute-block"]}
            onClick={() => {
              setChange(true);
            }}
          >
            <CustomInput
              value={prefix}
              setValue={setPrefix}
              label={"Префікс:"}
              maxlength="3"
              width={"50px"}
            />
          </div>
        </div>
        <div
          className={css["modal-atribute-block"]}
          onClick={() => {
            setChange(true);
          }}
        >
          <CustomInput
            value={account}
            setValue={setAccount}
            label={"Номер рахунку:"}
            width={"350px"}
          />
        </div>
        <div style={{ display: "flex" }}>
          <div
            className={css["modal-atribute-block"]}
            onClick={() => {
              setChange(true);
            }}
          >
            <CustomInput
              value={bank}
              setValue={setBank}
              label={"Банк:"}
              width={"300px"}
            />
          </div>
          <div
            className={css["modal-atribute-block"]}
            onClick={() => {
              setChange(true);
            }}
          >
            <CustomInput
              value={MFO}
              setValue={setMFO}
              label={"МФО:"}
              maxlength="6"
              width={"60px"}
            />
          </div>
        </div>
        <div style={{ display: "flex" }}>
          <div
            onClick={() => {
              if (change) {
                modalClose();
              }
            }}
          >
            <ButtonSave
              active={change}
              data={data}
              redirect="firms_dictionary"
              requestPath={"firms/save_firm_card/"}
            />
          </div>
          <div onClick={modalClose}>
            <ButtonClose />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountCard;
