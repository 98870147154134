import apiaxios from "../../common/apiaxios";
import { useEffect, useState } from "react";
import { IconContext } from "react-icons";
import { CgRadioCheck, CgRadioChecked } from "react-icons/cg";
import css from "./Orders.module.css";
import NovaPoshta from "../NovaPoshta/NovaPoshta";
import Basket from "../Basket/Basket";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import {
  selectBasketAmount,
  selectBasketItems,
} from "../../redux/basketSelectors";
import {
  changeBasketAmount,
  changeBasketAmountIn,
  changeBasketClient,
  changeBasketCount,
  changeBasketItems,
} from "../../redux/basketSlice";

const OrderNew = () => {
  const userStatus = useSelector((state) => state.user.status);
  const basket = useSelector(selectBasketItems);
  const basketAmount = useSelector(selectBasketAmount);
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [firstname, setFirstname] = useState("");
  const [surname, setSurname] = useState("");
  const [telephone, setTelephone] = useState("");
  const [coment, setComent] = useState("");
  const [delivery, setDelivery] = useState("office");
  const [regionRef, setRegionRef] = useState("");
  const [regionName, setRegionName] = useState("");
  const [cityRef, setCityRef] = useState("");
  const [cityName, setCityName] = useState("");
  const [pointRef, setPointRef] = useState("");
  const [pointName, setPointName] = useState("");
  const [adressDelivery, setAdressDelivery] = useState("");

  const submit = async (e) => {
    if (
      firstname === "" ||
      surname === "" ||
      email === "" ||
      telephone === ""
    ) {
      toast.error(
        "Всі поля секції 'Дані клієнта' є обов'язковими до заповнення!"
      );
      return;
    }
    if (
      delivery === "np" &&
      (regionName === "" || cityName === "" || pointName === "")
    ) {
      toast.error(
        "Заповніть будь ласка всі реквізити для доставки Новою поштою!"
      );
      return;
    }
    if (
      delivery === "np_adr" &&
      (regionName === "" || cityName === "" || adressDelivery === "")
    ) {
      toast.error(
        "Заповніть будь ласка всі реквізити для доставки Новою поштою!"
      );
      return;
    }
    if (basket.length === 0) {
      toast.error("Кошик порожній!");
      return;
    }
    const order = {
      username: email,
      items: basket.filter((product) => product.available),
      amount: basketAmount,
      coment: coment,
      delivery: delivery,
      deliveryDetails: {
        firstname: firstname,
        surname: surname,
        telephone: telephone,
        regionName: regionName,
        cityName: cityName,
        pointName: pointName,
        adressDelivery: adressDelivery,
      },
    };

    await apiaxios
      .post(
        "/orders/new_order/",
        order,
        { headers: { "Content-Type": "application/json" } },
        { withCredentials: true }
      )
      .then(() => {
        if (userStatus === "guest") {
          window.location.href = "/";
        } else if (userStatus === "user") {
          window.location.href = "/profile/orders?type=new_order";
        } else {
          window.location.href = "/portal/orders_list";
        }
        localStorage.removeItem("basketStorage");
        localStorage.removeItem("orderAmount");
        dispatch(changeBasketItems([]));
        dispatch(changeBasketCount(0));
        dispatch(changeBasketAmount(0));
        dispatch(changeBasketAmountIn(0));
        dispatch(changeBasketClient({ id: 0, name: "" }));
      });
  };

  const changeDelivery = (e) => {
    setDelivery(e.currentTarget.id);
  };

  useEffect(() => {
    if (localStorage.getItem("access_token")) {
      const path = window.location.href;
      apiaxios
        .get("/users/get_user_info/")
        .then((res) => {
          setFirstname(res.data.first_name);
          setSurname(res.data.last_name);
          setTelephone(res.data.telephone);
          setEmail(res.data.email);
        })
        .catch((err) => {
          console.log(err);
          window.location.href = path;
        });
    }
  }, []);

  useEffect(() => {
    setCityRef("");
    setCityName("");
    setPointRef("");
    setPointName("");
  }, [regionRef]);

  useEffect(() => {
    setPointRef("");
    setPointName("");
  }, [cityRef]);

  return (
    <div className={css["order-container-all"]}>
      <div className={css["order-title"]}>Оформлення замовлення</div>
      <div className={css["order-container"]}>
        <div className={css["order-details-container"]}>
          <div onSubmit={submit} className="form-order">
            {/* {result === "success" && (
              <div className={css["change-info-message"]}>
                Зміни внесено успішно!
              </div>
            )}
            {result === "error" && (
              <div className={css["change-info-message"]}>
                Помилка підтвердження паролем!
              </div>
            )} */}
            <div>
              <div>
                <div className={css["form-group"]}>
                  <label>Прізвище:</label>
                  <input
                    className={css["form-control"]}
                    name="surname"
                    type="text"
                    value={surname}
                    required
                    onChange={(e) => setSurname(e.target.value)}
                  />
                </div>
                <div className={css["form-group"]}>
                  <label>Ім'я:</label>
                  <input
                    className={css["form-control"]}
                    name="firstname"
                    type="text"
                    value={firstname}
                    required
                    onChange={(e) => setFirstname(e.target.value)}
                  />
                </div>
                <div className={css["form-group"]}>
                  <label>Номер телефону:</label>
                  <input
                    className={css["form-control"]}
                    name="telephone"
                    type="text"
                    value={telephone}
                    required
                    onChange={(e) => setTelephone(e.target.value)}
                  />
                </div>
                <div className={css["form-group"]}>
                  <label>Електронна пошта:</label>
                  <input
                    className={css["form-control"]}
                    name="username"
                    type="email"
                    value={email}
                    required
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>
              <div>
                <div>
                  <div
                    id="office"
                    className={css["delivery-item"]}
                    onClick={changeDelivery}
                  >
                    <IconContext.Provider value={{ size: "1em" }}>
                      <div>
                        {delivery === "office" && <CgRadioChecked />}
                        {delivery !== "office" && <CgRadioCheck />}
                      </div>
                    </IconContext.Provider>
                    <div className={css["delivery-label"]}>
                      Самовивіз з магазину м.Суми, вул.Привокзальна, 15
                    </div>
                  </div>
                  <div
                    id="np"
                    className={css["delivery-item"]}
                    onClick={changeDelivery}
                  >
                    <IconContext.Provider value={{ size: "1em" }}>
                      <div>
                        {delivery === "np" && <CgRadioChecked />}
                        {delivery !== "np" && <CgRadioCheck />}
                      </div>
                    </IconContext.Provider>
                    <div className={css["delivery-label"]}>
                      Новою поштою на відділення
                    </div>
                  </div>
                  <div
                    id="np_adr"
                    className={css["delivery-item"]}
                    onClick={changeDelivery}
                  >
                    <IconContext.Provider value={{ size: "1em" }}>
                      <div>
                        {delivery === "np_adr" && <CgRadioChecked />}
                        {delivery !== "np_adr" && <CgRadioCheck />}
                      </div>
                    </IconContext.Provider>
                    <div className={css["delivery-label"]}>
                      Новою поштою кур'єром
                    </div>
                  </div>
                </div>
              </div>
              <div>
                {(delivery === "np" || delivery === "np_adr") && (
                  <NovaPoshta
                    data={regionRef}
                    setData={setRegionRef}
                    dataName={regionName}
                    setDataName={setRegionName}
                    type={"region"}
                    info={""}
                  />
                )}
                {(delivery === "np" || delivery === "np_adr") &&
                  regionRef !== "" && (
                    <NovaPoshta
                      data={cityRef}
                      setData={setCityRef}
                      dataName={cityName}
                      setDataName={setCityName}
                      type={"city"}
                      info={regionRef}
                    />
                  )}
                {delivery === "np" && regionRef !== "" && cityRef !== "" && (
                  <NovaPoshta
                    data={pointRef}
                    setData={setPointRef}
                    dataName={pointName}
                    setDataName={setPointName}
                    type={"point"}
                    info={cityRef}
                  />
                )}
                {delivery === "np_adr" &&
                  regionRef !== "" &&
                  cityRef !== "" && (
                    <NovaPoshta
                      data={adressDelivery}
                      setData={setAdressDelivery}
                      dataName={adressDelivery}
                      setDataName={setAdressDelivery}
                      type={"adress"}
                      info={pointRef}
                    />
                  )}
              </div>
            </div>
            <div className={css["form-group"]}>
              <label>Коментар до замовлення:</label>
              <input
                className={css["form-control"]}
                name="coment"
                type="text"
                value={coment}
                required
                onChange={(e) => setComent(e.target.value)}
              />
            </div>

            <div className={css["form-btn"]}>
              <button onClick={submit} className="btn">
                Оформити
              </button>
            </div>
          </div>
          <ToastContainer />
        </div>
        <div className={css["basket-container"]}>
          <Basket type={"order"} className={css["basket"]} />
        </div>
      </div>
    </div>
  );
};

export default OrderNew;
