import { useEffect, useState } from "react";
import css from "./Dictionaries.module.css";
import apiaxios from "./../../common/apiaxios";
import CustomInput from "../control/CustomInput/CustomInput";
import CheckBool from "../control/CheckBool/CheckBool";
import CustomTextArea from "../control/CustomTextArea/CustomTextArea";
import ButtonSave from "../control/ButtonSave/ButtonSave";
import ButtonClose from "../control/ButtonClose/ButtonClose";
import SelectFromList from "../control/SelectFromList/SelectFromList";

const ClientCard = ({ id, modalClose, width = "500px" }) => {
  const [mode, setMode] = useState("general");
  const [data, setData] = useState({});
  const [change, setChange] = useState(false);
  const [clientID, setClientID] = useState();
  const [name, setName] = useState("");
  const [fullName, setFullName] = useState("");
  const [adress, setAdress] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [EDRPOU, setEDRPOU] = useState("");
  const [division, setDivision] = useState("");
  const [IPN, setIPN] = useState("");
  const [staffMember, setStaffMember] = useState("");
  const [exInfo, setExInfo] = useState("");
  const [dogovirVOsobi, setDogovirVOsobi] = useState("");
  const [dogovirNaPidstavi, setDogovirNaPidstavi] = useState("");
  const [dogovirKerivnik, setDogovirKerivnik] = useState("");
  const [account, setAccount] = useState("");
  const [accountSec, setAccountSec] = useState("");
  const [bank, setBank] = useState("");
  const [MFO, setMFO] = useState("");
  const [kazna, setKazna] = useState("");
  const [priceScheme, setPriceScheme] = useState("");

  useEffect(() => {
    apiaxios
      .get("/partners/get_client_by_id?id=" + id)
      .then((res) => {
        setClientID(res.data.info.id);
        setName(res.data.info.name);
        setFullName(res.data.info.full_name);
        setAdress(res.data.info.adress);
        setPhone(res.data.info.phone);
        setEmail(res.data.info.email);
        setEDRPOU(res.data.info.EDRPOU);
        setDivision(res.data.info.division);
        setIPN(res.data.info.IPN);
        setStaffMember(res.data.manager);
        setExInfo(res.data.info.ex_info);
        setDogovirVOsobi(res.data.info.dog_v_osobi);
        setDogovirNaPidstavi(res.data.info.dog_na_pidstavi);
        setDogovirKerivnik(res.data.info.dog_kerivnik);
        setAccount(res.data.info.account);
        setAccountSec(res.data.info.account_sec);
        setBank(res.data.info.bank);
        setMFO(res.data.info.MFO);
        setKazna(res.data.info.kazna);
        setPriceScheme(res.data.price_scheme);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id]);

  useEffect(() => {
    setData({
      id: clientID,
      name: name,
      full_name: fullName,
      adress: adress,
      phone: phone,
      email: email,
      EDRPOU: EDRPOU,
      division: division,
      IPN: IPN,
      staff_member: staffMember,
      ex_info: exInfo,
      dog_v_osobi: dogovirVOsobi,
      dog_na_pidstavi: dogovirNaPidstavi,
      dog_kerivnik: dogovirKerivnik,
      account: account,
      accountSec: accountSec,
      bank: bank,
      MFO: MFO,
      kazna: kazna,
      price_scheme: priceScheme,
    });
  }, [
    id,
    name,
    fullName,
    adress,
    phone,
    email,
    EDRPOU,
    division,
    IPN,
    staffMember,
    clientID,
    exInfo,
    dogovirVOsobi,
    dogovirNaPidstavi,
    dogovirKerivnik,
    account,
    accountSec,
    bank,
    MFO,
    kazna,
    priceScheme,
  ]);

  return (
    <div className={css["modal-container"]}>
      <div className={css["modal-content"]} style={{ width: width }}>
        <div className={css["modal-header-container"]}>
          <div style={{ display: "flex" }}>
            <div
              className={
                mode === "general"
                  ? css["modal-header-mode-active"]
                  : css["modal-header-mode"]
              }
              onClick={() => {
                setMode("general");
              }}
            >
              Загальне
            </div>
            <div
              className={
                mode === "contract"
                  ? css["modal-header-mode-active"]
                  : css["modal-header-mode"]
              }
              onClick={() => {
                setMode("contract");
              }}
            >
              Реквізити договору
            </div>
            <div
              className={
                mode === "info"
                  ? css["modal-header-mode-active"]
                  : css["modal-header-mode"]
              }
              onClick={() => {
                setMode("info");
              }}
            >
              Інформація
            </div>
          </div>
          <div className={css["modal-header-close-btn"]} onClick={modalClose}>
            <svg width="20" height="20">
              <use xlinkHref="#svg-icon__close" />
            </svg>
          </div>
        </div>
        <div
          className={css["modal-atribute-block"]}
          onClick={() => {
            setChange(true);
          }}
        >
          <CustomInput
            value={name}
            setValue={setName}
            label={"Назва:"}
            width={"420px"}
          />
        </div>
        {mode === "general" && (
          <>
            <div
              className={css["modal-atribute-block"]}
              onClick={() => {
                setChange(true);
              }}
            >
              <CustomInput
                value={fullName}
                setValue={setFullName}
                label={"Повна назва:"}
                width={"375px"}
              />
            </div>
            <div
              className={css["modal-atribute-block"]}
              onClick={() => {
                setChange(true);
              }}
            >
              <CustomInput
                value={adress}
                setValue={setAdress}
                label={"Адреса:"}
                width={"420px"}
              />
            </div>
            <div
              className={css["modal-atribute-block"]}
              onClick={() => {
                setChange(true);
              }}
            >
              <CustomInput
                value={phone}
                setValue={setPhone}
                label={"Телефон:"}
                width={"420px"}
              />
            </div>
            <div
              className={css["modal-atribute-block"]}
              onClick={() => {
                setChange(true);
              }}
            >
              <CustomInput
                value={email}
                setValue={setEmail}
                label={"Email:"}
                width={"420px"}
              />
            </div>
            <div
              className={css["modal-atribute-block"]}
              onClick={() => {
                setChange(true);
              }}
            >
              <CustomInput
                value={EDRPOU}
                setValue={setEDRPOU}
                label={"ЄДРПОУ:"}
                width={"420px"}
              />
            </div>
            <div
              className={css["modal-atribute-block"]}
              onClick={() => {
                setChange(true);
              }}
            >
              <CustomInput
                value={division}
                setValue={setDivision}
                label={"Філія:"}
                width={"420px"}
              />
            </div>
            <div
              className={css["modal-atribute-block"]}
              onClick={() => {
                setChange(true);
              }}
            >
              <CustomInput
                value={IPN}
                setValue={setIPN}
                label={"ІПН:"}
                width={"420px"}
              />
            </div>
            <div style={{ display: "flex", margin: "0px 0px 10px 10px" }}>
              <div
                // className={css["modal-atribute-block"]}
                onClick={() => {
                  setChange(true);
                }}
              >
                <SelectFromList
                  requestPath={"/staff/get_stafflist/"}
                  label={"Менеджер:"}
                  value={staffMember}
                  setValue={setStaffMember}
                  width="180px"
                />
              </div>
              <div
                // className={css["modal-atribute-block"]}
                onClick={() => {
                  setChange(true);
                }}
              >
                <SelectFromList
                  requestPath={"/catalog/get_prices_out_list/"}
                  label={"Цінова схема:"}
                  value={priceScheme}
                  setValue={setPriceScheme}
                  width="70px"
                />
              </div>
            </div>
          </>
        )}
        {mode === "contract" && (
          <>
            <div
              className={css["modal-atribute-block"]}
              onClick={() => {
                setChange(true);
              }}
            >
              <CustomInput
                value={dogovirVOsobi}
                setValue={setDogovirVOsobi}
                label={"В особі:"}
                width={"400px"}
              />
            </div>
            <div
              className={css["modal-atribute-block"]}
              onClick={() => {
                setChange(true);
              }}
            >
              <CustomInput
                value={dogovirNaPidstavi}
                setValue={setDogovirNaPidstavi}
                label={"На підставі:"}
                width={"370px"}
              />
            </div>
            <div
              className={css["modal-atribute-block"]}
              onClick={() => {
                setChange(true);
              }}
            >
              <CustomInput
                value={dogovirKerivnik}
                setValue={setDogovirKerivnik}
                label={"Керівник:"}
                width={"400px"}
              />
            </div>
            <div
              className={css["modal-atribute-block"]}
              onClick={() => {
                setChange(true);
              }}
            >
              <CustomInput
                value={account}
                setValue={setAccount}
                label={"Рахунок осонвний:"}
                width={"320px"}
              />
            </div>
            <div
              className={css["modal-atribute-block"]}
              onClick={() => {
                setChange(true);
              }}
            >
              <CustomInput
                value={accountSec}
                setValue={setAccountSec}
                label={"Рахунок додатковий:"}
                width={"320px"}
              />
            </div>
            <div
              className={css["modal-atribute-block"]}
              onClick={() => {
                setChange(true);
              }}
            >
              <CustomInput
                value={bank}
                setValue={setBank}
                label={"Банк:"}
                width={"420px"}
              />
            </div>
            <div
              className={css["modal-atribute-block"]}
              onClick={() => {
                setChange(true);
              }}
            >
              <CustomInput
                value={MFO}
                setValue={setMFO}
                label={"МФО:"}
                width={"420px"}
              />
            </div>
            <div
              className={css["modal-atribute-block"]}
              onClick={() => {
                setChange(true);
              }}
            >
              <CheckBool
                value={kazna}
                setValue={setKazna}
                label={"документи через казначейство"}
              />
            </div>
          </>
        )}
        {mode === "info" && (
          <>
            <div
              className={css["modal-atribute-block"]}
              onClick={() => {
                setChange(true);
              }}
            >
              <CustomTextArea
                value={exInfo}
                setValue={setExInfo}
                label={"Інформація:"}
                width={"460px"}
                rows={"13"}
              />
            </div>
          </>
        )}
        <div style={{ display: "flex" }}>
          <div
            onClick={() => {
              if (change) {
                modalClose();
              }
            }}
          >
            <ButtonSave
              active={change}
              data={data}
              redirect="clients_dictionary"
              requestPath={"partners/save_client_card/"}
            />
          </div>
          <div onClick={modalClose}>
            <ButtonClose />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientCard;
