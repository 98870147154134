import { useEffect, useState } from "react";
import css from "./Dictionaries.module.css";
import apiaxios from "../../common/apiaxios";
import CustomInput from "../control/CustomInput/CustomInput";
import CheckBool from "../control/CheckBool/CheckBool";
import ButtonSave from "../control/ButtonSave/ButtonSave";
import ButtonClose from "../control/ButtonClose/ButtonClose";

const StaffCard = ({ id, modalClose, width = "500px" }) => {
  const [mode, setMode] = useState("general");
  const [data, setData] = useState({});
  const [change, setChange] = useState(false);
  const [staffID, setStaffID] = useState();
  const [name, setName] = useState("");
  const [surename, setSurename] = useState("");
  const [firstname, setFirstname] = useState("");
  const [secondname, setSecondname] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [cofounder, setCofounder] = useState(false);
  const [namePrint, setNamePrint] = useState("");
  const [nameDoverka, setNameDoverka] = useState("");
  const [passportCode, setPassportCode] = useState("");
  const [passportNumber, setPassportNumber] = useState("");
  const [passportWhoGive, setPassportWhoGive] = useState("");
  const [passportWhenGive, setPassportWhenGive] = useState();
  const [salary, setSalary] = useState("");
  const [active, setActive] = useState(false);
  const [checkFromPrefix, setCheckFromPrefix] = useState("");

  useEffect(() => {
    apiaxios
      .get("/staff/get_staff_by_id?id=" + id)
      .then((res) => {
        setStaffID(res.data.id);
        setName(res.data.name);
        setSurename(res.data.surename);
        setFirstname(res.data.firstname);
        setSecondname(res.data.secondname);
        setJobTitle(res.data.job_title);
        setCofounder(res.data.co_founder);
        setNamePrint(res.data.name_print);
        setNameDoverka(res.data.name_doverka);
        setPassportCode(res.data.passport_code);
        setPassportNumber(res.data.passport_number);
        setPassportWhoGive(res.data.passport_who_give);
        setPassportWhenGive(res.data.passport_when_give);
        setSalary(res.data.salary ? res.data.salary : 0);
        setActive(res.data.active);
        setCheckFromPrefix(res.data.check_from_prefix);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id]);

  useEffect(() => {
    setData({
      id: staffID,
      name: name,
      surename: surename,
      firstname: firstname,
      secondname: secondname,
      job_title: jobTitle,
      co_founder: cofounder,
      name_print: namePrint,
      name_doverka: nameDoverka,
      passport_code: passportCode,
      passport_number: passportNumber,
      passport_who_give: passportWhoGive,
      passport_when_give: passportWhenGive,
      salary: salary,
      active: active,
      check_from_prefix: checkFromPrefix,
    });
  }, [
    active,
    checkFromPrefix,
    cofounder,
    firstname,
    jobTitle,
    name,
    nameDoverka,
    namePrint,
    passportCode,
    passportNumber,
    passportWhenGive,
    passportWhoGive,
    salary,
    secondname,
    staffID,
    surename,
  ]);

  return (
    <div className={css["modal-container"]}>
      <div className={css["modal-content"]} style={{ width: width }}>
        <div className={css["modal-header-container"]}>
          <div style={{ display: "flex" }}>
            <div
              className={
                mode === "general"
                  ? css["modal-header-mode-active"]
                  : css["modal-header-mode"]
              }
              onClick={() => {
                setMode("general");
              }}
            >
              Загальне
            </div>
            <div
              className={
                mode === "passport"
                  ? css["modal-header-mode-active"]
                  : css["modal-header-mode"]
              }
              onClick={() => {
                setMode("passport");
              }}
            >
              Паспорт
            </div>
            <div
              className={
                mode === "other"
                  ? css["modal-header-mode-active"]
                  : css["modal-header-mode"]
              }
              onClick={() => {
                setMode("other");
              }}
            >
              Інше
            </div>
          </div>
          <div className={css["modal-header-close-btn"]} onClick={modalClose}>
            <svg width="20" height="20">
              <use xlinkHref="#svg-icon__close" />
            </svg>
          </div>
        </div>
        <div
          className={css["modal-atribute-block"]}
          onClick={() => {
            setChange(true);
          }}
        >
          <CustomInput
            value={name}
            setValue={setName}
            label={"Співробітник:"}
            width={"420px"}
          />
        </div>
        {mode === "general" && (
          <>
            <div
              className={css["modal-atribute-block"]}
              onClick={() => {
                setChange(true);
              }}
            >
              <CustomInput
                value={surename}
                setValue={setSurename}
                label={"Прізвище:"}
                width={"375px"}
              />
            </div>
            <div style={{ display: "flex" }}>
              <div
                className={css["modal-atribute-block"]}
                onClick={() => {
                  setChange(true);
                }}
              >
                <CustomInput
                  value={firstname}
                  setValue={setFirstname}
                  label={"Ім'я:"}
                  width={"160px"}
                />
              </div>
              <div
                className={css["modal-atribute-block"]}
                onClick={() => {
                  setChange(true);
                }}
              >
                <CustomInput
                  value={secondname}
                  setValue={setSecondname}
                  label={"По батькові:"}
                  width={"160px"}
                />
              </div>
            </div>
            <div
              className={css["modal-atribute-block"]}
              onClick={() => {
                setChange(true);
              }}
            >
              <CustomInput
                value={jobTitle}
                setValue={setJobTitle}
                label={"Посада:"}
                width={"420px"}
              />
            </div>
            <div style={{ display: "flex" }}>
              <div
                className={css["modal-atribute-block"]}
                onClick={() => {
                  setChange(true);
                }}
              >
                <CheckBool
                  value={active}
                  setValue={setActive}
                  label={"активний"}
                />
              </div>
              <div
                className={css["modal-atribute-block"]}
                onClick={() => {
                  setChange(true);
                }}
              >
                <CheckBool
                  value={cofounder}
                  setValue={setCofounder}
                  label={"співзасновник"}
                />
              </div>
            </div>
          </>
        )}
        {mode === "passport" && (
          <>
            <div style={{ display: "flex" }}>
              <div
                className={css["modal-atribute-block"]}
                onClick={() => {
                  setChange(true);
                }}
              >
                <CustomInput
                  value={passportCode}
                  setValue={setPassportCode}
                  label={"Серія:"}
                  maxlength="2"
                  width={"50px"}
                />
              </div>
              <div
                className={css["modal-atribute-block"]}
                onClick={() => {
                  setChange(true);
                }}
              >
                <CustomInput
                  value={passportNumber}
                  setValue={setPassportNumber}
                  label={"Номер:"}
                  width={"100px"}
                />
              </div>
              <div
                className={css["modal-atribute-block"]}
                onClick={() => {
                  setChange(true);
                }}
              >
                <CustomInput
                  value={passportWhenGive}
                  setValue={setPassportWhenGive}
                  label={"від"}
                  type="date"
                  width={"120px"}
                />
              </div>
            </div>
            <div
              className={css["modal-atribute-block"]}
              onClick={() => {
                setChange(true);
              }}
            >
              <CustomInput
                value={passportWhoGive}
                setValue={setPassportWhoGive}
                label={"Виданий:"}
                width={"400px"}
              />
            </div>
          </>
        )}
        {mode === "other" && (
          <>
            <div
              className={css["modal-atribute-block"]}
              onClick={() => {
                setChange(true);
              }}
            >
              <CustomInput
                value={namePrint}
                setValue={setNamePrint}
                label={"Ім'я для друку документів:"}
                width={"370px"}
              />
            </div>
            <div
              className={css["modal-atribute-block"]}
              onClick={() => {
                setChange(true);
              }}
            >
              <CustomInput
                value={nameDoverka}
                setValue={setNameDoverka}
                label={"Ім'я для друку довіреності:"}
                width={"400px"}
              />
            </div>
            <div style={{ display: "flex" }}>
              <div
                className={css["modal-atribute-block"]}
                onClick={() => {
                  setChange(true);
                }}
              >
                <CustomInput
                  value={salary}
                  setValue={setSalary}
                  label={"Заробітня платня:"}
                  type="number"
                  width={"100px"}
                />
              </div>
              <div
                className={css["modal-atribute-block"]}
                onClick={() => {
                  setChange(true);
                }}
              >
                <CustomInput
                  value={checkFromPrefix}
                  setValue={setCheckFromPrefix}
                  label={"Префікс ПРРО:"}
                  maxlength="3"
                  width={"50px"}
                />
              </div>
            </div>
          </>
        )}
        <div style={{ display: "flex" }}>
          <div
            onClick={() => {
              if (change) {
                modalClose();
              }
            }}
          >
            <ButtonSave
              active={change}
              data={data}
              redirect="staff_dictionary"
              requestPath={"staff/save_staff_card/"}
            />
          </div>
          <div onClick={modalClose}>
            <ButtonClose />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StaffCard;
