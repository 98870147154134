import './App.css';
import { Routes, Route } from "react-router-dom";
import Main from './pages/Main';
import NotFound from './pages/NotFound';
import Home from './pages/Home';
import Delivery from './pages/Delivery';
import SC from './pages/SC';
import Contact from './pages/Contact';
import About from './pages/About';
import ProductDetails from './components/ProductDetails/ProductDetails';
import GroupMenu from './components/GroupMenu/GroupMenu';
import Profile from './components/Profile/Profile';
import OrderNew from './components/Orders/OrderNew';
import Utility from './components/Profile/Utility';
import Info from './components/Profile/Info';
import Login from './components/Auth/Login';
import Registration from './components/Auth/Registration';
import RestorePassword from './components/Auth/RestorePassword';
import ProductsListPage from './pages/ProductsListPage';
import ProductsCompareDetails from './components/ProductsCompareDetails/ProductsCompareDetails';
import Statistic from './components/Profile/Statistic';
import OrdersList from './components/Orders/OrdersList';
import Portal from './pages/Portal';
import Sources from './components/Dictionaries/Sources';
import SourceItem from './components/Dictionaries/SourceItem';
import VendorsCategories from './components/Dictionaries/VendorsCategories';
import ProductDublicate from './components/ProductDublicate/ProductDublicate';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getStartUserData } from './components/Auth/getStartUserData';
import DictionaryList from './components/Dictionaries/DictionaryList';

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
      getStartUserData(dispatch);
  }, [dispatch]);
  return (
      <Routes>
        <Route path="/" element={<Main />}>
          <Route index element={<Home />} />
          <Route path="delivery" element={<Delivery />} />
          <Route path="sc" element={<SC />} />
          <Route path="contacts" element={<Contact />} />
          <Route path="about" element={<About />} />

          <Route path="login" element={<Login />} />
          <Route path="registration" element={<Registration />} />
          <Route path="restore_password" element={<RestorePassword />} />

          <Route path="profile/orders" element={<Profile active_menu={"order_user"} />} />
          <Route path="profile/orders_list" element={<Profile active_menu={"orders_list"} />} />
          <Route path="profile/favorite" element={<Profile active_menu={"favorite"} />} />
          <Route path="profile/compare" element={<Profile active_menu={"compare"} />} />
          <Route path="profile/info" element={<Profile active_menu={"info"} />} />
          <Route path="profile/utility" element={<Profile active_menu={"utility"} />} />
          <Route path="profile/info_mob" element={<Info />} />
          <Route path="utility" element={<Utility />} />
          <Route path="statistic" element={<Statistic />} />
          <Route path="order" element={<OrderNew />} />

          <Route path="group_menu/:categoryID" element={<GroupMenu />} />
          <Route path="product_details/:id" element={<ProductDetails />} />
          <Route path="products_list/:categoryID" element={<ProductsListPage />} />
          <Route path="search" element={<ProductsListPage />} />
          <Route path="compare" element={<ProductsCompareDetails />} />

          <Route path="portal/" element={<Portal />}>
            <Route index element={<OrdersList type={"orders_list"} />} />
            <Route path="orders_list" element={<OrdersList type={"orders_list"} />} />
            <Route path="sources_list" element={<Sources />} />
            <Route path="sources_list/:id" element={<SourceItem type={"edit"} />} />
            <Route path="sources_list/update/:vendor" element={<SourceItem type={"update"} />} />
            <Route path="sources_list/create" element={<SourceItem type={"create"} />} />
            <Route path="vendors_categories_list" element={<VendorsCategories />} />
            <Route path="products_without_category/:categoryID" element={<ProductsListPage />} />
            <Route path="product_dublicate" element={<ProductDublicate />} />
            <Route path="clients_dictionary" element={<DictionaryList pageTitle={"Довідник контрагентів"} path={"/partners/get_clients_list/"} type={'client'} />} />
            <Route path="users_dictionary" element={<DictionaryList pageTitle={"Довідник користувачів"} path={"/users/get_users_list/"} type={'user'} />} />
            <Route path="staff_dictionary" element={<DictionaryList pageTitle={"Довідник співробітників"} path={"/staff/get_staff_list/"} type={'staff'} />} />
            <Route path="firms_dictionary" element={<DictionaryList pageTitle={"Довідник фірми"} path={"/firms/get_firms_list/"} type={'firms'} />} />
            <Route path="accounts_dictionary" element={<DictionaryList pageTitle={"Довідник розрахункові рахунки та каси"} path={"/firms/get_accounts_list/"} type={'accounts'} />} />
          </Route>
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
  );
}

export default App;
