import { useState, useContext } from "react";
import userStatusContext from "../../context/userStatusContext";
import css from "./CategoriesFilters.module.css";
import { IconContext } from "react-icons";
import { CgChevronDown, CgChevronRight } from "react-icons/cg";

const CategoriesFiltersPrice = ({ catalogNavigate }) => {
  const {
    sortTypePrice,
    search,
    filters,
    perPage,
    setPage,
    minPrice,
    setMinPrice,
    maxPrice,
    setMaxPrice,
  } = useContext(userStatusContext);
  const [show, setShow] = useState(
    minPrice !== 0 || maxPrice !== 1000000 ? true : false
  );

  return (
    <>
      <div className={css["filter-block-group"]}>
        <div
          className={css["filter-block-button"]}
          onClick={() => {
            setShow(!show);
          }}
        >
          {show ? (
            <IconContext.Provider value={{ size: "1em" }}>
              <div>
                <CgChevronDown />
              </div>
            </IconContext.Provider>
          ) : (
            <IconContext.Provider value={{ size: "1em" }}>
              <div>
                <CgChevronRight />
              </div>
            </IconContext.Provider>
          )}
        </div>
        <div
          className={css["filter-block-group-title"]}
          onClick={() => {
            setShow(!show);
          }}
        >
          Фільтр за ціною
        </div>
        {show && (
          <div className={css["filter-price-block"]}>
            <div className={css["filter-price-group"]}>
              <label>від</label>
              <input
                className={css["filter-price-input"]}
                name="from"
                value={minPrice}
                onChange={(e) => {
                  setMinPrice(Number(e.target.value));
                  catalogNavigate(
                    filters,
                    perPage,
                    1,
                    sortTypePrice,
                    search,
                    Number(e.target.value),
                    maxPrice
                  );
                  setPage(1);
                }}
              />
            </div>
            <div className={css["filter-price-group"]}>
              <label>до</label>
              <input
                className={css["filter-price-input"]}
                name="to"
                value={maxPrice}
                // onBlur={() => {}}
                onChange={(e) => {
                  setMaxPrice(Number(e.target.value));
                  catalogNavigate(
                    filters,
                    perPage,
                    1,
                    sortTypePrice,
                    search,
                    minPrice,
                    Number(e.target.value)
                  );
                  setPage(1);
                }}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default CategoriesFiltersPrice;
