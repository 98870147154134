import { useEffect, useState } from "react";
import { Circles } from "react-loader-spinner";
import apiaxios from "../../common/apiaxios";
import ProductCard from "../ProductCard/ProductCard";
import css from "./ProductDublicate.module.css";

const ProductDublicate = () => {
  const [productList, setProductList] = useState([]);
  const [count, setCount] = useState(0);
  const [circle, setCircle] = useState(true);

  useEffect(() => {
    setCircle(true);

    apiaxios
      .get("/catalog/get_vendors_vendor_code/")
      .then((res) => {
        setProductList(res.data["result"]);
        setCount(res.data["count"]);
        setCircle(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <div className={css["container"]}>
      {circle && (
        <div className="spinner">
          <Circles
            height="150"
            width="150"
            color="rgb(50, 130, 250)"
            ariaLabel="circles-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      )}
      {!circle && (
        <div>
          <div className={css["title"]}>Всього: {count}</div>
          {productList.map((elem) => (
            <ul key={elem['num']} className={css["row"]}>
              {elem['list'].map((item) => (
                <li key={item.id}>
                  <ProductCard
                    id={item.id}
                    name={item.name}
                    categoryID={item.categoryID}
                    onoff={item.onoff}
                    brief_description={item.brief_description}
                    date_modified={item.date_modified}
                    price_im={item.price_im}
                    full_image={item.full_image}
                    favorite={item.favorite}
                    compare={item.compare}
                    prices={item.prices}
                    sale_prices={item.sale_prices}
                    iconLineView={"icon"}
                  />
                </li>
              ))}
            </ul>
          ))}
        </div>
      )}
    </div>
  );
};

export default ProductDublicate;
