import Contacts from "../components/Contacts/Contacts";

const Delivery = () => {
  return (
    <div className="pages-container">
      <div className="page-title">Доставка</div>
      {/* <div>
        <h2>Доставка</h2>
      </div> */}
      <ul>
        <div>
          При оформленні замовлення пропонуємо вибрати зручний для Вас спосіб
          отримати замовлення:
        </div>
        <li>
          • Ви можете самостійно забрати замовлення з нашого магазину “Суми
          ІТ-сервіс”, який розташований за адресою: м. Суми, вул. Ппривокзальна,
          будинок 15;
        </li>
        <li>
          • ми можемо відправити Вам замовлення Новою Поштою на зручне для Вас
          відділення.
        </li>
      </ul>
      <div>
        <p>
          Вартість доставки: безкоштовна з магазину та згідно тарифів
          перевізника, при замовленні через Нову пошту.
        </p>
      </div>
      <div className="page-title">Оплата</div>
      {/* <div>
        <h2>Оплата</h2>
      </div> */}
      <div>
        <p>
          Для оформлення замовлення необхідна попередня оплата у безготівковій
          формі на рахунок компанії. В залежності від юридичного статусу клієнта
          ми сформуємо рахунок фактуру з ПДВ чи без ПДВ та оформимо всі
          необхідні документи згідно чинного законодавства України.
        </p>
      </div>
      <div>
        <p>
          Для постійних клієнтів можливе замовлення товару без попередньої
          оплати.
        </p>
      </div>
      <Contacts />
    </div>
  );
};

export default Delivery;
