import css from "./CustomSelect.module.css";

const CustomSelect = ({ value, setValue, valueList, label }) => {
  return (
    <div className={css["container"]}>
      <div className={css["select-label"]}>
        <label htmlFor="select">{label}</label>
      </div>
      <select
        id="select"
        name="select"
        value={value}
        className={css["select"]}
        onChange={(e) => {
          setValue(e.target.value);
        }}
      >
        {valueList.map((item) => (
          <option key={item["id"]} value={item["id"]}>
            {item["name"]}
          </option>
        ))}
      </select>
    </div>
  );
};

export default CustomSelect;
