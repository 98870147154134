import { useEffect, useState } from "react";
import css from "./ProductsCompareDetails.module.css";
import no_photo from "../../no-photo-api.png";
import { Link, useSearchParams } from "react-router-dom";
import apiaxios from "../../common/apiaxios";
import ProductCard from "../ProductCard/ProductCard";
import BuyButtonModal from "../Basket/BuyButtonModal";
import { useSelector } from "react-redux";

const ProductsCompareDetails = () => {
  const priceScheme = useSelector((state) => state.basket.priceScheme);
  const [group, setGroup] = useState();
  const [categoryID, setCategoryID] = useState();
  const [list, setList] = useState([]);
  const [listOptions, setListOptions] = useState([]);
  const [searchParams] = useSearchParams();
  const [perPage] = useState(
    JSON.parse(localStorage.getItem("productsPerPage"))
      ? JSON.parse(localStorage.getItem("productsPerPage"))
      : 20
  );

  useEffect(() => {
    apiaxios
      .get("/catalog/get_compare_details?id=" + searchParams.get("products"), {
        params: {
          price_scheme: priceScheme,
        },
      })
      .then((res) => {
        setGroup(res.data["group"]);
        setCategoryID(res.data["categoryID"]);
        setList(res.data["productList"]);
        setListOptions(res.data["optionsList"]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [searchParams, priceScheme]);

  return (
    <div className={css["container-all"]}>
      <div className={css["page-title"]}>
        <div>
          <Link
            to={"/products_list/" + categoryID + "?page=1&perPage=" + perPage}
            className={css["page-title-link"]}
          >
            {group}
          </Link>
        </div>
        {/* <div className={css["page-title-mode"]}>Тільки відмінності</div> */}
      </div>
      {list.length < 3 && (
        <div className={css["list-block"]}>
          {list.map((item) => (
            <div key={item.id} className={css["list-item"]}>
              <ProductCard
                id={item.id}
                name={item.name}
                categoryID={item.categoryID}
                onoff={item.onoff}
                brief_description={item.brief_description}
                date_modified={item.date_modified}
                price_im={item.user_price}
                price_max={item.price_im}
                full_image={item.full_image}
                favorite={item.favorite}
                compare={item.compare}
                prices={item.prices}
                sale_prices={item.sale_prices}
                iconLineView={"icon"}
              />
            </div>
          ))}
        </div>
      )}
      {list.length >= 3 && (
        <div className={css["option-block-header"]}>
          <div className={css["option-title"]}>Товар</div>
          <div className={css["product-row-block"]}>
            <div className={css["product-row"]}>
              {list.map((item) => (
                <div key={item.id} className={css["list-item-row-header"]}>
                  <Link
                    to={"/product_details/" + item.id}
                    className={css["list-item-row-header-name"]}
                  >
                    {item.name}
                  </Link>
                </div>
              ))}
            </div>
            <div className={css["product-row"]}>
              {list.map((item) => (
                <div key={item.id} className={css["list-item-row-header"]}>
                  {item.full_image ? (
                    <Link to={"/product_details/" + item.id}>
                      <img
                        src={item.full_image}
                        width={"100px"}
                        alt={item.name}
                      />
                    </Link>
                  ) : (
                    <Link to={"/product_details/" + item.id}>
                      <img src={no_photo} width={"100px"} alt={item.name} />
                    </Link>
                  )}
                </div>
              ))}
            </div>
            <div className={css["product-row"]}>
              {list.map((item) => (
                <div key={item.id} className={css["list-item-row-header"]}>
                  <div id="buy" className={css["product-buy-price-block"]}>
                    {item.onoff && (
                      <BuyButtonModal
                        id={item.id}
                        price_im={item.price_im}
                        name={item.name}
                        full_image={item.full_image}
                      />
                    )}
                    {!item.onoff && (
                      <>
                        <div className={css["btn-buy-off"]}>
                          <div className={css["btn-buy-svg-off"]}>
                            <svg width="13" height="13">
                              <use xlinkHref="#svg-icon__cart" />
                            </svg>
                          </div>
                        </div>
                        <div className={css["price-na"]}>NA ₴</div>
                      </>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
      {listOptions.map((option) => (
        <div key={option.optionID} className={css["option-block"]}>
          <div className={css["option-title"]}>{option.name}</div>
          <div className={css["list-block-row"]}>
            {list.map((item) => (
              <div key={item.id} className={css["list-item-row"]}>
                {item.options.map((elem) => (
                  <div key={elem.optionID}>
                    {elem.optionID === option.optionID && (
                      <div>{elem.value}</div>
                    )}
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      ))}
      <div className={css["option-block"]}></div>
    </div>
  );
};

export default ProductsCompareDetails;
