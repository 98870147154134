import logo from "../../ITS_Logo.png";
import { useContext, useState } from "react";
import css from "./Header.module.css";
import { NavLink, useNavigate } from "react-router-dom";
import Login from "../Auth/Login";
import CatalogMenu from "../CatalogMenu/CatalogMenu";
import Basket from "../Basket/Basket";
import CountCircle from "../CountCircle/CountCircle";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Search from "../Search/Search";
import apiaxios from "../../common/apiaxios";
import Sprite from "../../common/Sprite";
import CatalogMobile from "../CatalogMobile/CatalogMobile";
import modalStatusContext from "../../context/modalStatusContext";
import Modal from "../../common/Modal";
import Registration from "../Auth/Registration";
import RestorePassword from "../Auth/RestorePassword";
import Message from "../Auth/Message";
import { useSelector } from "react-redux";

const Header = () => {
  const userStatus = useSelector((state) => state.user.status);
  const firstname = useSelector((state) => state.user.name);
  const surname = useSelector((state) => state.user.surname);
  const email = useSelector((state) => state.user.email);
  const favoriteCount = useSelector((state) => state.user.favoriteCount);
  const compareCount = useSelector((state) => state.user.compareCount);
  const basketCount = useSelector((state) => state.basket.count);
  const navigate = useNavigate();

  const { modalIsOpen, openModal, closeModal, contentModal } =
    useContext(modalStatusContext);
  const [activeMenu, setActiveMenu] = useState(false);
  const [showCatalog, setShowCatalog] = useState(false);
  function handleBackdropClick(e) {
    if (e.target.classList.contains("backdrop-modal")) {
      setShowCatalog(!showCatalog); // закрити модальне вікно, якщо клікнуто на бекдроп
    }
  }

  function logout() {
    window.location.href = "/";
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    apiaxios.defaults.headers.common["Authorization"] = null;
  }

  return (
    <header>
      <div className="hide">
        <Sprite />
      </div>

      <div className={css["header-nav-container"]}>
        <div className={css["header-nav"]}>
          <div
            className={css["nav-mobile-catalog-btn"]}
            id="catalog-mobile"
            onClick={openModal}
          >
            <svg width="30" height="30">
              <use xlinkHref="#svg-icon__bars" />
            </svg>
          </div>
          <div className={css["logo-block-small"]}>
            <div>
              <NavLink to="/">
                <img
                  src={logo}
                  alt="logo"
                  width={window.innerWidth >= 480 ? "150px" : "100px"}
                />
              </NavLink>
            </div>
          </div>

          <ul className={css["nav-menu"]}>
            <li>
              <NavLink to="about" className={css["nav-menu-item"]}>
                Про Суми ІТ-сервіс
              </NavLink>
            </li>
            <li>
              <NavLink to="sc" className={css["nav-menu-item"]}>
                Сервісний центр
              </NavLink>
            </li>
            <li>
              <NavLink to="delivery" className={css["nav-menu-item"]}>
                Доставка і оплата
              </NavLink>
            </li>
            <li>
              <NavLink to="contacts" className={css["nav-menu-item"]}>
                Контакти
              </NavLink>
            </li>
          </ul>
          <div className={css["contact-menu"]}>
            <div className={css["contact-menu-item"]}>
              <ul className={css["contact-menu-phones"]}>
                <div className="svg-icon">
                  <svg width="15" height="15">
                    <use xlinkHref="#svg-icon__phone" />
                  </svg>
                </div>
                <li className={css["contact-menu-phones-item"]}>
                  <a href="tel:+380542619125">0542 61 91 25</a>
                </li>
                <li className={css["contact-menu-phones-item"]}>
                  <a href="tel:+380667779125">066 777 91 25</a>
                </li>
                <li className={css["contact-menu-phones-item"]}>
                  <a href="tel:+380677779125">067 777 91 25</a>
                </li>
              </ul>
            </div>
            <div className={css["contact-menu-line-block"]}>
              <div className="svg-icon">
                <svg width="15" height="15">
                  <use xlinkHref="#svg-icon__pointer" />
                </svg>
              </div>
              <div className={css["contact-menu-item"]}>
                місто Суми, вулиця Привокзальна, будинок 15
              </div>
            </div>
            <div className={css["contact-menu-line-block"]}>
              <div className="svg-icon">
                <svg width="15" height="15">
                  <use xlinkHref="#svg-icon__clock" />
                </svg>
              </div>
              <div className={css["contact-menu-item"]}>
                Пн–Пт 08:00–17:00, Сб, Нд - вихідні дні
              </div>
            </div>
          </div>

          <ul className={css["buttons"]}>
            <li>
              <div
                className={css["header-icon"]}
                onClick={() => {
                  if (userStatus !== "guest") {
                    navigate("/profile/favorite", { replace: true });
                    window.location.reload();
                  } else {
                    toast.error("Доступно тільки зареєстрованим користувачам!");
                  }
                }}
              >
                <svg width="30" height="30">
                  <use xlinkHref="#svg-icon__heart" />
                </svg>
                <div className={css["header-icon-label"]}>ОБРАНЕ</div>
                {favoriteCount > 0 && (
                  <div className={css["header-basket_circle"]}>
                    <CountCircle count={favoriteCount} />
                  </div>
                )}
              </div>
            </li>
            <li>
              <div
                className={css["header-icon"]}
                onClick={() => {
                  if (userStatus !== "guest") {
                    navigate("/profile/compare", { replace: true });
                    window.location.reload();
                  } else {
                    toast.error("Доступно тільки зареєстрованим користувачам!");
                  }
                }}
              >
                <svg width="30" height="30">
                  <use xlinkHref="#svg-icon__libra" />
                </svg>
                <div className={css["header-icon-label"]}>ПОРІВНЯТИ</div>
                {compareCount > 0 && (
                  <div className={css["header-basket_circle"]}>
                    <CountCircle count={compareCount} />
                  </div>
                )}
              </div>
            </li>
            <li>
              <div
                className={css["header-icon"]}
                id="basket"
                onClick={openModal}
              >
                <svg width="30" height="30">
                  <use xlinkHref="#svg-icon__cart" />
                </svg>
                <div className={css["header-icon-label"]}>КОШИК</div>
                {basketCount > 0 && (
                  <div className={css["header-basket_circle"]}>
                    <CountCircle count={basketCount} />
                  </div>
                )}
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div className={css["header-search-container"]}>
        <div className={css["header-search"]}>
          <div
            id="catalog"
            className={css["header-search-catalog"]}
            onClick={() => {
              setShowCatalog(!showCatalog);
            }}
          >
            <div className={css["svg-icon-catalog-btn"]}>
              <svg width="15" height="15">
                <use xlinkHref="#svg-icon__bars" />
              </svg>
            </div>
            <div id="catalog" className={css["header-search-catalog-text"]}>
              Каталог
            </div>
            <div className={css["svg-icon-catalog-btn"]}>
              <svg width="12" height="12">
                <use xlinkHref="#svg-icon__caret-down" />
              </svg>
            </div>
            {showCatalog && (
              <>
                <div className={css["catalog-menu-absolute"]}></div>
                <div className={css["catalog-menu"]}>
                  <CatalogMenu />
                </div>
              </>
            )}
          </div>
          <div className={css["header-search-block"]}>
            <Search />
          </div>

          <div
            className={css["profile-btn"]}
            onMouseEnter={(e) => {
              setActiveMenu(true);
            }}
            onMouseLeave={() => {
              setActiveMenu(false);
            }}
          >
            <div className={css["header-search-catalog-text"]}>
              {!email && <p>Особистий кабінет</p>}
              {email && (
                <>
                  <div className={css["header-search-catalog-text-name"]}>
                    {firstname} {surname}
                  </div>
                  <div className={css["header-search-catalog-text-email"]}>
                    {email}
                  </div>
                </>
              )}
            </div>
            <div className={css["svg-icon-profile-btn"]}>
              <svg width="12" height="12">
                <use xlinkHref="#svg-icon__caret-down" />
              </svg>
            </div>
            {activeMenu && (
              <ul className={css["profile-menu"]}>
                <li
                  onClick={() => {
                    setActiveMenu(false);
                  }}
                >
                  <div
                    className={css["profile-menu-item"]}
                    id="basket"
                    onClick={openModal}
                  >
                    <div>Кошик</div>
                  </div>
                </li>
                {userStatus !== "guest" ? (
                  <>
                    <li
                      onClick={() => {
                        setActiveMenu(false);
                      }}
                    >
                      <NavLink
                        to="/profile/orders"
                        className={css["profile-menu-item"]}
                      >
                        <div>Кабінет</div>
                      </NavLink>
                    </li>
                    <li
                      onClick={() => {
                        setActiveMenu(false);
                      }}
                    >
                      <div
                        className={css["profile-menu-item"]}
                        onClick={logout}
                      >
                        <div>Вихід</div>
                      </div>
                    </li>
                  </>
                ) : (
                  <>
                    <li
                      onClick={() => {
                        setActiveMenu(false);
                      }}
                    >
                      <NavLink to="/login" className={css["profile-menu-item"]}>
                        <div>Вхід</div>
                      </NavLink>
                    </li>
                    <li
                      onClick={() => {
                        setActiveMenu(false);
                      }}
                    >
                      <NavLink
                        to="/registration"
                        className={css["profile-menu-item"]}
                      >
                        <div>Реєстрація</div>
                      </NavLink>
                    </li>
                  </>
                )}
                {userStatus === "super" && (
                  <>
                    <li
                      onClick={() => {
                        setActiveMenu(false);
                      }}
                    >
                      <NavLink
                        to="/portal/"
                        className={css["profile-menu-item"]}
                      >
                        <div>Портал</div>
                      </NavLink>
                    </li>
                    <li
                      onClick={() => {
                        setActiveMenu(false);
                      }}
                    >
                      <NavLink
                        to="/utility"
                        className={css["profile-menu-item"]}
                      >
                        <div>Утіліти</div>
                      </NavLink>
                    </li>
                    <li
                      onClick={() => {
                        setActiveMenu(false);
                      }}
                    >
                      <NavLink
                        to="/statistic"
                        className={css["profile-menu-item"]}
                      >
                        <div>Статистика</div>
                      </NavLink>
                    </li>
                  </>
                )}
              </ul>
            )}
          </div>
        </div>
      </div>
      {modalIsOpen && (
        <Modal>
          {contentModal === "basket" && (
            <div className={css["modal-basket-container"]}>
              <Basket modalClose={closeModal} />
            </div>
          )}
          {contentModal === "login" && (
            <div className={css["modal-basket-container"]}>
              <Login modalClose={closeModal} />
            </div>
          )}
          {contentModal === "reg" && (
            <div className={css["modal-basket-container"]}>
              <Registration modalClose={closeModal} />
            </div>
          )}
          {contentModal === "restore" && (
            <div className={css["modal-basket-container"]}>
              <RestorePassword modalClose={closeModal} />
            </div>
          )}
          {contentModal === "restore succsess" && (
            <div className={css["modal-message-container"]}>
              <Message
                modalClose={closeModal}
                message={"Новий пароль успішно надіслано Вам на пошту!"}
              />
            </div>
          )}
          {contentModal === "reg succsess" && (
            <div className={css["modal-message-container"]}>
              <Message
                modalClose={closeModal}
                message={"Реєстрація пройшла успішно!"}
                redirect={"/"}
              />
            </div>
          )}
          {contentModal === "reg fail" && (
            <div className={css["modal-message-container"]}>
              <Message
                modalClose={closeModal}
                message={
                  "Користувач з таким логіном вже існую. Спробуйте відновити пароль."
                }
                redirect={"/"}
              />
            </div>
          )}
          {contentModal === "change user data" && (
            <div className={css["modal-message-container"]}>
              <Message
                modalClose={closeModal}
                message={"Дані успішно оновлено!"}
              />
            </div>
          )}
          {contentModal === "catalog-mobile" && (
            <div className={css["modal-catalog-mobile-container"]}>
              <CatalogMobile
                modalClose={closeModal}
                openModal={openModal}
                logout={logout}
                firstname={firstname}
                surname={surname}
                email={email}
              />
            </div>
          )}
        </Modal>
      )}
      {showCatalog && (
        <div className="backdrop-modal" onClick={handleBackdropClick}></div>
      )}
      <ToastContainer />
    </header>
  );
};

export default Header;
