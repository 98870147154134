import axios from "axios";
import { useState } from "react";
import css from "./NovaPoshta.module.css";

const NovaPoshta = ({ data, setData, dataName, setDataName, type, info }) => {
  const [allDataList, setAllDataList] = useState([]);
  const [dataList, setDataList] = useState([]);
  const [dataListShow, setDataListShow] = useState(false);

  const np_request_region = () => {
    axios
      .post("https://api.novaposhta.ua/v2.0/json/", {
        apiKey: process.env.REACT_APP_NP_API_KEY,
        modelName: "Address",
        calledMethod: "getSettlementAreas",
        methodProperties: {
          Ref: "",
        },
      })
      .then((res) => {
        setDataList(res.data.data);
        setAllDataList(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const np_request_point = () => {
    axios
      .post("https://api.novaposhta.ua/v2.0/json/", {
        apiKey: process.env.REACT_APP_NP_API_KEY,
        modelName: "Address",
        calledMethod: "getWarehouses",
        methodProperties: {
          SettlementRef: info,
        },
      })
      .then((res) => {
        setDataList(res.data.data);
        setAllDataList(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onDataClick = async (e) => {
    if (e.target.id === "input") {
      setData("");
      setDataName("");
    }
    if (type === "region") {
      np_request_region();
    } else if (type === "city") {
      let pageNum = 1;
      let newCityList = [];
      let get_data = [];
      do {
        get_data = await axios
          .post("https://api.novaposhta.ua/v2.0/json/", {
            apiKey: process.env.REACT_APP_NP_API_KEY,
            modelName: "Address",
            calledMethod: "getSettlements",
            methodProperties: {
              AreaRef: info,
              Page: pageNum,
              Limit: "150",
              Warehouse: "1",
            },
          })
          .then((res) => {
            return res.data.data;
          })
          .catch((err) => {
            console.log(err);
          });
        newCityList = [...newCityList, ...get_data];
        pageNum = pageNum + 1;
      } while (get_data.length > 0);
      setDataList(newCityList);
      setAllDataList(newCityList);
    } else if (type === "point") {
      np_request_point();
    } else if (type === "adress") {
      setDataList("");
    } else setDataList(allDataList);
    setDataListShow(!dataListShow);
  };

  const dataSelect = (e) => {
    setDataListShow(true);
    setDataList(
      allDataList.filter((item) =>
        item.Description.toLowerCase().includes(
          e.currentTarget.value.toLowerCase()
        )
      )
    );
  };

  const clickData = (e) => {
    setData(e.currentTarget.id);
    setDataName(
      dataList.find((item) => item.Ref === e.currentTarget.id)["Description"]
    );
    setDataListShow(false);
  };

  return (
    <div className={css["delivery-np"]}>
      <div>{type === "region" && "Область:"}</div>
      <div>{type === "city" && "Місто:"}</div>
      <div>{type === "point" && "Відділення:"}</div>
      <div>{type === "adress" && "Адреса:"}</div>
      {type === "adress" ? (
        // <div>
        <input
          className={css["adress-np-input"]}
          value={data}
          onInput={(e) => {
            setData(e.currentTarget.value);
          }}
        />
      ) : (
        // </div>
        <div className={css["select-np"]} onClick={onDataClick}>
          <div id="input">
            {dataName
              ? dataName
              : (type === "region" && "Виберіть область") ||
                (type === "city" && "Виберіть місто") ||
                (type === "point" && "Виберіть відділення")}
          </div>
          {dataListShow && (
            <div className={css["select-list"]}>
              <input
                type="text"
                placeholder="Почніть вводити"
                autoFocus
                className={css["search-input"]}
                onInput={dataSelect}
                onClick={() => setDataListShow(true)}
              />
              {dataList.map((item) => (
                <div
                  key={item.Ref}
                  className={css["select-item"]}
                  id={item.Ref}
                  onClick={clickData}
                >
                  {item.Description}
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default NovaPoshta;
