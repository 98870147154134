import { useState } from "react";
import css from "./Search.module.css";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import userStatusContext from "../../context/userStatusContext";

const Search = () => {
  const [searchText, setSearchText] = useState("");
  const navigate = useNavigate();
  const { setSearch, setPage, perPage } = useContext(userStatusContext);

  const changeText = (e) => {
    setSearchText(e.target.value);
  };

  const submitSearch = () => {
    if (searchText) {
      setPage(1);
      setSearch(searchText);
      navigate("/search?search=" + searchText + "&page=1&perPage=" + perPage);
      setSearchText("");
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && searchText) {
      submitSearch();
    }
  };

  return (
    <div className={css["search"]}>
      <input
        type="text"
        placeholder="Пошук..."
        value={searchText}
        onChange={changeText}
        onKeyDown={handleKeyDown}
        className={css["search-input"]}
      />
      <button onClick={submitSearch} className={css["search-btn"]}>
        <div style={{ fill: "#ffffff" }}>
          <svg width="15" height="15">
            <use xlinkHref="#svg-icon__search" />
          </svg>
        </div>
      </button>
    </div>
  );
};

export default Search;
