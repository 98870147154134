// import { useState } from "react";
import css from "./CustomTextArea.module.css";

const CustomTextArea = ({
  value,
  setValue,
  label,
  width = "250px",
  rows = "10",
}) => {
  // const [value, setValue] = useState(defaultValue);
  return (
    <div className={css["container"]}>
      <div className={css["textarea-label"]}>
        <label htmlFor="textarea">{label}</label>
      </div>
      <textarea
        id="textarea"
        name="textarea"
        value={value}
        rows={rows}
        placeholder="Опис відсутній..."
        className={css["textarea"]}
        style={{ width: width }}
        onChange={(e) => {
          setValue(e.target.value);
        }}
      />
    </div>
  );
};

export default CustomTextArea;
