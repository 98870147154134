import { useEffect, useState } from "react";
import css from "./Dictionaries.module.css";
import apiaxios from "../../common/apiaxios";
import CustomInput from "../control/CustomInput/CustomInput";
import CheckBool from "../control/CheckBool/CheckBool";
import ButtonSave from "../control/ButtonSave/ButtonSave";
import ButtonClose from "../control/ButtonClose/ButtonClose";
import SelectFromList from "../control/SelectFromList/SelectFromList";

const UserCard = ({ id, modalClose, width = "500px" }) => {
  const [data, setData] = useState({});
  const [change, setChange] = useState(false);
  const [userID, setUserID] = useState();
  const [username, setUsername] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [isStaff, setIsStaff] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [lastLogin, setLastLogin] = useState("");
  const [dateJoined, setDateJoined] = useState("");
  const [telephone, setTelephone] = useState("");
  const [fullCatalog, setFullCatalog] = useState(false);
  const [client, setClient] = useState("");
  const [staff, setStaff] = useState("");

  useEffect(() => {
    apiaxios
      .get("/users/get_user_by_id?id=" + id)
      .then((res) => {
        console.log(res.data);
        setUserID(res.data.id);
        setUsername(res.data.username);
        setFirstName(res.data.first_name);
        setLastName(res.data.last_name);
        setEmail(res.data.email);
        setIsStaff(res.data.is_staff);
        setIsActive(res.data.is_active);
        setLastLogin(res.data.last_login);
        setDateJoined(res.data.date_joined);
        setTelephone(res.data.telephone);
        setFullCatalog(res.data.full_catalog);
        setClient(res.data.client);
        setStaff(res.data.staff);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id]);

  useEffect(() => {
    setData({
      id: userID,
      first_name: firstName,
      last_name: lastName,
      telephone: telephone,
      email: email,
      is_staff: isStaff,
      staffID: staff["id"],
      full_catalog: fullCatalog,
      is_active: isActive,
      client: client,
    });
  }, [
    client,
    email,
    firstName,
    fullCatalog,
    id,
    isActive,
    isStaff,
    lastName,
    staff,
    telephone,
    userID,
    username,
  ]);

  useEffect(() => {
    if (!isStaff) {
      setStaff({ id: 0, name: "" });
    }
  }, [isStaff]);

  return (
    <div className={css["modal-container"]}>
      <div className={css["modal-content"]} style={{ width: width }}>
        <div className={css["modal-header-container"]}>
          <div style={{ display: "flex" }}>
            <div>Користувач: {username}</div>
            <div style={{ marginLeft: "15px" }}>ID: {userID}</div>
          </div>
          <div className={css["modal-header-close-btn"]} onClick={modalClose}>
            <svg width="20" height="20">
              <use xlinkHref="#svg-icon__close" />
            </svg>
          </div>
        </div>
        <div
          className={css["modal-atribute-block"]}
          onClick={() => {
            setChange(true);
          }}
        >
          <CustomInput
            value={lastName}
            setValue={setLastName}
            label={"Прізвище:"}
            width={"420px"}
          />
        </div>
        <div
          className={css["modal-atribute-block"]}
          onClick={() => {
            setChange(true);
          }}
        >
          <CustomInput
            value={firstName}
            setValue={setFirstName}
            label={"Ім'я:"}
            width={"420px"}
          />
        </div>
        <div
          className={css["modal-atribute-block"]}
          onClick={() => {
            setChange(true);
          }}
        >
          <CustomInput
            value={telephone}
            setValue={setTelephone}
            label={"Телефон:"}
            width={"420px"}
          />
        </div>
        <div
          className={css["modal-atribute-block"]}
          onClick={() => {
            setChange(true);
          }}
        >
          <CustomInput
            value={email}
            setValue={setEmail}
            label={"Email:"}
            width={"420px"}
          />
        </div>
        <div style={{ display: "flex" }}>
          <div
            className={css["modal-atribute-block"]}
            onClick={() => {
              setChange(true);
            }}
          >
            <CheckBool
              value={isActive}
              setValue={setIsActive}
              label={"активний"}
            />
          </div>
          <div
            className={css["modal-atribute-block"]}
            onClick={() => {
              setChange(true);
            }}
          >
            <CheckBool
              value={fullCatalog}
              setValue={setFullCatalog}
              label={"повний каталог"}
            />
          </div>
          <div
            className={css["modal-atribute-block"]}
            onClick={() => {
              setChange(true);
            }}
          >
            <CheckBool
              value={isStaff}
              setValue={setIsStaff}
              label={"співробітник"}
            />
          </div>
        </div>
        {isStaff && (
          <div style={{ display: "flex", margin: "0px 0px 10px 10px" }}>
            <div
              onClick={() => {
                setChange(true);
              }}
            >
              <SelectFromList
                requestPath={"/staff/get_stafflist/"}
                label={"Співробітник:"}
                value={staff}
                setValue={setStaff}
                width="180px"
              />
            </div>
          </div>
        )}
        <div style={{ display: "flex", margin: "0px 0px 10px 10px" }}>
          <div
            onClick={() => {
              setChange(true);
            }}
          >
            <SelectFromList
              requestPath={"/partners/get_clients/"}
              label={"Клієнт:"}
              value={client}
              setValue={setClient}
              width="180px"
            />
          </div>
        </div>
        <div style={{ display: "flex", margin: "0px 0px 10px 10px" }}>
          Дата реєстрації: {dateJoined}
        </div>
        <div style={{ display: "flex", margin: "0px 0px 10px 10px" }}>
          Останній вхід: {lastLogin}
        </div>
        <div style={{ display: "flex" }}>
          <div
            onClick={() => {
              if (change) {
                modalClose();
              }
            }}
          >
            <ButtonSave
              active={change}
              data={data}
              redirect="users_dictionary"
              requestPath={"users/save_user_card/"}
            />
          </div>
          <div onClick={modalClose}>
            <ButtonClose />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserCard;
