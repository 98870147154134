import css from "./Profile.module.css";
import { useEffect, useState } from "react";
import apiaxios from "../../common/apiaxios";
import { ToastContainer, toast } from "react-toastify";
import { useSelector } from "react-redux";

const Info = () => {
  const [email, setEmail] = useState("");
  const [firstname, setFirstname] = useState("");
  const [surname, setSurname] = useState("");
  const [telephone, setTelephone] = useState("");
  const [password, setPassword] = useState("");
  const [passwordAgain, setPasswordAgain] = useState("");
  const [passwordCurrent, setPasswordCurrent] = useState("");
  const [changePasswordShow, setChangePasswordShow] = useState(false);
  const [result, setResult] = useState("start");
  const userStatus = useSelector((state) => state.user.status);

  useEffect(() => {
    if (localStorage.getItem("access_token")) {
      const path = window.location.href;
      apiaxios
        .get("/users/get_user_info/")
        .then((res) => {
          setFirstname(res.data.first_name);
          setSurname(res.data.last_name);
          setTelephone(res.data.telephone);
          setEmail(res.data.email);
        })
        .catch((err) => {
          console.log(err);
          window.location.href = path;
        });
    }
  }, []);

  const changeClientData = async () => {
    const clientData = {
      username: email,
      firstname: firstname,
      surname: surname,
      email: email,
      telephone: telephone,
      password: password,
      passwordCurrent: passwordCurrent,
    };
    await apiaxios
      .post(
        "/users/change_password/",
        clientData,
        { headers: { "Content-Type": "application/json" } },
        { withCredentials: true }
      )
      .then((res) => {
        if (res.data.result === "success") {
          setResult("success");
        } else if (res.data.result === "fail") {
          setResult("error");
        }
      });
  };

  const submit = (e) => {
    if (passwordCurrent === "") {
      toast.warning("Зміни мають бути підтверджені паролем!");
      return;
    } else {
      e.preventDefault();
      changeClientData();
    }
  };

  return (
    <div className={css["info-container"]}>
      {userStatus !== "guest" && (
        <form className={css["prifile-user-data"]} onSubmit={submit}>
          <div className="page-title">Профіль клієнта</div>
          {result === "success" && (
            <div className={css["change-info-message"]}>
              Зміни внесено успішно!
            </div>
          )}
          {result === "error" && (
            <div className={css["change-info-message"]}>
              Помилка підтвердження паролем!
            </div>
          )}
          <div className={css["form-group"]}>
            <label>Електронна пошта (логін):</label>
            <input
              className={css["form-control-readonly"]}
              name="email"
              type="email"
              autoComplete="username"
              value={email}
              required
              readOnly
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className={css["form-group"]}>
            <label>Прізвище:</label>
            <input
              className={css["form-control"]}
              name="surname"
              type="text"
              value={surname}
              required
              onChange={(e) => setSurname(e.target.value)}
            />
          </div>
          <div className={css["form-group"]}>
            <label>Ім'я:</label>
            <input
              className={css["form-control"]}
              name="firstname"
              type="text"
              value={firstname}
              required
              onChange={(e) => setFirstname(e.target.value)}
            />
          </div>
          <div className={css["form-group"]}>
            <label>Номер телефону:</label>
            <input
              className={css["form-control"]}
              name="telephone"
              type="text"
              value={telephone}
              required
              onChange={(e) => setTelephone(e.target.value)}
            />
          </div>
          <div className={css["form-group"]}>
            <label>Пароль:</label>
            <input
              name="passwordCurrent"
              type="password"
              autoComplete="current-password"
              className={css["form-control"]}
              value={passwordCurrent}
              required
              onChange={(e) => setPasswordCurrent(e.target.value)}
            />
          </div>
          <div
            className={css["change-password"]}
            onClick={() => setChangePasswordShow(!changePasswordShow)}
          >
            Змінити пароль?
          </div>
          {changePasswordShow && (
            <>
              <div className="hide">
                <input name="username" autoComplete="username" />
              </div>
              <div className={css["form-group"]}>
                <label>Новий пароль:</label>
                <input
                  name="password"
                  type="password"
                  autoComplete="new-password"
                  className={css["form-control"]}
                  value={password}
                  required
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <div className={css["form-group"]}>
                <label>Підтвердження паролю:</label>
                <input
                  className={css["form-control"]}
                  name="passwordAgain"
                  type="password"
                  autoComplete="new-password"
                  value={passwordAgain}
                  required
                  onChange={(e) => setPasswordAgain(e.target.value)}
                />
              </div>
            </>
          )}
          <button type="submit" className={css["form-btn"]}>
            <div>Оновити дані клієнта</div>
          </button>
        </form>
      )}
      <ToastContainer />
    </div>
  );
};

export default Info;
