import { useContext, useEffect, useState } from "react";
import Basket from "./Basket";
import css from "./Basket.module.css";
import modalStatusContext from "../../context/modalStatusContext";
import Modal from "../../common/Modal";
import { useDispatch, useSelector } from "react-redux";
import {
  changeBasketAmount,
  changeBasketAmountIn,
  changeBasketCount,
  changeBasketItems,
} from "../../redux/basketSlice";
import {
  selectBasketAmount,
  selectBasketAmountIn,
  selectBasketCount,
  selectBasketItems,
} from "../../redux/basketSelectors";
import CountCircle from "../CountCircle/CountCircle";

const BuyButtonModal = ({
  id,
  name,
  full_image,
  price_im,
  price_max,
  vendor,
  price_in,
}) => {
  const { modalIsOpen, openModal, closeModal, contentModal } =
    useContext(modalStatusContext);
  const dispatch = useDispatch();
  const userStatus = useSelector((state) => state.user.status);
  const basket = useSelector(selectBasketItems);
  const basketCount = useSelector(selectBasketCount);
  const basketAmount = useSelector(selectBasketAmount);
  const basketAmountIn = useSelector(selectBasketAmountIn);
  const [quantBasket, setQuantBasket] = useState(0);

  useEffect(() => {
    for (const item of basket) {
      if (item.id === id) {
        setQuantBasket(item.quantity);
      }
    }
  }, [basket, id]);

  const addToBasket = (id, name, full_image, price_im, vendor, price_in) => {
    let changedBasket = basket.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          quantity: item.quantity + 1,
        };
      }
      return item;
    });
    const itemExists = changedBasket.some((item) => item.id === id);
    if (!itemExists) {
      const newItem = {
        id: id,
        name: name,
        full_image: full_image,
        price_im: price_im,
        quantity: 1,
        available: true,
        vendor:
          userStatus === "staff" || userStatus === "super" ? vendor : undefined,
        price_in:
          userStatus === "staff" || userStatus === "super"
            ? price_in
            : undefined,
      };
      changedBasket = [...changedBasket, newItem];
    }
    dispatch(changeBasketItems(changedBasket));
    dispatch(changeBasketCount(basketCount + 1));
    dispatch(changeBasketAmount(basketAmount + price_im));
    dispatch(changeBasketAmountIn(basketAmountIn + price_in));
  };

  return (
    <>
      <div
        className={css["btn-buy-container"]}
        onClick={(e) => {
          if (userStatus !== "super" && userStatus !== "staff") {
            openModal(e);
            e.stopPropagation();
          }
          addToBasket(id, name, full_image, price_im, vendor, price_in);
        }}
        id={id}
      >
        <div className={css["btn-buy"]}>
          <div className={css["btn-buy-svg"]}>
            <svg width="13" height="13">
              <use xlinkHref="#svg-icon__cart" />
            </svg>
            {quantBasket !== 0 && (
              <div className={css["quantity_circle"]}>
                <CountCircle count={quantBasket} />
              </div>
            )}
          </div>
        </div>
        <div>
          <div className={css["price-retail"]}>{price_im} ₴</div>
          {price_im !== price_max && (
            <div className={css["price-retail-max"]}>{price_max} ₴</div>
          )}
        </div>
      </div>
      {modalIsOpen && contentModal === String(id) && (
        <Modal>
          <div className={css["modal-basket-container"]}>
            <Basket modalClose={closeModal} />
          </div>
        </Modal>
      )}
    </>
  );
};

export default BuyButtonModal;
