import { useEffect, useState } from "react";
import { IconContext } from "react-icons";
import { CgRadioCheck, CgRadioChecked } from "react-icons/cg";
import css from "./Basket.module.css";
import NovaPoshta from "../NovaPoshta/NovaPoshta";
import InputMask from "react-input-mask";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import {
  selectBasketComent,
  selectBasketDelivery,
  selectBasketItems,
} from "../../redux/basketSelectors";
import {
  changeBasketComent,
  changeBasketDelivery,
} from "../../redux/basketSlice";

const BasketDelivery = ({ saveOrder, setDeliveryShow, modalClose }) => {
  const dispatch = useDispatch();
  const userStatus = useSelector((state) => state.user.status);
  const userEmail = useSelector((state) => state.user.email);
  const basket = useSelector(selectBasketItems);
  const basketDelivery = useSelector(selectBasketDelivery);
  const basketComent = useSelector(selectBasketComent);
  const [email, setEmail] = useState(basketDelivery["email"]);
  if (email === "" && userStatus !== "guest") {
    setEmail(userEmail);
  }
  const [firstname, setFirstname] = useState(basketDelivery["firstname"]);
  const [surname, setSurname] = useState(basketDelivery["surname"]);
  const [telephone, setTelephone] = useState(basketDelivery["telephone"]);
  const [coment, setComent] = useState(
    basketDelivery["coment"] ? basketDelivery["coment"] : basketComent
  );
  const [deliveryType, setDeliveryType] = useState(
    basketDelivery["deliveryType"] ? basketDelivery["deliveryType"] : "office"
  );
  const [regionRef, setRegionRef] = useState(basketDelivery["regionRef"] || "");
  const [regionName, setRegionName] = useState(
    basketDelivery["regionName"] || ""
  );
  const [cityRef, setCityRef] = useState(basketDelivery["cityRef"] || "");
  const [cityName, setCityName] = useState(basketDelivery["cityName"] || "");
  const [pointRef, setPointRef] = useState(basketDelivery["pointRef"] || "");
  const [pointName, setPointName] = useState(basketDelivery["pointName"] || "");
  const [adressDelivery, setAdressDelivery] = useState(
    basketDelivery["adressDelivery"] || ""
  );

  const submit = async (e) => {
    if (
      firstname === "" ||
      surname === "" ||
      email === "" ||
      telephone === ""
    ) {
      toast.error(
        "Всі поля секції 'Дані клієнта' є обов'язковими до заповнення!"
      );
      return;
    }
    if (
      (deliveryType === "np" || deliveryType === "np_adr") &&
      (regionName === "" || cityName === "" || pointName === "")
    ) {
      toast.error(
        "Заповніть будь ласка всі реквізити для доставки Новою поштою!"
      );
      return;
    }
    // if (
    //   deliveryType === "np_adr" &&
    //   (regionName === "" || cityName === "" || adressDelivery === "")
    // ) {
    //   toast.error(
    //     "Заповніть будь ласка всі реквізити для доставки Новою поштою!"
    //   );
    //   return;
    // }
    if (basket.length === 0) {
      toast.error("Кошик порожній!");
      return;
    }

    saveOrder();
  };

  const changeDelivery = (e) => {
    setDeliveryType(e.currentTarget.id);
  };

  useEffect(() => {
    if (regionRef !== basketDelivery["regionRef"]) {
      setCityRef("");
      setCityName("");
      setPointRef("");
      setPointName("");
    }
  }, [regionRef, basketDelivery]);

  useEffect(() => {
    if (cityRef !== basketDelivery["cityRef"]) {
      setPointRef("");
      setPointName("");
    }
  }, [cityRef, basketDelivery]);

  useEffect(() => {
    dispatch(
      changeBasketDelivery({
        deliveryType: deliveryType,
        firstname: firstname,
        surname: surname,
        telephone: telephone,
        email: email,
        regionName: regionName,
        regionRef: regionRef,
        cityName: cityName,
        cityRef: cityRef,
        pointName: pointName,
        pointRef: pointRef,
        adressDelivery: adressDelivery,
      })
    );
  }, [
    adressDelivery,
    cityName,
    cityRef,
    deliveryType,
    dispatch,
    email,
    firstname,
    pointName,
    pointRef,
    regionName,
    regionRef,
    surname,
    telephone,
  ]);

  return (
    <div className={css["delivery-container"]}>
      <div onSubmit={submit} className="form-order">
        <div className={css["delivery-block"]}>
          <div>
            <div
              id="office"
              className={css["delivery-item"]}
              onClick={changeDelivery}
            >
              <IconContext.Provider value={{ size: "1em" }}>
                <div>
                  {deliveryType === "office" && <CgRadioChecked />}
                  {deliveryType !== "office" && <CgRadioCheck />}
                </div>
              </IconContext.Provider>
              <div className={css["delivery-label"]}>
                Самовивіз з магазину м.Суми, вул.Привокзальна, 15
              </div>
            </div>
            <div
              id="np"
              className={css["delivery-item"]}
              onClick={changeDelivery}
            >
              <IconContext.Provider value={{ size: "1em" }}>
                <div>
                  {deliveryType === "np" && <CgRadioChecked />}
                  {deliveryType !== "np" && <CgRadioCheck />}
                </div>
              </IconContext.Provider>
              <div className={css["delivery-label"]}>
                Новою поштою на відділення
              </div>
            </div>
            <div
              id="np_adr"
              className={css["delivery-item"]}
              onClick={changeDelivery}
            >
              <IconContext.Provider value={{ size: "1em" }}>
                <div>
                  {deliveryType === "np_adr" && <CgRadioChecked />}
                  {deliveryType !== "np_adr" && <CgRadioCheck />}
                </div>
              </IconContext.Provider>
              <div className={css["delivery-label"]}>Новою поштою кур'єром</div>
            </div>
            <div>
              {(deliveryType === "np" || deliveryType === "np_adr") && (
                <NovaPoshta
                  data={regionRef}
                  setData={setRegionRef}
                  dataName={regionName}
                  setDataName={setRegionName}
                  type={"region"}
                  info={""}
                />
              )}
              {(deliveryType === "np" || deliveryType === "np_adr") &&
                regionRef !== "" && (
                  <NovaPoshta
                    data={cityRef}
                    setData={setCityRef}
                    dataName={cityName}
                    setDataName={setCityName}
                    type={"city"}
                    info={regionRef}
                  />
                )}
              {deliveryType === "np" && regionRef !== "" && cityRef !== "" && (
                <NovaPoshta
                  data={pointRef}
                  setData={setPointRef}
                  dataName={pointName}
                  setDataName={setPointName}
                  type={"point"}
                  info={cityRef}
                />
              )}
              {deliveryType === "np_adr" &&
                regionRef !== "" &&
                cityRef !== "" && (
                  <NovaPoshta
                    data={adressDelivery}
                    setData={setAdressDelivery}
                    dataName={adressDelivery}
                    setDataName={setAdressDelivery}
                    type={"adress"}
                    info={pointRef}
                  />
                )}
            </div>
          </div>
          {(userStatus === "guest" || deliveryType !== "office") && (
            <div>
              <div className={css["form-group"]}>
                <label>Прізвище:</label>
                <input
                  className={css["form-control"]}
                  name="surname"
                  type="text"
                  value={surname}
                  required
                  onChange={(e) => setSurname(e.target.value)}
                />
              </div>
              <div className={css["form-group"]}>
                <label>Ім'я:</label>
                <input
                  className={css["form-control"]}
                  name="firstname"
                  type="text"
                  value={firstname}
                  required
                  onChange={(e) => setFirstname(e.target.value)}
                />
              </div>
              <div className={css["form-group"]}>
                <label>Номер телефону:</label>
                <InputMask
                  className={css["form-control"]}
                  name="telephone"
                  type="text"
                  autoComplete="telephone"
                  mask="(999)9999999"
                  value={telephone}
                  required
                  onChange={(e) => setTelephone(e.target.value)}
                />
              </div>
              {userStatus === "guest" && (
                <div className={css["form-group"]}>
                  <label>Електронна пошта:</label>
                  <input
                    className={css["form-control"]}
                    name="username"
                    type="email"
                    value={email}
                    required
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              )}
            </div>
          )}
        </div>
        <div className={css["form-group"]} style={{ width: "100%" }}>
          <label>Коментар до замовлення:</label>
          <input
            className={css["form-control"]}
            name="coment"
            type="text"
            value={coment}
            required
            onChange={(e) => {
              setComent(e.target.value);
              dispatch(changeBasketComent(e.target.value));
            }}
          />
        </div>

        <div style={{ marginTop: "15px", marginLeft: "5px" }}>
          <button onClick={submit} className={css["btn"]}>
            Замовити
          </button>
          <button
            onClick={() => {
              setDeliveryShow(false);
              modalClose();
            }}
            className={css["btn"]}
          >
            Продовжити покупки
          </button>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default BasketDelivery;
