import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import css from "./Basket.module.css";
import { selectBasketItems } from "../../redux/basketSelectors";
import { changeBasketAmount, changeBasketAmountIn, changeBasketCount, changeBasketItems } from "../../redux/basketSlice";

const BasketPriceChange = ({ available, price_im, id }) => {
  const dispatch = useDispatch();
  const basket = useSelector(selectBasketItems);
  const [changePriceBtn, setChangePriceBtn] = useState(false);
  const [value, setValue] = useState(price_im);
  const inputRef = useRef(null);
  const buttonRef = useRef(null);

  const priceChange = () => {
    let newBasket = basket.map((elem) => {
      if (elem.id === id) {
        return {
          ...elem,
          price_im: value,
        };
      }
      return elem;
    });
  
    const amount = newBasket.reduce((total, item) => total + item.price_im * item.quantity, 0);
    const amountIn = newBasket.reduce((total, item) => total + item.price_in * item.quantity, 0);
    const count = newBasket.reduce((total, item) => total + item.quantity, 0);
  
    dispatch(changeBasketItems(newBasket));
    dispatch(changeBasketCount(count));
    dispatch(changeBasketAmount(amount));
    dispatch(changeBasketAmountIn(amountIn));
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      setValue(e.target.value);
      setChangePriceBtn(!changePriceBtn);
      priceChange();
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        inputRef.current &&
        buttonRef.current &&
        !inputRef.current.contains(event.target) &&
        !buttonRef.current.contains(event.target)
      ) {
        setChangePriceBtn(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      {!changePriceBtn && (
        <div
          onClick={() => {
            setChangePriceBtn(!changePriceBtn);
          }}
        >
          {available === true && <div>{price_im} ₴</div>}
          {available === false && <div style={{ color: "red" }}>немає</div>}
        </div>
      )}
      {changePriceBtn && (
        <>
          <input
            className={css["input-price"]}
            id="input"
            name="input"
            type="number"
            value={value}
            autoComplete="off"
            ref={inputRef}
            onChange={(e) => {
              setValue(e.target.value);
            }}
            onKeyDown={handleKeyDown}
          />
          <button
            ref={buttonRef}
            onClick={() => {
              setChangePriceBtn(!changePriceBtn);
              priceChange();
            }}
          >
            S
          </button>
        </>
      )}
    </>
  );
};

export default BasketPriceChange;
