// import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import { uk } from "date-fns/locale/uk";
registerLocale("uk", uk);

const MyDatePicker = ({ selectedDate, setSelectedDate }) => {
  //   const [selectedDate, setSelectedDate] = useState(new Date("2024-01-01"));

  const handleDateChange = (date) => {
    setSelectedDate(date.toISOString());
  };

  return (
    <div>
      <DatePicker
        locale="uk"
        selected={selectedDate}
        onChange={handleDateChange}
        dateFormat="dd/MM/yyyy" // Формат дати
        placeholderText="Виберіть дату" // Текст, що відображається, коли дата не вибрана
        // disabled
      />
    </div>
  );
};

export default MyDatePicker;
