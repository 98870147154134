import { Link } from "react-router-dom";
import css from "./ProductCard.module.css";
import no_photo from "../../no-photo-api.png";
import { useState } from "react";
import BuyButtonModal from "../Basket/BuyButtonModal";
import ProductFavoriteCompare from "../ProductFavoriteCompare/ProductFavoriteCompare";
import EditCardBtn from "./EditCardBtn";
import { useSelector } from "react-redux";

const ProductCard = ({
  id,
  name,
  categoryID,
  onoff,
  brief_description,
  date_modified,
  price_im,
  price_max,
  full_image,
  favorite,
  compare,
  prices,
  sale_prices,
  iconLineView,
}) => {
  const productPath = "/product_details/" + id;
  const [activeProduct, setActiveProduct] = useState(false);
  const userStatus = useSelector((state) => state.user.status);
  let fImage = full_image;
  if (full_image) {
    if (full_image.startsWith("http://") || full_image.startsWith("static/")) {
      fImage = no_photo;
    }
  }

  return (
    <div
      className={
        iconLineView === "icon"
          ? css["product-list-item"]
          : css["product-list-item-line"]
      }
      onMouseEnter={() => {
        setActiveProduct(true);
      }}
      onMouseLeave={() => {
        setActiveProduct(false);
      }}
    >
      {iconLineView === "icon" && (
        <div className={css["favorite-compare-block"]}>
          <ProductFavoriteCompare
            type={"icon"}
            id={id}
            favoriteStatus={favorite}
            compareStatus={compare}
          />
        </div>
      )}
      <div
        className={
          iconLineView === "icon"
            ? css["product-card-image"]
            : css["product-card-image-line"]
        }
      >
        {fImage ? (
          <Link to={productPath}>
            <img
              src={fImage}
              width={iconLineView === "icon" ? "180px" : "100px"}
              alt={name}
            />
          </Link>
        ) : (
          <Link to={productPath}>
            <img
              src={no_photo}
              width={iconLineView === "icon" ? "180px" : "100px"}
              alt={name}
            />
          </Link>
        )}
      </div>
      <div className={css["product-card-image-mob"]}>
        {fImage ? (
          <Link to={productPath}>
            <img
              src={fImage}
              width={iconLineView === "icon" ? "100px" : "100px"}
              alt={name}
            />
          </Link>
        ) : (
          <Link to={productPath}>
            <img
              src={no_photo}
              width={iconLineView === "icon" ? "100px" : "100px"}
              alt={name}
            />
          </Link>
        )}
      </div>
      <div
        className={
          iconLineView === "icon"
            ? css["product-card-without-image-block"]
            : css["product-card-without-image-block-line"]
        }
      >
        <div
          className={
            iconLineView === "line" ? css["product-card-name-description"] : ""
          }
        >
          <div
            className={
              iconLineView === "icon"
                ? css["product-card-name"]
                : css["product-card-name-line"]
            }
          >
            {name}
          </div>
          {iconLineView === "line" && (
            <div className={css["product-card-description"]}>
              {brief_description}
            </div>
          )}
        </div>
        <div
          className={
            iconLineView === "icon"
              ? css["product-card-footer"]
              : css["product-card-footer-line"]
          }
        >
          <div className={css["product-buy-price-favorite-block"]}>
            <div id="buy" className={css["product-buy-price-block"]}>
              {onoff && (userStatus === "staff" || userStatus === "super") && (
                <BuyButtonModal
                  id={id}
                  price_im={price_im}
                  price_max={price_max}
                  name={name}
                  full_image={fImage}
                  vendor={prices[0]["vendor"]}
                  price_in={prices[0]["price"]}
                />
              )}
              {onoff && userStatus !== "staff" && userStatus !== "super" && (
                <BuyButtonModal
                  id={id}
                  price_im={price_im}
                  price_max={price_max}
                  name={name}
                  full_image={fImage}
                />
              )}
              {!onoff && (
                <>
                  <div className={css["btn-buy-off"]}>
                    <div className={css["btn-buy-svg-off"]}>
                      <svg width="13" height="13">
                        <use xlinkHref="#svg-icon__cart" />
                      </svg>
                    </div>
                  </div>
                  <div className={css["price-na"]}>NA ₴</div>
                </>
              )}
            </div>
            {iconLineView === "line" && (
              <div className={css["favorite-compare-block-line"]}>
                <ProductFavoriteCompare
                  type={"line"}
                  id={id}
                  favoriteStatus={favorite}
                  compareStatus={compare}
                />
              </div>
            )}
          </div>
          {(userStatus === "super" || userStatus === "staff") &&
            JSON.parse(localStorage.getItem("showPrice")) && (
              <div>
                {userStatus === "super" && (
                  <div
                    className={
                      iconLineView === "icon"
                        ? css["product-prices-staff-icon"]
                        : css["product-prices-staff-line"]
                    }
                  >
                    {sale_prices.map((item) => (
                      <div key={item["code"]} className={css["product-price"]}>
                        {item["code"] + ": " + String(item["price"])}
                      </div>
                    ))}
                  </div>
                )}
                <div
                  className={
                    iconLineView === "icon"
                      ? css["product-prices-staff-icon"]
                      : css["product-prices-staff-line"]
                  }
                >
                  {prices.map((item, index) => (
                    <div key={item["vendor"]}>
                      {item["price"] !== 0 && item["blocked"] === 0 && (
                        <div
                          title={item["quantity"]}
                          id={item["vendor"]}
                          className={`${css["product-price"]} ${
                            index === 0 ? css["product-price-first"] : ""
                          }`}
                        >
                          {item["available"] === 1 && (
                            <>{item["vendor"] + ": " + String(item["price"])}</>
                          )}
                        </div>
                      )}
                    </div>
                  ))}
                  <div className={css["product-price"]}>
                    <div className={css["edit-icon"]}>
                      <EditCardBtn
                        id={id}
                        name={name}
                        categoryID={categoryID}
                        prices={prices}
                        sale_prices={sale_prices}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
        </div>
      </div>

      {activeProduct && iconLineView === "icon" && (
        <div className={css["product-card-description-icon"]}>
          <div>{brief_description}</div>
        </div>
      )}
    </div>
  );
};

export default ProductCard;
