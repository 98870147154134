import apiaxios from "../../common/apiaxios";
import { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import css from "./GroupMenu.module.css";
import ProductListPath from "../ProductsList/ProductListPath";
import nophoto from "../../nophoto.jpg";
import userStatusContext from "../../context/userStatusContext";
import { ToastContainer, toast } from "react-toastify";
import { useSelector } from "react-redux";

const GroupMenu = () => {
  const [details, setDetails] = useState([]);
  const [group, setGroup] = useState("");
  const [addImage, setAddImage] = useState(false);
  const { categoryID } = useParams();
  const { selectVendor } = useContext(userStatusContext);
  const userStatus = useSelector((state) => state.user.status);

  const [perPage] = useState(
    JSON.parse(localStorage.getItem("productsPerPage"))
      ? JSON.parse(localStorage.getItem("productsPerPage"))
      : 20
  );

  const savePath = async (e) => {
    // const path = document.getElementById("i" + e.currentTarget.id).value;
    const path = window.location.href;
    e.preventDefault();
    const data = {
      image: document.getElementById("i" + e.currentTarget.id).value,
      categoryID: e.currentTarget.id,
    };
    const res = await apiaxios.post(
      "/catalog/change_group_image/",
      data,
      { headers: { "Content-Type": "application/json" } },
      { withCredentials: true }
    );
    if (res.name === "AxiosError") {
      toast.error("Неправильний логін або пароль");
      return;
    }
    apiaxios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${res.data["access"]}`;
    window.location.href = path;
  };

  useEffect(() => {
    apiaxios
      .get("/catalog/get_group_menu/" + categoryID, {
        params: {
          vendor: selectVendor,
        },
      })
      .then((res) => {
        setDetails(res.data["list"]);
        setGroup(res.data["group"]);
        // setImgPath(res.data["image"]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [categoryID, selectVendor]);

  return (
    <>
      <div className={css["container-main"]}>
        <ProductListPath categoryID={categoryID} />
        {(userStatus === "super" || userStatus === "staff") && (
          <div
            className={css["add-image"]}
            onClick={() => {
              setAddImage(!addImage);
            }}
          >
            Add image
          </div>
        )}
        <div className={css["header-main-title-blok"]}>
          <div className={css["header-main-title"]}>{group}</div>
        </div>
        <div className={css["container"]}>
          {details &&
            details.map(
              (group) =>
                group.items.length === 0 && (
                  <div key={group.categoryID} className={css["group"]}>
                    <Link
                      to={
                        "/products_list/" +
                        group.categoryID +
                        "?page=1&perPage=" +
                        perPage
                      }
                    >
                      <div className={css["item"]}>
                        <div className={css["item-img"]}>
                          <img
                            src={group.image ? group.image : nophoto}
                            alt={group.im_name}
                            width={"100%"}
                          />
                        </div>
                        <div className={css["item-name"]}>{group.im_name}</div>
                      </div>
                    </Link>
                    {addImage && (
                      <div
                        // onSubmit={submit}
                        className={css["add-image-card"]}
                      >
                        <input
                          id={"i" + String(group.categoryID)}
                          type="text"
                          defaultValue={group.image}
                        />
                        <button id={group.categoryID} onClick={savePath}>
                          Save
                        </button>
                      </div>
                    )}
                  </div>
                )
            )}
        </div>
        {details &&
          details.map(
            (group) =>
              group.items.length !== 0 && (
                <div key={group.categoryID}>
                  <Link to={"/group_menu/" + group.categoryID}>
                    <div className={css["group-label"]}>{group.im_name}</div>
                  </Link>
                  <div className={css["container"]} key={group.categoryID}>
                    {group.items &&
                      group.items.map((item) => (
                        <div className={css["group"]} key={item.categoryID}>
                          <Link
                            to={
                              "/products_list/" +
                              item.categoryID +
                              "?page=1&perPage=" +
                              perPage
                            }
                          >
                            <div className={css["item"]}>
                              <div className={css["item-img"]}>
                                <img
                                  src={item.image ? item.image : nophoto}
                                  alt={item.im_name}
                                  width={"100%"}
                                />
                              </div>
                              <div className={css["item-name"]}>
                                {item.im_name}
                              </div>
                            </div>
                          </Link>
                          {addImage && (
                            <div
                              // onSubmit={submit}
                              className={css["add-image-card"]}
                            >
                              <input
                                id={"i" + String(item.categoryID)}
                                type="text"
                                defaultValue={item.image}
                              />
                              <button id={item.categoryID} onClick={savePath}>
                                Save
                              </button>
                            </div>
                          )}
                        </div>
                      ))}
                  </div>
                </div>
              )
          )}
        <ToastContainer />
      </div>
    </>
  );
};

export default GroupMenu;
