import no_photo from "../../no-photo-api.png";
import css from "./Basket.module.css";
import { Link, useNavigate } from "react-router-dom";
import { IconContext } from "react-icons";
import { CgCloseO, CgArrowUp, CgArrowDown } from "react-icons/cg";
import { FaRegEyeSlash, FaRegEye } from "react-icons/fa";
import { useEffect, useState } from "react";
import SelectFromList from "../control/SelectFromList/SelectFromList";
import CustomInput from "../control/CustomInput/CustomInput";
import BasketPriceChange from "./BasketPriceChange";
import { useDispatch, useSelector } from "react-redux";
import {
  changeBasketAmount,
  changeBasketAmountIn,
  changeBasketClient,
  changeBasketComent,
  changeBasketConfirm,
  changeBasketCount,
  changeBasketFirm,
  changeBasketItems,
  changeBasketManager,
  changeBasketToNull,
  changeBasketPriceScheme,
  changeBasketDelivery,
} from "../../redux/basketSlice";
import {
  selectBasketAmount,
  selectBasketAmountIn,
  selectBasketClient,
  selectBasketComent,
  selectBasketConfirm,
  selectBasketDelivery,
  selectBasketFirm,
  selectBasketItems,
  selectBasketManager,
  selectBasketOrderID,
  selectBasketPriceScheme,
} from "../../redux/basketSelectors";
import apiaxios from "../../common/apiaxios";
import SelectPriceScheme from "../ProductsList/SelectPriceScheme";
import CheckBool from "../control/CheckBool/CheckBool";
import BasketDelivery from "./BasketDelivery";

const Basket = ({ modalClose, type }) => {
  const [showPrice, setShowPrice] = useState(
    JSON.parse(localStorage.getItem("showPrice"))
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [deliveryShow, setDeliveryShow] = useState(false);
  const [successMessageShow, setSuccessMessageShow] = useState(false);
  const [newUser, setNewUser] = useState(false);
  const userStatus = useSelector((state) => state.user.status);
  const name = useSelector((state) => state.user.name);
  const surname = useSelector((state) => state.user.surname);
  const email = useSelector((state) => state.user.email);
  const telephone = useSelector((state) => state.user.telephone);
  const defaultClient = useSelector((state) => state.user.defaultClient);
  const defaultFirm = useSelector((state) => state.user.defaultFirm);
  const staffMember = useSelector((state) => state.user.staffMember);
  const basketOrderID = useSelector(selectBasketOrderID);
  const basket = useSelector(selectBasketItems);
  const basketAmount = useSelector(selectBasketAmount);
  const basketAmountIn = useSelector(selectBasketAmountIn);
  const basketClient = useSelector(selectBasketClient);
  const basketFirm = useSelector(selectBasketFirm);
  const basketManager = useSelector(selectBasketManager);
  const basketConfirm = useSelector(selectBasketConfirm);
  const basketPriceScheme = useSelector(selectBasketPriceScheme);
  const basketComent = useSelector(selectBasketComent);
  const basketDelivery = useSelector(selectBasketDelivery);

  const deleteItem = (e) => {
    const itemId = Number(e.currentTarget.id);

    const { newBasket, amount, amountIn, count } = basket.reduce(
      (acc, item) => {
        if (item.id !== itemId) {
          acc.newBasket.push(item);
          acc.amount += item.price_im * item.quantity;
          acc.amountIn += item.price_in * item.quantity;
          acc.count += item.quantity;
        }
        return acc;
      },
      { newBasket: [], amount: 0, amountIn: 0, count: 0 }
    );

    dispatch(changeBasketItems(newBasket));
    dispatch(changeBasketCount(count));
    dispatch(changeBasketAmount(amount));
    dispatch(changeBasketAmountIn(amountIn));
  };

  const quantityChange = (e) => {
    const updatedBasket = basket.map((item) => {
      if (item.id === Number(e.currentTarget.id)) {
        return {
          ...item,
          quantity: Number(e.currentTarget.value),
        };
      }
      return item;
    });

    const { newBasket, amount, amountIn, count } = updatedBasket.reduce(
      (acc, item) => {
        acc.newBasket.push(item);
        acc.amount += item.price_im * item.quantity;
        acc.amountIn += item.price_in * item.quantity;
        acc.count += item.quantity;
        return acc;
      },
      { newBasket: [], amount: 0, amountIn: 0, count: 0 }
    );

    dispatch(changeBasketItems(newBasket));
    dispatch(changeBasketCount(count));
    dispatch(changeBasketAmount(amount));
    dispatch(changeBasketAmountIn(amountIn));
  };

  const quantityIncrement = (e) => {
    const updatedBasket = basket.map((item) => {
      if (item.id === Number(e.currentTarget.id)) {
        const newQuantity =
          e.currentTarget.name === "+"
            ? item.quantity + 1
            : item.quantity > 1
            ? item.quantity - 1
            : item.quantity;

        return {
          ...item,
          quantity: newQuantity,
        };
      }
      return item;
    });

    const { newBasket, amount, amountIn, count } = updatedBasket.reduce(
      (acc, item) => {
        acc.newBasket.push(item);
        acc.amount += item.price_im * item.quantity;
        acc.amountIn += item.price_in * item.quantity;
        acc.count += item.quantity;
        return acc;
      },
      { newBasket: [], amount: 0, amountIn: 0, count: 0 }
    );

    dispatch(changeBasketItems(newBasket));
    dispatch(changeBasketCount(count));
    dispatch(changeBasketAmount(amount));
    dispatch(changeBasketAmountIn(amountIn));
  };

  const setBasketClient = (value) => {
    dispatch(changeBasketClient(value));
  };

  const setBasketFirm = (value) => {
    dispatch(changeBasketFirm(value));
  };

  const setBasketManager = (value) => {
    dispatch(changeBasketManager(value));
  };

  const setBasketComent = (value) => {
    dispatch(changeBasketComent(value));
  };

  const setBasketConfirm = (value) => {
    dispatch(changeBasketConfirm(value));
  };

  const clearBasket = () => {
    dispatch(changeBasketToNull(null));
    dispatch(changeBasketClient(defaultClient));
    dispatch(changeBasketFirm(defaultFirm));
    dispatch(changeBasketManager(staffMember));
    dispatch(
      changeBasketDelivery({
        deliveryType: "office",
        firstname: userStatus === "user" ? name : "",
        surname: userStatus === "user" ? surname : "",
        telephone: userStatus === "user" ? telephone : "",
        email: userStatus === "user" ? email : "",
        regionName: "",
        cityName: "",
        pointName: "",
        adressDelivery: "",
      })
    );
    localStorage.removeItem("basketStorage");
    localStorage.removeItem("orderAmount");
  };

  const saveOrder = async () => {
    const order = {
      username: email,
      orderID: basketOrderID,
      clientID: basketClient["id"],
      firmID: basketFirm["id"],
      managerID: basketManager["id"],
      amount: basketAmount,
      coment: basketComent,
      confirm: basketConfirm,
      priceScheme: basketPriceScheme,
      delivery: basketDelivery,
      items: basket,
    };
    await apiaxios
      .post(
        "/orders/new_order/",
        order,
        { headers: { "Content-Type": "application/json" } },
        { withCredentials: true }
      )
      .then((res) => {
        setNewUser(res.data.new_user);
        clearBasket();
      });
    if (userStatus === "staff" || userStatus === "super") {
      if (window.location.pathname === "/portal/orders_list") {
        window.location.reload(); // Примусове оновлення сторінки
      } else {
        navigate("/portal/orders_list"); // Перехід на сторінку, якщо ви ще не там
      }
      modalClose();
    } else if (userStatus === "user") {
      navigate("/profile/orders");
      modalClose();
    } else {
      setSuccessMessageShow(true);
    }
  };

  useEffect(() => {
    if (userStatus !== "guest") {
      apiaxios
        .get("/partners/get_client_by_id?id=" + basketClient["id"])
        .then((res) => {
          dispatch(changeBasketPriceScheme(res.data.price_scheme["name"]));
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [basketClient, dispatch, userStatus]);

  return (
    <div className={css["container"]}>
      {successMessageShow && (
        <div style={{ margin: "50px", textAlign: "center" }}>
          <div>Замовлення було успішно створено!</div>
          <div>
            Деталі замовлення та його статус Ви можете побачити в особистому
            кабінеті.
          </div>
          {newUser && (
            <div>
              Ви автоматично зареєстровані. Дані для входу в особистий кабінет
              надіслано Вам на пошту. Також Ви можете відновити пароль до
              кабінета{" "}
              <Link
                to={"/restore_password"}
                style={{ textDecoration: "underline" }}
                onClick={modalClose}
              >
                тут
              </Link>
              .
            </div>
          )}
          <div className={css["return-success-link"]} onClick={modalClose}>
            {userStatus === "guest" && (
              <div>
                <Link to={"/login"} style={{ color: "black" }}>
                  Увійти
                </Link>
              </div>
            )}
            {userStatus === "user" && (
              <div>
                <Link to={"/profile/orders"} style={{ color: "black" }}>
                  Особистий кабінет
                </Link>
              </div>
            )}
            <div>
              <Link to={"/"} style={{ color: "black" }}>
                Повернутись до магазину
              </Link>
            </div>
          </div>
        </div>
      )}
      {!successMessageShow && (
        <>
          <div className={css["header-container"]}>
            {(userStatus === "staff" || userStatus === "super") && (
              <div className={css["header-title"]}>
                {basketOrderID
                  ? "Замовлення №" + basketOrderID
                  : "Нове замовлення"}
              </div>
            )}
            {userStatus !== "staff" &&
              userStatus !== "super" &&
              !deliveryShow && (
                <div className={css["header-title"]}>Товари у кошику</div>
              )}
            {userStatus !== "staff" &&
              userStatus !== "super" &&
              deliveryShow && (
                <div className={css["header-title"]}>Оформлення замовлення</div>
              )}
            {type !== "order" && (
              <div style={{ display: "flex" }}>
                {(userStatus === "super" || userStatus === "staff") && (
                  <div
                    style={{ marginRight: "20px" }}
                    onClick={() => {
                      setShowPrice(!showPrice);
                      localStorage.setItem(
                        "showPrice",
                        JSON.stringify(!showPrice)
                      );
                    }}
                  >
                    <IconContext.Provider value={{ size: "1.5em" }}>
                      {showPrice ? <FaRegEye /> : <FaRegEyeSlash />}
                    </IconContext.Provider>
                  </div>
                )}
                <div className={css["header-close-btn"]} onClick={modalClose}>
                  <svg width="20" height="20">
                    <use xlinkHref="#svg-icon__close" />
                  </svg>
                </div>
              </div>
            )}
          </div>
          {type !== "order" &&
            (userStatus === "staff" || userStatus === "super") && (
              <div>
                <div
                  style={{
                    display: "flex",
                    marginBottom: "5px",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <SelectFromList
                      requestPath={"/partners/get_clients/"}
                      label={"Клієнт:"}
                      value={basketClient}
                      setValue={setBasketClient}
                      width="250px"
                      clear={false}
                    />
                  </div>
                  <div style={{ marginRight: "10px" }}>
                    <SelectPriceScheme />
                  </div>
                  <div style={{ marginRight: "10px" }}>
                    <CheckBool
                      label={"узгоджена ціна"}
                      value={basketConfirm}
                      setValue={setBasketConfirm}
                    />
                  </div>
                  <div>
                    <SelectFromList
                      requestPath={"/firms/get_firms/"}
                      label={"Фірма:"}
                      value={basketFirm}
                      setValue={setBasketFirm}
                      width="150px"
                      clear={false}
                    />
                  </div>
                  <div>
                    <SelectFromList
                      requestPath={"/staff/get_stafflist/"}
                      label={"Відповідальний:"}
                      value={basketManager}
                      setValue={setBasketManager}
                      width="200px"
                      clear={false}
                    />
                  </div>
                  <div
                    onClick={() => {
                      setDeliveryShow(!deliveryShow);
                    }}
                  >
                    Доставка
                  </div>
                </div>
                <div style={{ marginBottom: "10px" }}>
                  <CustomInput
                    value={basketComent}
                    setValue={setBasketComent}
                    label={"Коментар:"}
                    width="100%"
                  />
                </div>
              </div>
            )}
          {(userStatus === "staff" || userStatus === "super") &&
            basket.length !== 0 && (
              <table>
                <thead>
                  <tr>
                    <th>Фото</th>
                    <th>Назва</th>
                    {showPrice && (
                      <>
                        <th>Ven</th>
                        <th>In</th>
                      </>
                    )}
                    <th>Ціна</th>
                    <th>К-ть</th>
                    {showPrice && <th>InSum</th>}
                    <th>Сума</th>
                    <th>X</th>
                  </tr>
                </thead>
                <tbody>
                  {basket.map(
                    (item) =>
                      ((type === "order" && item.available === true) ||
                        type !== "order") && (
                        <tr key={item.id}>
                          <td>
                            <Link
                              className={css["image"]}
                              to={"/product_details/" + item.id}
                              onClick={modalClose}
                            >
                              {item.full_image ? (
                                <img
                                  src={item.full_image}
                                  width="30px"
                                  alt={item.name}
                                />
                              ) : (
                                <img
                                  src={no_photo}
                                  width="30px"
                                  alt={item.name}
                                />
                              )}
                            </Link>
                          </td>
                          <td>
                            <div style={{ textAlign: "left" }}>
                              <Link
                                style={
                                  item.available === true
                                    ? { color: "black" }
                                    : { color: "red" }
                                }
                                to={"/product_details/" + item.id}
                                onClick={modalClose}
                              >
                                {item.name}
                              </Link>
                            </div>
                          </td>
                          {showPrice && (
                            <>
                              <td>
                                {item.available === true && (
                                  <div>{item.vendor}</div>
                                )}
                                {item.available === false && (
                                  <div style={{ color: "red" }}>немає</div>
                                )}
                              </td>
                              <td>
                                {item.available === true && (
                                  <div>{item.price_in} ₴</div>
                                )}
                                {item.available === false && (
                                  <div style={{ color: "red" }}>немає</div>
                                )}
                              </td>
                            </>
                          )}
                          <td>
                            <BasketPriceChange
                              available={item.available}
                              price_im={item.price_im}
                              id={item.id}
                            />
                          </td>
                          <td>
                            <div className={css["quantity-block"]}>
                              <input
                                className={css["quantity-input-order"]}
                                id={item.id}
                                value={item.quantity}
                                onChange={quantityChange}
                              />
                            </div>
                          </td>
                          {showPrice && (
                            <td>
                              {item.available === true && (
                                <div>{item.price_in * item.quantity} ₴</div>
                              )}
                              {item.available === false && (
                                <div style={{ color: "red" }}>немає</div>
                              )}
                            </td>
                          )}
                          <td>
                            {item.available === true && (
                              <div>{item.price_im * item.quantity} ₴</div>
                            )}
                            {item.available === false && (
                              <div style={{ color: "red" }}>немає</div>
                            )}
                          </td>
                          <td>
                            <div
                              className={css["delete-x-order"]}
                              id={item.id}
                              onClick={deleteItem}
                            >
                              <IconContext.Provider value={{ size: "1.5em" }}>
                                <div>
                                  <CgCloseO />
                                </div>
                              </IconContext.Provider>
                            </div>
                          </td>
                        </tr>
                      )
                  )}
                </tbody>
                <tfoot>
                  <tr>
                    <th></th>
                    <th></th>
                    {showPrice && (
                      <>
                        <th></th>
                        <th></th>
                      </>
                    )}
                    <th></th>
                    <th></th>
                    {showPrice && <th>{basketAmountIn} ₴</th>}
                    <th>{basketAmount} ₴</th>
                    <th></th>
                  </tr>
                </tfoot>
              </table>
            )}

          {userStatus !== "staff" &&
            userStatus !== "super" &&
            basket.length !== 0 && (
              <>
                {basket.map(
                  (item) =>
                    ((type === "order" && item.available === true) ||
                      type !== "order") && (
                      <div key={item.id} className={css["row"]}>
                        <Link
                          className={css["image"]}
                          to={"/product_details/" + item.id}
                          onClick={modalClose}
                        >
                          {item.full_image ? (
                            <img
                              src={item.full_image}
                              width="50px"
                              alt={item.name}
                            />
                          ) : (
                            <img src={no_photo} width="50px" alt={item.name} />
                          )}
                        </Link>
                        <div className={css["row-without-img-and-x-block"]}>
                          <Link
                            className={
                              item.available === true
                                ? css["name"]
                                : css["name-not-available"]
                            }
                            to={"/product_details/" + item.id}
                            onClick={modalClose}
                          >
                            {item.name}
                          </Link>
                          <div className={css["row-amount-quantity-block"]}>
                            {item.available === true && (
                              <div className={css["amount-row"]}>
                                {item.price_im} ₴
                              </div>
                            )}
                            {item.available === false && (
                              <div className={css["amount-row-not-available"]}>
                                немає
                              </div>
                            )}
                            <div className={css["quantity-block"]}>
                              <button
                                className={css["quantity-button"]}
                                id={item.id}
                                name="-"
                                onClick={quantityIncrement}
                              >
                                <IconContext.Provider value={{ size: "1em" }}>
                                  <div>
                                    <CgArrowDown />
                                  </div>
                                </IconContext.Provider>
                              </button>
                              <input
                                className={css["quantity-input"]}
                                id={item.id}
                                value={item.quantity}
                                onChange={quantityChange}
                              />
                              <button
                                className={css["quantity-button"]}
                                id={item.id}
                                name="+"
                                onClick={quantityIncrement}
                              >
                                <IconContext.Provider value={{ size: "1em" }}>
                                  <div>
                                    <CgArrowUp />
                                  </div>
                                </IconContext.Provider>
                              </button>
                            </div>
                            {item.available === true && (
                              <div className={css["amount-row"]}>
                                {item.price_im * item.quantity} ₴
                              </div>
                            )}
                            {item.available === false && (
                              <div className={css["amount-row-not-available"]}>
                                немає
                              </div>
                            )}
                          </div>
                        </div>
                        <div
                          className={css["delete-x"]}
                          id={item.id}
                          onClick={deleteItem}
                        >
                          <IconContext.Provider value={{ size: "1.5em" }}>
                            <div>
                              <CgCloseO />
                            </div>
                          </IconContext.Provider>
                        </div>
                      </div>
                    )
                )}
              </>
            )}
          {basket.length === 0 && (
            <div className={css["none-items"]}>
              Товарів у кошику ще немає...
            </div>
          )}
          <div className={css["fotter"]}>
            <div>
              {type !== "order" &&
                (userStatus === "staff" || userStatus === "super") && (
                  <button
                    className={css["btn"]}
                    onClick={() => {
                      saveOrder();
                      if (basket.length !== 0)
                        navigate("/portal/orders_list", { replace: true });
                      modalClose();
                    }}
                  >
                    Зберегти
                  </button>
                )}
              {!deliveryShow && (
                <>
                  {type !== "order" &&
                    userStatus !== "staff" &&
                    userStatus !== "super" && (
                      <button
                        className={css["btn"]}
                        onClick={() => {
                          setDeliveryShow(true);
                          // if (basket.length !== 0)
                          //   navigate("/order", { replace: true });
                          // modalClose();
                        }}
                      >
                        Оформити
                      </button>
                    )}
                  <button className={css["btn"]} onClick={modalClose}>
                    Продовжити
                  </button>
                  <button className={css["btn"]} onClick={clearBasket}>
                    Очистити
                  </button>
                </>
              )}
            </div>

            {type !== "order" &&
              userStatus !== "staff" &&
              userStatus !== "super" && (
                <div className={css["amount"]}>
                  <span>{basketAmount} ₴</span>
                </div>
              )}
          </div>
          {deliveryShow && (
            <div>
              <BasketDelivery
                saveOrder={saveOrder}
                setDeliveryShow={setDeliveryShow}
                modalClose={modalClose}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Basket;
