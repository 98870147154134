import axios from "axios";
import { jwtDecode } from "jwt-decode";

// Встановлюємо налаштування за замовчуванням для нашого екземпляру axios
const apiaxios = axios.create({
  baseURL: process.env.REACT_APP_DJANGO_API_URL,
});

// Додаємо за замовчуванням для токен
if (localStorage.getItem("access_token")) {
  apiaxios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${localStorage.getItem("access_token")}`;
}

// Функція оновлення токена, або видалення недійсних токенів
const refresh = async () => {
  if (localStorage.getItem("refresh_token")) {
    await axios
      .post(
        process.env.REACT_APP_DJANGO_API_URL + "/token/refresh/",
        { refresh: localStorage.getItem("refresh_token") },
        { headers: { "Content-Type": "application/json" } },
        { withCredentials: true }
      )
      .then((response) => {
        apiaxios.defaults.headers.common["Authorization"] = `Bearer
        ${response.data["access"]}`;
        localStorage.setItem("access_token", response.data.access);
        localStorage.setItem("refresh_token", response.data.refresh);
        window.location.reload();
      })
      .catch(() => {
        apiaxios.defaults.headers.common["Authorization"] = null;
        localStorage.removeItem("access_token");
        localStorage.removeItem("refresh_token");
        window.location.href = "/";
        window.location.reload();
      });
  } else {
    apiaxios.defaults.headers.common["Authorization"] = null;
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    window.location.href = "/";
    window.location.reload();
  }
};

// Поновлення access_token
const accessToken = localStorage.getItem("access_token");
if (accessToken) {
  try {
    const decodedToken = jwtDecode(accessToken);
    // Перевірка терміну дії токену
    if (decodedToken.exp < Date.now() / 1000) {
      // Токен застарів - оновлення
      // alert("Токен застарів, потрібно оновити");
      refresh();
      window.location.reload();
    }
  } catch (error) {
    // Помилка розкодування токену - оновлення
    // alert("Invalid token format");
    refresh();
    window.location.reload();
  }
}

export default apiaxios;
