import { useContext, useState } from "react";
import css from "./ProductDetails.module.css";
import modalStatusContext from "../../context/modalStatusContext";
import Modal from "../../common/Modal";

const ProductDetailsImages = ({ path_list, name, bigImage, setBigImage }) => {
  const [active, setActive] = useState(0);
  const { modalIsOpen, openModal, closeModal, contentModal } =
    useContext(modalStatusContext);

  function clickSmallImage(e) {
    setActive(Number(e.currentTarget.id));
    setBigImage(path_list[e.currentTarget.id].path_big);
  }

  function clickArrow(e) {
    // console.log(active);
    if (active > 0 && e.currentTarget.id === "-") {
      setActive(active - 1);
      setBigImage(path_list[active - 1].path_big);
    }
    if (active === 0 && e.currentTarget.id === "-") {
      setActive(path_list.length - 1);
      setBigImage(path_list[path_list.length - 1].path_big);
    }
    if (active < path_list.length - 1 && e.currentTarget.id === "+") {
      setActive(active + 1);
      setBigImage(path_list[active + 1].path_big);
    }
    if (active === path_list.length - 1 && e.currentTarget.id === "+") {
      setActive(0);
      setBigImage(path_list[0].path_big);
    }
  }

  return (
    <>
      <div className={css["img"]} onClick={openModal} id="img">
        {bigImage ? (
          <img src={bigImage} alt={name} width={"100%"} />
        ) : (
          <img
            src={
              "https://opt.brain.com.ua/static/common/images/no-photo-api.png"
            }
            alt={name}
            width={"100%"}
          />
        )}
      </div>
      {bigImage && (
        <div className={css["img-small-block"]}>
          {path_list.map((item) => (
            <div className={css["img-small"]} key={item.id}>
              <img
                id={item.id}
                width={"50px"}
                src={item.path}
                alt={name}
                onClick={clickSmallImage}
              />
            </div>
          ))}
        </div>
      )}
      {bigImage && modalIsOpen && contentModal === "img" && (
        <Modal>
          <div className={css["modal-block"]}>
            <div className={css["modal-big-image-block"]}>
              <div
                className={css["modal-arrow-block"]}
                onClick={clickArrow}
                id="-"
              >
                <svg width="20" height="20">
                  <use xlinkHref="#svg-icon__angle-left" />
                </svg>
              </div>
              <div className={css["modal-big-inside-block"]}>
                <div className={css["modal-close-block"]}>
                  <div className={css["modal-close-btn"]} onClick={closeModal}>
                    <svg width="20" height="20">
                      <use xlinkHref="#svg-icon__close" />
                    </svg>
                  </div>
                </div>
                <img
                  className={css["modal-big-image"]}
                  src={bigImage}
                  alt={name}
                />
              </div>

              <div
                className={css["modal-arrow-block"]}
                onClick={clickArrow}
                id="+"
              >
                <svg width="20" height="20">
                  <use xlinkHref="#svg-icon__angle-right" />
                </svg>
              </div>
            </div>
            <div className={css["modal-small-image-block"]}>
              {path_list.map((item) => (
                <img
                  key={item.id}
                  id={item.id}
                  width={"50px"}
                  className={
                    item.id === active
                      ? css["modal-small-image-active"]
                      : css["modal-small-image"]
                  }
                  src={item.path}
                  alt={name}
                  onClick={clickSmallImage}
                />
              ))}
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default ProductDetailsImages;
