import { useEffect, useState } from "react";
import apiaxios from "../../common/apiaxios";
import css from "./Dictionaries.module.css";
import ClientCard from "./ClientCard";
import UserCard from "./UserCard";
import StaffCard from "./StaffCard";
import FirmCard from "./FirmCard";
import AccountCard from "./AccountCard";

const DictionaryList = ({ pageTitle, path, type }) => {
  const [dictList, setDictList] = useState([]);
  const [dictHeaderList, setHeaderDictList] = useState([]);
  const [editOpen, setEditOpen] = useState(false);
  // const [title, setTitle] = useState("");
  const [selectedID, setSelectedID] = useState();
  // const [selectedCategory, setSelectedCategory] = useState();

  useEffect(() => {
    apiaxios
      .get(path)
      .then((res) => {
        setHeaderDictList(res.data["header"]);
        setDictList(res.data["items"]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [path]);

  const modalOpen = (e) => {
    setEditOpen(true);
    // setTitle(
    //   "Обераємо відповідність для категорії: " +
    //     e.currentTarget.getAttribute("data-name")
    // );
    setSelectedID(e.currentTarget.id);
  };

  const modalClose = () => {
    setEditOpen(false);
    // setTitle("");
    setSelectedID("");
  };

  return (
    <div className={css["dictionary-container"]}>
      <div className={css["dictionary-header"]}>
        <div className={css["dictionary-title"]}>{pageTitle}</div>
      </div>
      <div>
        <table>
          <thead>
            <tr>
              {dictHeaderList.map((item) => (
                <th key={item}>{item}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {dictList.length !== 0 &&
              dictList.map((row) => (
                <tr
                  key={row[0]}
                  id={row[0]}
                  className={css["dictionary-table-row"]}
                  onClick={modalOpen}
                >
                  {row.map((item, index) => (
                    <td key={`${item}-${index}`}>{item}</td>
                  ))}
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      {editOpen && type === "client" && (
        <ClientCard id={selectedID} modalClose={modalClose} width="480px" />
      )}
      {editOpen && type === "user" && (
        <UserCard id={selectedID} modalClose={modalClose} width="480px" />
      )}
      {editOpen && type === "staff" && (
        <StaffCard id={selectedID} modalClose={modalClose} width="480px" />
      )}
      {editOpen && type === "firms" && (
        <FirmCard id={selectedID} modalClose={modalClose} width="480px" />
      )}
      {editOpen && type === "accounts" && (
        <AccountCard id={selectedID} modalClose={modalClose} width="480px" />
      )}
    </div>
  );
};

export default DictionaryList;
