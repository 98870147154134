import { useEffect, useState } from "react";
import { useContext } from "react";
import userStatusContext from "../../context/userStatusContext";
import apiaxios from "../../common/apiaxios";

const SelectVendor = () => {
  const { selectVendor, setSelectVendor, vendorBest, setVendorBest } =
    useContext(userStatusContext);
  const [vendorList, setVendorList] = useState([]);

  useEffect(() => {
    apiaxios
      .get("/catalog/get_vendors/")
      .then((res) => {
        setVendorList([{ vendor: "ALL" }, ...res.data]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <select
        id="select"
        name="select"
        value={selectVendor}
        onChange={(e) => {
          setSelectVendor(e.target.value);
        }}
      >
        {vendorList.map((item) => (
          <option key={item["vendor"]} value={item["vendor"]}>
            {item["vendor"]}
          </option>
        ))}
      </select>
      <input
        type="checkbox"
        name="selectVendors"
        value={vendorBest}
        checked={vendorBest}
        onChange={() => {
          setVendorBest(!vendorBest);
        }}
        style={{ marginLeft: "5px" }}
        title="best"
      />
    </>
  );
};

export default SelectVendor;
