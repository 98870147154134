import apiaxios from "../../common/apiaxios";
import css from "../ProductsList/ProductsList.module.css";
import css2 from "./ProductFavoriteCompare.module.css";
import ProductCard from "../ProductCard/ProductCard";
import { IconContext } from "react-icons";
import { FaTrashAlt } from "react-icons/fa";
import { Link } from "react-router-dom";

const ProductCompareList = ({ elem }) => {
  const deleteList = () => {
    apiaxios.post("/users/delete_compare_list?id=" + elem.listID).then(() => {
      window.location.reload();
    });
  };
  const perPage = JSON.parse(localStorage.getItem("productsPerPage"))
    ? JSON.parse(localStorage.getItem("productsPerPage"))
    : 20;
  let prod_ids = "";
  for (const item of elem.list) {
    prod_ids += String(item.id) + "|";
  }

  return (
    <>
      <div className={css2["favorite-list-header-block"]}>
        <div className={css2["favorite-list-header-title-block"]}>
          <div className={css2["favorite-list-header-title"]}>
            <Link
              to={
                "/products_list/" +
                elem.categoryID +
                "?page=1&perPage=" +
                perPage
              }
              className={css2["favorite-list-header-title-link"]}
            >
              {elem.categoryName}
            </Link>
          </div>
          <div className={css["header-product-list-count"]}>
            {elem.listCount}
          </div>
          <div>
            <Link
              to={"/compare?products=" + prod_ids.slice(0, -1)}
              className={css2["header-product-list-compare"]}
            >
              Порівняти
            </Link>
          </div>
        </div>
        <div className={css2["favorite-list-header-icon-block"]}>
          <div
            className={css2["favorite-list-header-icon-item"]}
            onClick={deleteList}
            title="Видалити список"
          >
            <IconContext.Provider value={{ size: "1em" }}>
              <FaTrashAlt />
            </IconContext.Provider>
          </div>
        </div>
      </div>
      {elem.list.length !== 0 && (
        <ul className={css["product-list-pag"]}>
          {elem.list.map((item) => (
            <li key={item.id} className={css["product-list-wrap"]}>
              <ProductCard
                id={item.id}
                name={item.name}
                onoff={item.onoff}
                brief_description={item.brief_description}
                date_modified={item.date_modified}
                price_im={item.user_price}
                price_max={item.price_im}
                full_image={item.full_image}
                favorite={item.favorite}
                compare={item.compare}
                prices={item.prices}
                sale_prices={item.sale_prices}
                iconLineView={"icon"}
              />
            </li>
          ))}
        </ul>
      )}
      <div className={css2["favorite-list-header-block"]}></div>
    </>
  );
};

export default ProductCompareList;
