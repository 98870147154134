import React from "react";

const Sprite = () => (
  <svg xmlns="http://www.w3.org/2000/svg">
    <symbol id="svg-icon__alarm-clock">
      <path d="M18.5 1.69C9.77 1.69 2.69 8.769 2.69 17.5c0 3.78 1.327 7.25 3.54 9.97l-4.22 4.22 1.3 1.3 4.162-4.16A15.758 15.758 0 0 0 18.5 33.31c4.289 0 8.179-1.708 11.028-4.482l4.162 4.161 1.3-1.3-4.22-4.219a15.743 15.743 0 0 0 3.54-9.97c0-8.731-7.08-15.81-15.81-15.81zm0 29.308C11.04 30.998 5 24.954 5 17.5c0-7.455 6.04-13.498 13.5-13.498S32 10.045 32 17.5c0 7.454-6.04 13.498-13.5 13.498zM.01 6.689l1.3 1.3 6.68-6.678-1.3-1.3zM30.31.011l-1.3 1.3 6.68 6.678 1.3-1.3zM19 17V8h-2v11h10v-2z" />
    </symbol>
    <symbol id="svg-icon__angle-down" viewBox="0 0 32 32">
      <path d="M31.68 8.593L30.077 6.99c-.214-.214-.46-.321-.738-.321s-.523.107-.737.321L16 19.591 3.399 6.99c-.214-.214-.46-.321-.738-.321s-.524.107-.738.321L.32 8.593c-.214.214-.321.46-.321.738s.107.524.321.737L15.262 25.01c.214.214.46.321.738.321s.523-.107.737-.321L31.68 10.068c.214-.214.32-.46.32-.737s-.106-.524-.32-.738z" />
    </symbol>
    <symbol id="svg-icon__angle-left" viewBox="0 0 32 32">
      <path d="M12.408 16L25.009 3.399c.214-.214.321-.46.321-.737s-.107-.524-.321-.737L23.406.322c-.214-.214-.46-.321-.737-.321s-.524.107-.738.321L6.989 15.264c-.214.214-.32.46-.32.738s.107.524.32.737L21.93 31.681c.214.214.46.321.738.321s.523-.107.737-.321l1.603-1.602c.214-.214.32-.46.32-.738s-.106-.523-.32-.737L12.407 16.002z" />
    </symbol>
    <symbol id="svg-icon__angle-right" viewBox="0 0 32 32">
      <path d="M25.011 15.262L10.069.32c-.214-.214-.46-.321-.738-.321s-.524.107-.738.321L6.99 1.923c-.214.214-.321.459-.321.738s.107.524.321.737l12.601 12.601L6.99 28.6c-.214.214-.321.46-.321.737s.107.524.321.738l1.603 1.603c.214.214.46.321.738.321s.524-.107.738-.321L25.01 16.736c.214-.214.321-.46.321-.738s-.106-.524-.32-.738z" />
    </symbol>
    <symbol id="svg-icon__bars" viewBox="0 0 14 12">
      <path d="M0 0h14v2H0V0zm0 5h14v2H0V5zm0 5h14v2H0v-2z" />
    </symbol>
    <symbol id="svg-icon__caret-down" viewBox="0 0 32 32">
      <path d="M31.406 7.594A1.92 1.92 0 0 0 30 7H2c-.542 0-1.011.198-1.406.594S0 8.459 0 9.001s.198 1.01.594 1.406l14 14c.396.396.865.594 1.406.594s1.011-.198 1.406-.594l14-14c.395-.396.594-.864.594-1.406s-.199-1.01-.594-1.406z" />
    </symbol>
    <symbol id="svg-icon__caret-right" viewBox="0 0 32 32">
      <path d="M24.406 14.594l-14-14C10.01.198 9.542 0 9 0S7.989.198 7.593.594A1.92 1.92 0 0 0 6.999 2v28c0 .542.198 1.01.594 1.406S8.458 32 9 32a1.92 1.92 0 0 0 1.406-.594l14-14c.396-.395.594-.864.594-1.406s-.198-1.01-.594-1.406z" />
    </symbol>
    <symbol id="svg-icon__cart" viewBox="0 0 31 28">
      <path d="M10 22a3 3 0 1 0 3 3 3 3 0 0 0-3-3zm0 4a1 1 0 1 1 1-1 1 1 0 0 1-1 1zm15-4a3 3 0 1 0 3 3 3 3 0 0 0-3-3zm0 4a1 1 0 1 1 1-1 1 1 0 0 1-1 1zm-5-3h-5a1 1 0 0 0 0 2h5a1 1 0 0 0 0-2zm9-16H7.07L5 1c-.28-.78-.46-1-1-1H1a1 1 0 0 0 0 2h2l6 16v2a1.06 1.06 0 0 0 1.07 1 .92.92 0 0 0 .91-1h15c1.1 0 1.55-.73 2-2l3-10C31 6.89 30.11 7 29 7zm-3 11H11L8 9h21z" />
    </symbol>
    <symbol id="svg-icon__clock" viewBox="0 0 16 16">
      <path d="M8 16c-4.4 0-8-3.6-8-8s3.6-8 8-8 8 3.6 8 8-3.6 8-8 8zM8 2C4.7 2 2 4.7 2 8s2.7 6 6 6 6-2.7 6-6-2.7-6-6-6zM7 9V4h2v3h2v2H7z" />
    </symbol>
    <symbol id="svg-icon__close" viewBox="0 0 26 26">
      <path d="M.99 1.704L1.7.99l23.31 23.305-.71.715zM24.3.99l.71.714L1.7 25.01l-.71-.715z" />
    </symbol>
    <symbol id="svg-icon__credit-card" viewBox="0 0 32 32">
      <path d="M31.216 3.983a2.568 2.568 0 0 0-1.883-.783H2.666c-.733 0-1.361.261-1.883.783S0 5.133 0 5.866v20.266c0 .734.261 1.361.783 1.883s1.15.783 1.883.783h26.667c.733 0 1.361-.261 1.883-.783s.784-1.15.784-1.883V5.866c0-.733-.261-1.361-.784-1.883zm-1.35 22.15c0 .145-.053.269-.158.375s-.231.158-.375.158H2.666c-.144 0-.269-.053-.375-.158s-.158-.231-.158-.375V16h27.733v10.133zm0-16.533H2.133V5.867c0-.144.053-.269.158-.375s.231-.158.375-.158h26.667c.144 0 .269.053.375.158s.158.231.158.375V9.6z" />
      <path d="M4.267 22.4h4.267v2.134H4.267V22.4zm6.4 0h6.4v2.134h-6.4V22.4z" />
    </symbol>
    <symbol id="svg-icon__delivery" viewBox="0 0 30 19">
      <path d="M27-.01H13c-1.86 0-2.87.82-2.87 3l.87.21v12.44c0 1.11 1.81 1.35 3 1.35h9a2 2 0 0 1-2-2h-9v-13h16v13h-3a2 2 0 0 1-2 2h4c2.21 0 3-.79 3-3v-11c0-2.2-.79-3-3-3z" />
      <path d="M12 9.99h16v1H12v-1zm11 2a3 3 0 0 0-3 3 2.07 2.07 0 0 0 0 .5 2.93 2.93 0 0 0 .72 1.5 3 3 0 0 0 2.23 1 2.94 2.94 0 0 0 2.23-1 2.78 2.78 0 0 0 .72-1.5 2.07 2.07 0 0 0 0-.5 3 3 0 0 0-2.9-3zm-2 3a2 2 0 0 1 1.77-2 1.33 1.33 0 0 1 .46 0 2 2 0 1 1-2.23 2z" />
      <path d="M25.95 15.5a2.15 2.15 0 0 0-.12-.5 3 3 0 0 0-2.6-2 2 2 0 1 1-.46 0 3 3 0 0 0-2.59 2 1.76 1.76 0 0 0-.13.5 2.07 2.07 0 0 0 0 .5 2.77 2.77 0 0 0 .18 1 3 3 0 0 0 5.82-1 2.07 2.07 0 0 0-.1-.5zM6 4.99h5.78v-2H5S.19 8.89 0 9.6v4.39c.52 2.18 4 3 4 3h3a2 2 0 0 1-2-2H2v-5zm8.25 12l-3.25-2H9a2 2 0 0 1-2 2h7.25z" />
      <path d="M10 5.99H7l-3 4h6v-4zm-3 6a3 3 0 0 0-3 3 2.07 2.07 0 0 0 0 .5 2.93 2.93 0 0 0 .72 1.5 3 3 0 0 0 2.23 1 2.94 2.94 0 0 0 2.23-1 2.78 2.78 0 0 0 .72-1.5 2.07 2.07 0 0 0 0-.5 3 3 0 0 0-2.9-3zm-2 3a2 2 0 0 1 1.77-2 1.33 1.33 0 0 1 .46 0 2 2 0 1 1-2.23 2z" />
      <path d="M9.95 15.5a2.15 2.15 0 0 0-.12-.5 3 3 0 0 0-2.6-2 2 2 0 1 1-.46 0 3 3 0 0 0-2.59 2 1.76 1.76 0 0 0-.13.5 2.07 2.07 0 0 0 0 .5 2.77 2.77 0 0 0 .18 1 3 3 0 0 0 5.82-1 2.07 2.07 0 0 0-.1-.5z" />
    </symbol>
    <symbol id="svg-icon__download" viewBox="0 0 32 32">
      <path d="M31.462 21.462a1.78 1.78 0 0 0-1.307-.538h-8.923l-2.615 2.615c-.744.718-1.615 1.077-2.615 1.077s-1.872-.359-2.615-1.077l-2.596-2.615H1.849c-.513 0-.949.18-1.308.538s-.538.795-.538 1.308v6.154c0 .513.179.949.538 1.308s.795.538 1.308.538h28.308c.513 0 .949-.179 1.307-.538s.538-.795.538-1.308V22.77c0-.513-.179-.949-.539-1.308zm-7.212 6.481c-.244.244-.532.365-.866.365s-.622-.122-.865-.365c-.243-.244-.365-.532-.365-.865s.122-.622.365-.865c.244-.244.532-.366.865-.366s.622.122.866.366c.243.243.365.532.365.865s-.122.622-.365.865zm4.923 0c-.243.244-.532.365-.865.365s-.622-.122-.865-.365c-.243-.244-.365-.532-.365-.865s.122-.622.365-.865c.244-.244.532-.366.865-.366s.622.122.865.366c.244.243.366.532.366.865s-.122.622-.366.865z" />
      <path d="M15.135 21.789c.231.243.519.365.865.365s.634-.122.865-.365l8.616-8.616c.397-.372.487-.821.269-1.346-.218-.5-.596-.75-1.135-.75h-4.923V2.462c0-.333-.122-.622-.365-.865s-.532-.365-.865-.365h-4.924c-.333 0-.622.122-.865.365s-.365.532-.365.865v8.616H7.385c-.539 0-.917.25-1.135.75-.218.525-.128.974.269 1.346l8.616 8.615z" />
    </symbol>
    <symbol id="svg-icon__facebook-logo" viewBox="0 0 96.124 96.123">
      <path d="M72.089.02L59.624 0C45.62 0 36.57 9.285 36.57 23.656v10.907H24.037a1.96 1.96 0 0 0-1.96 1.961v15.803a1.96 1.96 0 0 0 1.96 1.96H36.57v39.876a1.96 1.96 0 0 0 1.96 1.96h16.352a1.96 1.96 0 0 0 1.96-1.96V54.287h14.654a1.96 1.96 0 0 0 1.96-1.96l.006-15.803a1.963 1.963 0 0 0-1.961-1.961H56.842v-9.246c0-4.444 1.059-6.7 6.848-6.7l8.397-.003a1.96 1.96 0 0 0 1.959-1.96V1.98A1.96 1.96 0 0 0 72.089.02z" />
    </symbol>
    <symbol id="svg-icon__facebook" viewBox="0 0 56.693 56.693">
      <path d="M40.43 21.739h-7.645v-5.014c0-1.883 1.248-2.322 2.127-2.322h5.395V6.125l-7.43-.029c-8.248 0-10.125 6.174-10.125 10.125v5.518h-4.77v8.53h4.77v24.137h10.033V30.269h6.77l.875-8.53z" />
    </symbol>
    <symbol id="svg-icon__four-squares" viewBox="0 0 13 13">
      <path d="M8 7h4c.55 0 1 .448 1 1v4c0 .552-.45 1-1 1H8c-.55 0-1-.448-1-1V8c0-.552.45-1 1-1zM1 7h4c.55 0 1 .448 1 1v4c0 .552-.45 1-1 1H1c-.55 0-1-.448-1-1V8c0-.552.45-1 1-1zm0-7h4c.55 0 1 .448 1 1v4c0 .552-.45 1-1 1H1c-.55 0-1-.448-1-1V1c0-.552.45-1 1-1zm7 0h4c.55 0 1 .448 1 1v4c0 .552-.45 1-1 1H8c-.55 0-1-.448-1-1V1c0-.552.45-1 1-1z" />
    </symbol>
    <symbol id="svg-icon__google-plus-logo" viewBox="0 0 96.828 96.827">
      <path d="M62.617 0H39.525c-10.29 0-17.413 2.256-23.824 7.552-5.042 4.35-8.051 10.672-8.051 16.912 0 9.614 7.33 19.831 20.913 19.831 1.306 0 2.752-.134 4.028-.253l-.188.457c-.546 1.308-1.063 2.542-1.063 4.468 0 3.75 1.809 6.063 3.558 8.298l.22.283-.391.027c-5.609.384-16.049 1.1-23.675 5.787-9.007 5.355-9.707 13.145-9.707 15.404 0 8.988 8.376 18.06 27.09 18.06 21.76 0 33.146-12.005 33.146-23.863.002-8.771-5.141-13.101-10.6-17.698l-4.605-3.582c-1.423-1.179-3.195-2.646-3.195-5.364 0-2.672 1.772-4.436 3.336-5.992l.163-.165c4.973-3.917 10.609-8.358 10.609-17.964 0-9.658-6.035-14.649-8.937-17.048h7.663a.488.488 0 0 0 .266-.077l6.601-4.15A.5.5 0 0 0 62.617 0zM34.614 91.535c-13.264 0-22.176-6.195-22.176-15.416 0-6.021 3.645-10.396 10.824-12.997 5.749-1.935 13.17-2.031 13.244-2.031 1.257 0 1.889 0 2.893.126 9.281 6.605 13.743 10.073 13.743 16.678-.001 8.414-7.101 13.64-18.528 13.64zm-.125-50.779c-11.132 0-15.752-14.633-15.752-22.468 0-3.984.906-7.042 2.77-9.351 2.023-2.531 5.487-4.166 8.825-4.166 10.221 0 15.873 13.738 15.873 23.233 0 1.498 0 6.055-3.148 9.22-2.117 2.113-5.56 3.532-8.568 3.532zm60.493 4.467H82.814V33.098a.5.5 0 0 0-.5-.5H77.08a.5.5 0 0 0-.5.5v12.125H64.473a.5.5 0 0 0-.5.5v5.304a.5.5 0 0 0 .5.5H76.58V63.73a.5.5 0 0 0 .5.5h5.234c.275 0 .5-.225.5-.5V51.525h12.168a.5.5 0 0 0 .5-.5v-5.302c0-.277-.223-.5-.5-.5z" />
    </symbol>
    <symbol id="svg-icon__google-plus" viewBox="0 0 56.6934 56.6934">
      <path d="M19.667 25.787c-.007 1.793 0 3.587.008 5.38 3.006.098 6.02.053 9.027.098-1.326 6.669-10.399 8.832-15.199 4.476-4.936-3.82-4.702-12.2.43-15.749 3.587-2.864 8.688-2.155 12.275.324 1.41-1.304 2.728-2.698 4.001-4.144-2.984-2.382-6.646-4.077-10.542-3.896-8.13-.272-15.606 6.85-15.741 14.98-.52 6.646 3.85 13.165 10.022 15.516 6.149 2.366 14.03.753 17.957-4.77 2.592-3.49 3.15-7.98 2.848-12.2-5.034-.038-10.06-.03-15.086-.015zm29.403-.023c-.015-1.5-.022-3.007-.03-4.506h-4.483c-.015 1.5-.03 2.999-.038 4.506-1.507.008-3.007.015-4.506.03v4.484l4.506.045c.015 1.5.015 3 .03 4.499h4.491c.008-1.5.015-3 .03-4.506 1.507-.015 3.007-.023 4.507-.038v-4.484c-1.5-.015-3.007-.015-4.507-.03z" />
    </symbol>
    <symbol id="svg-icon__heart" viewBox="0 0 30 28">
      <path d="M21.98 0C19.07 0 16.51 1.806 15 3.954 13.48 1.806 10.92 0 8.01 0 3.38 0 .02 3.786.02 9c-.03 9.907 14.41 18.84 14.56 18.908a.977.977 0 0 0 .86-.015C15.59 27.82 30 18.976 29.97 9c0-5.215-3.36-9-7.99-9zM15 25.81C12.53 24.17 2 16.705 2.02 9.004 2.02 4.88 4.49 2 8.01 2c2.4 0 5.01 1.88 6.07 4.373.15.368.51.608.92.608.39 0 .76-.24.91-.608C16.97 3.88 19.58 2 21.98 2c3.53 0 5.99 2.879 5.99 7.002v.003C27.99 16.71 17.46 24.17 15 25.809z" />
    </symbol>
    <symbol id="svg-icon__instagram" viewBox="0 0 56.693 56.693">
      <path d="M43.414 4.831H13c-5.283 0-9.581 4.297-9.581 9.58v30.415c0 5.283 4.298 9.58 9.581 9.58h30.415c5.283 0 9.58-4.297 9.58-9.58V14.41c-.001-5.283-4.298-9.579-9.581-9.579zm2.748 5.713l1.096-.004v8.403l-8.375.027-.029-8.402 7.308-.024zM21.131 24.53c1.588-2.197 4.164-3.638 7.076-3.638s5.488 1.441 7.074 3.638a8.677 8.677 0 0 1 1.652 5.088c0 4.811-3.918 8.725-8.727 8.725-4.812 0-8.726-3.914-8.726-8.725a8.683 8.683 0 0 1 1.651-5.088zm27.033 20.295a4.754 4.754 0 0 1-4.75 4.75H13a4.755 4.755 0 0 1-4.751-4.75V24.53h7.4a13.483 13.483 0 0 0-.998 5.088c0 7.473 6.08 13.557 13.556 13.557 7.475 0 13.555-6.084 13.555-13.557 0-1.799-.361-3.516-1-5.088h7.402v20.295z" />
    </symbol>
    <symbol id="svg-icon__letter" viewBox="0 0 13 11">
      <path
        fill="currentColor"
        d="M6.5 4.49L.83.02A.88.88 0 0 1 1 0h11l.17.02zM13 1v9a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V1C0 .89.02.78.06.68L6.5 5.76 12.94.68c.04.1.06.21.06.32z"
      />
    </symbol>
    <symbol id="svg-icon__libra" viewBox="0 0 38 28">
      <path d="M1 20a1 1 0 0 0-1 1v2.06C0 25.79 3.58 28 8 28s8-2.21 8-4.94V21a1 1 0 0 0-1-1H1zm13 3c0 1.35-2.71 3-6 3s-6-1.52-6-2.83V22h12v1zm0-3L9.41 9.91A1.55 1.55 0 0 0 8 9a1.55 1.55 0 0 0-1.41.91L2 20h2l4-9 4 9h2zm9 0a1 1 0 0 0-1 1v2.06c0 2.73 3.58 4.94 8 4.94s8-2.21 8-4.94V21a1 1 0 0 0-1-1H23zm13 3c0 1.35-2.71 3-6 3s-6-1.52-6-2.83V22h12v1zm0-3L31.41 9.91A1.55 1.55 0 0 0 30 9a1.55 1.55 0 0 0-1.41.91L24 20h2l4-9 4 9h2zM34.3 4a1 1 0 0 0-1.25.65 2.06 2.06 0 0 1-2 1.31h-1a8.31 8.31 0 0 1-2.77-1.43 14.36 14.36 0 0 0-5.39-2.51 3 3 0 0 0-5.67 0 14.36 14.36 0 0 0-5.39 2.51A8.31 8.31 0 0 1 8 6H7a2.06 2.06 0 0 1-2-1.31 1.003 1.003 0 0 0-1.91.61 4.09 4.09 0 0 0 4 2.7h1c1.16 0 2.41-.81 3.86-1.75a13.7 13.7 0 0 1 4.35-2.16 3 3 0 0 0 5.58 0 13.7 13.7 0 0 1 4.35 2.16c1.45.94 2.7 1.75 3.86 1.75h1a4.08 4.08 0 0 0 4-2.7A1 1 0 0 0 34.3 4zM19 4a1 1 0 1 1 1-1 1 1 0 0 1-1 1z" />
    </symbol>
    <symbol id="svg-icon__list" viewBox="0 0 15 11">
      <path d="M1 0h13c.55 0 1 .448 1 1v1c0 .552-.45 1-1 1H1c-.55 0-1-.448-1-1V1c0-.552.45-1 1-1zm0 4h13c.55 0 1 .448 1 1v1c0 .552-.45 1-1 1H1c-.55 0-1-.448-1-1V5c0-.552.45-1 1-1zm0 4h13c.55 0 1 .448 1 1v1c0 .552-.45 1-1 1H1c-.55 0-1-.448-1-1V9c0-.552.45-1 1-1z" />
    </symbol>
    <symbol id="svg-icon__odnoklassniki" viewBox="0 0 30 30">
      <path d="M22 15c-1 0-3 2-7 2s-6-2-7-2a2 2 0 0 0-2 2c0 1 .568 1.481 1 1.734C8.185 19.427 12 21 12 21l-4.25 5.438S7 27.373 7 28a2 2 0 0 0 2 2c1.021 0 1.484-.656 1.484-.656S14.993 23.993 15 24c.007-.007 4.516 5.344 4.516 5.344S19.979 30 21 30a2 2 0 0 0 2-2c0-.627-.75-1.562-.75-1.562L18 21s3.815-1.573 5-2.266c.432-.253 1-.734 1-1.734a2 2 0 0 0-2-2zM15 0a7 7 0 1 0 0 14 7 7 0 0 0 0-14zm0 10.5a3.5 3.5 0 1 1-.001-6.999A3.5 3.5 0 0 1 15 10.5z" />
    </symbol>
    <symbol id="svg-icon__payment" viewBox="0 0 27 22">
      <path d="M22-.01H4a4 4 0 0 0-4 4v14a4 4 0 0 0 4 4h18a4 4 0 0 0 4-4v-14a4 4 0 0 0-4-4zm-20 2h22v1H2v-1zm22 18H2v-16h22v5h1v6h-1v5z" />
      <path d="M26 8.28A1.91 1.91 0 0 0 25 8h-8a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2v-4a2 2 0 0 0-1-1.72zM25 15h-9V9h9v6z" />
      <path d="M18 10.99h2v2h-2v-2z" />
    </symbol>
    <symbol id="svg-icon__phone-big" viewBox="0 0 32 32">
      <path d="M23.5 32a7.33 7.33 0 0 1-2.45-.42A33.71 33.71 0 0 1 .34 10.33a7.35 7.35 0 0 1 2.53-8.1L4.5 1a5 5 0 0 1 7.48 1.69l2.67 5.18a3.49 3.49 0 0 1-.53 4l-.55.6a2 2 0 0 0-.3 2.21 9.45 9.45 0 0 0 4.09 4.09 2 2 0 0 0 2.21-.3l.61-.55a3.56 3.56 0 0 1 4-.54l5.18 2.66a5 5 0 0 1 1.28 8l-1.8 1.82A7.4 7.4 0 0 1 23.5 32zM7.5 3a2 2 0 0 0-1.21.41l-1.58 1.2a4.35 4.35 0 0 0-1.5 4.8 30.69 30.69 0 0 0 18.85 19.35 4.44 4.44 0 0 0 4.55-1l1.8-1.82a2 2 0 0 0-.51-3.18L22.72 20a.54.54 0 0 0-.56.08l-.61.56a5 5 0 0 1-5.62.75 12.42 12.42 0 0 1-5.38-5.38 5 5 0 0 1 .75-5.61l.55-.6a.49.49 0 0 0 .08-.56L9.26 4.06A2 2 0 0 0 7.5 3z" />
    </symbol>
    <symbol id="svg-icon__phone" viewBox="0 0 13 13">
      <path d="M12.1.5C11.6 0 10-.1 10 .2S8.6 3.5 8.6 3.8c0 .2.9 1.2 1.2 1.5.3.2-1.8 2.6-1.9 2.7 0 0-2.4 2.1-2.7 1.9C5 9.6 4 8.6 3.7 8.6 3.4 8.6.4 10 .1 10c-.3 0-.2 1.6.3 2.1.4.4 2.7 2.1 6.7-.8.4-.3 1.1-.8 2.2-1.9 1.1-1.1 1.6-1.8 1.9-2.2 3-4 1.4-6.3.9-6.7z" />
    </symbol>
    <symbol id="svg-icon__pointer" viewBox="0 0 10 13">
      <path d="M5 0c2.76 0 5 2.04 5 4.56 0 .19-.02.38-.04.56C9.54 8.68 5.4 12.8 5.4 12.8l-.22.16-.14.04-.14-.04h-.01a.73.73 0 0 1-.21-.16S.47 8.69.04 5.13C.02 4.94 0 4.75 0 4.56 0 2.04 2.24 0 5 0zm0 7.5c1.77 0 3.22-1.32 3.22-2.94 0-1.62-1.45-2.93-3.22-2.93-1.77 0-3.22 1.31-3.22 2.93S3.23 7.5 5 7.5z" />
    </symbol>
    <symbol id="svg-icon__refresh" viewBox="0 0 32 32">
      <path d="M31.605 1.729c-.264-.264-.576-.396-.938-.396s-.673.132-.937.396l-2.708 2.688c-1.486-1.403-3.188-2.49-5.104-3.261S18.029 0 16.001 0c-3.82 0-7.153 1.156-10 3.469S1.279 8.798.376 12.521v.146c0 .181.066.337.198.469s.288.198.469.198h4.146c.306 0 .514-.16.625-.479.583-1.389.951-2.201 1.104-2.437.972-1.583 2.264-2.826 3.875-3.729s3.347-1.354 5.208-1.354c2.792 0 5.215.951 7.271 2.854l-2.875 2.875c-.263.264-.396.577-.396.938s.132.674.396.937c.264.264.577.396.938.396h9.333c.361 0 .674-.132.938-.396s.395-.576.395-.937V2.668c0-.361-.131-.674-.395-.938zm-.793 16.938h-4c-.306 0-.514.16-.625.479-.583 1.389-.951 2.201-1.104 2.437-.972 1.583-2.264 2.827-3.875 3.729s-3.348 1.354-5.209 1.354c-1.347 0-2.646-.25-3.896-.75s-2.368-1.209-3.354-2.126l2.854-2.854c.264-.264.396-.577.396-.937s-.132-.674-.396-.937a1.28 1.28 0 0 0-.937-.396H1.332c-.361 0-.674.132-.938.396s-.396.576-.396.938v9.334c0 .361.132.674.396.937s.577.396.938.396.673-.133.937-.396l2.687-2.688c1.486 1.416 3.177 2.507 5.073 3.271S13.887 32 15.914 32c3.806 0 7.125-1.156 9.958-3.469s4.694-5.33 5.584-9.052a.64.64 0 0 0-.178-.615.643.643 0 0 0-.469-.197z" />
    </symbol>
    <symbol id="svg-icon__remove" viewBox="0 0 32 32">
      <path d="M23.326 16l7.919-7.919c.503-.503.754-1.113.754-1.832s-.252-1.329-.754-1.832L27.582.753c-.503-.503-1.113-.754-1.831-.754s-1.329.251-1.832.754L16 8.672 8.081.753C7.579.25 6.968-.001 6.249-.001S4.92.25 4.417.753L.754 4.417C.251 4.919 0 5.53 0 6.249s.251 1.329.754 1.832L8.673 16 .754 23.919C.251 24.422 0 25.032 0 25.751s.251 1.329.754 1.831l3.663 3.663c.503.503 1.114.754 1.832.754s1.329-.252 1.832-.754L16 23.326l7.92 7.919a2.493 2.493 0 0 0 1.832.754c.718 0 1.329-.252 1.831-.754l3.663-3.663c.503-.502.754-1.113.754-1.831s-.251-1.329-.754-1.832L23.327 16z" />
    </symbol>
    <symbol id="svg-icon__search" viewBox="0 0 32 32">
      <path d="M31.289 27.808l-6.596-6.596c1.59-2.295 2.385-4.853 2.385-7.673 0-1.833-.356-3.587-1.068-5.26s-1.673-3.115-2.885-4.327-2.654-2.173-4.326-2.885C17.126.355 15.372 0 13.539 0s-3.587.356-5.26 1.067c-1.673.711-3.115 1.673-4.327 2.885S1.779 6.606 1.067 8.279C.355 9.952 0 11.705 0 13.539s.356 3.586 1.067 5.259c.711 1.673 1.673 3.115 2.885 4.327s2.654 2.173 4.327 2.885a13.294 13.294 0 0 0 5.26 1.067c2.821 0 5.379-.795 7.673-2.385l6.596 6.577c.462.487 1.038.731 1.731.731.667 0 1.244-.244 1.731-.731s.731-1.064.731-1.731a2.36 2.36 0 0 0-.711-1.731zm-11.664-8.183c-1.686 1.686-3.715 2.529-6.087 2.529s-4.4-.843-6.086-2.529-2.529-3.714-2.529-6.086.843-4.401 2.529-6.086c1.686-1.686 3.715-2.529 6.086-2.529s4.4.843 6.087 2.529c1.686 1.686 2.529 3.715 2.529 6.086s-.843 4.4-2.529 6.086z" />
    </symbol>
    <symbol id="svg-icon__shopping-cart" viewBox="0 0 32 32">
      <path d="M24.615 27.077c0 .68.241 1.26.721 1.74s1.061.721 1.741.721c.679 0 1.259-.241 1.74-.721s.721-1.06.721-1.74-.24-1.26-.721-1.741a2.373 2.373 0 0 0-1.74-.721c-.68 0-1.26.241-1.741.721s-.721 1.061-.721 1.741z" />
      <path d="M31.634 5.288a1.181 1.181 0 0 0-.865-.365H7.673c-.013-.09-.042-.253-.087-.49l-.106-.567c-.026-.141-.074-.311-.144-.509s-.154-.356-.25-.471a1.216 1.216 0 0 0-.932-.423H1.231c-.334 0-.622.122-.866.365S0 3.36 0 3.693s.122.622.365.865c.244.244.532.365.866.365h3.923L8.558 20.75c-.025.051-.157.295-.394.731s-.426.817-.567 1.144c-.141.327-.212.58-.212.76 0 .333.122.622.365.866.244.243.532.365.866.365h19.692c.333 0 .622-.122.865-.365s.366-.532.366-.866-.122-.622-.366-.865a1.184 1.184 0 0 0-.865-.366H10.616c.308-.616.462-1.025.462-1.23 0-.129-.016-.27-.048-.423s-.071-.324-.115-.51a4.443 4.443 0 0 1-.086-.413l20.077-2.346c.32-.039.583-.176.788-.414s.307-.509.307-.817V6.155c0-.333-.122-.622-.365-.866z" />
      <path d="M7.384 27.077c0 .68.24 1.26.721 1.74s1.061.721 1.741.721c.679 0 1.26-.241 1.74-.721s.721-1.06.721-1.74-.24-1.26-.721-1.741c-.481-.48-1.061-.721-1.74-.721s-1.26.241-1.741.721a2.371 2.371 0 0 0-.721 1.741z" />
    </symbol>
    <symbol id="svg-icon__star" viewBox="0 0 520.08 494.03">
      <path d="M497.58 198.08c0-7-162.31-27-162.31-27S265.75 22.51 260.04 22.51s-75.23 148.6-75.23 148.6S22.5 191.04 22.5 198.08c0 4 117.06 114.77 117.06 114.77s-30.12 155.84-28.12 158.6 148.6-74.67 148.6-74.67 146.33 77.43 148.33 74.67-27.87-158.59-27.87-158.59 117.08-110.59 117.08-114.78z" />
    </symbol>
    <symbol id="svg-icon__thumb-down" viewBox="0 0 32 32">
      <path d="M29.826 14.115c.116-.423.173-.865.173-1.327 0-.987-.244-1.91-.731-2.769.038-.269.058-.545.058-.827 0-1.295-.385-2.436-1.154-3.423v-.096c.013-1.756-.535-3.141-1.644-4.154S23.967 0 22.173 0h-2.154c-1.346 0-2.628.141-3.846.423s-2.647.705-4.288 1.269c-1.487.513-2.372.769-2.654.769H3.693c-.679 0-1.26.24-1.74.721s-.721 1.061-.721 1.74V17.23c0 .68.24 1.259.721 1.74s1.061.721 1.74.721h5.269c.461.308 1.34 1.302 2.634 2.981.756.988 1.442 1.801 2.058 2.443.243.269.442.68.596 1.231s.266 1.093.337 1.625c.071.532.234 1.112.49 1.74s.602 1.154 1.038 1.578c.5.474 1.077.711 1.731.711 1.077 0 2.045-.208 2.904-.625s1.513-1.067 1.962-1.952c.449-.872.673-2.064.673-3.577 0-1.192-.308-2.423-.923-3.692h3.384c1.334 0 2.487-.487 3.462-1.462s1.461-2.122 1.461-3.442a5.562 5.562 0 0 0-.942-3.135zM5.789 7.02c-.244.244-.532.365-.865.365s-.622-.122-.866-.365c-.244-.244-.365-.532-.365-.866s.122-.622.365-.865c.244-.244.532-.365.866-.365s.622.122.865.365c.244.244.365.532.365.865s-.122.622-.365.866zm21.768 11.932c-.5.494-1.07.74-1.712.74h-6.769c0 .744.307 1.766.923 3.067s.923 2.33.923 3.087c0 1.256-.205 2.186-.615 2.788s-1.231.904-2.462.904c-.333-.333-.577-.879-.731-1.635s-.349-1.561-.586-2.413c-.237-.852-.618-1.555-1.144-2.106-.282-.295-.776-.878-1.481-1.75-.051-.064-.199-.256-.442-.577s-.445-.583-.606-.788c-.16-.205-.382-.478-.664-.817s-.539-.622-.769-.846c-.231-.224-.477-.452-.74-.683s-.519-.404-.769-.519-.477-.173-.683-.173h-.615V4.923h.615c.167 0 .368-.019.606-.058s.448-.08.634-.125.43-.115.731-.212a37.403 37.403 0 0 0 1.356-.462c.308-.11.494-.176.558-.202 2.705-.936 4.897-1.404 6.577-1.404h2.461c1.116 0 1.987.263 2.615.788s.942 1.333.942 2.423c0 .333-.032.692-.096 1.077.384.205.689.542.913 1.009s.337.939.337 1.413c0 .474-.115.917-.346 1.327.68.641 1.019 1.404 1.019 2.288 0 .321-.064.676-.192 1.067s-.289.696-.481.913c.41.013.753.314 1.029.904s.414 1.109.414 1.558c0 .654-.25 1.227-.75 1.721z" />
    </symbol>
    <symbol id="svg-icon__thumb-up" viewBox="0 0 32 32">
      <path d="M29.826 17.885a5.562 5.562 0 0 0 .942-3.135c0-1.321-.487-2.468-1.461-3.443-.975-.974-2.128-1.461-3.462-1.461H22.46c.615-1.269.923-2.5.923-3.692 0-1.5-.225-2.692-.673-3.577S21.607 1.042 20.748.625C19.889.208 18.921 0 17.844 0c-.654 0-1.231.237-1.731.712-.551.538-.949 1.231-1.192 2.077s-.439 1.657-.587 2.433c-.147.776-.375 1.324-.683 1.644a36.95 36.95 0 0 0-2.058 2.462c-1.295 1.679-2.173 2.673-2.634 2.981H3.69c-.679 0-1.26.241-1.74.721s-.721 1.061-.721 1.74v12.307c0 .68.24 1.259.721 1.74s1.061.721 1.74.721h5.538c.282 0 1.167.256 2.654.769 1.577.551 2.965.971 4.163 1.26s2.413.433 3.644.433h2.481c1.808 0 3.262-.516 4.365-1.548s1.647-2.439 1.635-4.221c.769-.987 1.154-2.128 1.154-3.423 0-.282-.019-.558-.058-.827a5.524 5.524 0 0 0 .731-2.769c0-.462-.058-.904-.173-1.327zM5.789 26.711c-.244.243-.532.366-.865.366s-.622-.122-.865-.366c-.244-.243-.365-.532-.365-.866s.122-.622.365-.865c.244-.244.532-.365.865-.365s.622.122.865.365c.244.244.365.532.365.865s-.122.622-.365.866zm22.105-10.383c-.276.59-.619.891-1.029.904.192.218.353.523.481.913s.192.747.192 1.067c0 .884-.339 1.647-1.019 2.288.231.41.346.853.346 1.327s-.112.946-.337 1.413c-.224.467-.529.804-.913 1.009.064.385.096.744.096 1.077 0 2.141-1.231 3.211-3.693 3.211h-2.326c-1.68 0-3.872-.468-6.577-1.404a100.938 100.938 0 0 0-1.241-.442c-.148-.052-.372-.125-.673-.221s-.545-.167-.731-.212c-.186-.045-.397-.086-.634-.125s-.439-.058-.606-.058h-.615V14.768h.615c.205 0 .433-.058.683-.173s.507-.288.769-.519c.263-.231.509-.458.74-.683s.487-.506.769-.846a37.543 37.543 0 0 0 1.269-1.606c.243-.32.391-.513.442-.577.705-.872 1.199-1.455 1.481-1.75.526-.551.907-1.253 1.144-2.106s.433-1.657.586-2.413c.154-.756.398-1.301.731-1.635 1.23 0 2.051.301 2.461.904s.615 1.532.615 2.788c0 .756-.308 1.785-.923 3.086s-.923 2.324-.923 3.067h6.769c.642 0 1.212.247 1.712.741s.751 1.067.751 1.721c0 .449-.138.968-.414 1.558z" />
    </symbol>
    <symbol id="svg-icon__tick" viewBox="0 0 14 11">
      <path
        fill="currentColor"
        d="M11.66 1.59L6 7.24 4.59 8.66.34 4.4 1.76 3l2.83 2.83L10.24.17z"
      />
      &gt;
    </symbol>
    <symbol id="svg-icon__tooltip" viewBox="0 0 14 14">
      <path d="M7.07 0a7.06 7.06 0 1 0 7 7.06 7.07 7.07 0 0 0-7-7.06zm1.68 10.78a5.84 5.84 0 0 1-.75.18 2.26 2.26 0 0 1-.77.12 1.54 1.54 0 0 1-1-.33 1.16 1.16 0 0 1-.46-.84 2.8 2.8 0 0 1 0-.4c0-.13.05-.51.09-.68l.64-1.51c0-.15.08-.3.11-.44a1.57 1.57 0 0 0 0-.28.61.61 0 0 0-.22-.43 1.45 1.45 0 0 0-.7-.13 1.24 1.24 0 0 0-.37.06L5 6.21v-.62c.31-.13.89-.3 1.16-.39a2.9 2.9 0 0 1 .79-.13 1.51 1.51 0 0 1 1 .32 1.46 1.46 0 0 1 .55.91v.38a3.3 3.3 0 0 1-.09.49l-.64 1.5a3.72 3.72 0 0 0-.11.45 5 5 0 0 0 0 .6.55.55 0 0 0 .15.45.56.56 0 0 0 .43.2.93.93 0 0 0 .63-.09zm0-6.92a1 1 0 0 1-.67.29 1 1 0 0 1-.66-.29.87.87 0 0 1 0-1.28.91.91 0 0 1 .66-.28.93.93 0 0 1 .67.26.91.91 0 0 1 .27.66.87.87 0 0 1-.32.64z" />
    </symbol>
    <symbol id="svg-icon__truck" viewBox="0 0 32 32">
      <path d="M31.649 3.314a1.141 1.141 0 0 0-.833-.352H11.853c-.321 0-.599.118-.833.352s-.352.513-.352.833v3.556H7.705c-.333 0-.695.08-1.084.241s-.701.358-.935.593l-3.667 3.667c-.16.16-.299.346-.417.556s-.204.398-.259.565c-.055.167-.096.395-.12.685s-.04.503-.046.639c-.006.136-.006.37 0 .704s.009.549.009.648v5.926c-.321 0-.599.117-.833.352s-.352.512-.352.833c0 .185.025.349.074.491s.133.256.25.343c.117.087.219.157.305.213s.232.092.435.111l.417.037c.074.006.231.006.472 0l.417-.009h1.185c0 1.308.463 2.426 1.389 3.352s2.043 1.389 3.352 1.389c1.309 0 2.426-.463 3.352-1.389s1.389-2.043 1.389-3.352h7.111c0 1.308.463 2.426 1.389 3.352s2.043 1.389 3.352 1.389c1.309 0 2.426-.463 3.352-1.389s1.389-2.043 1.389-3.352l.416.009c.24.006.398.006.472 0l.417-.037c.204-.018.349-.055.435-.111s.188-.126.305-.213c.117-.087.201-.201.25-.343s.074-.306.074-.491V4.149c0-.321-.117-.599-.351-.834zM9.963 25.963c-.469.469-1.025.704-1.667.704s-1.198-.235-1.667-.704c-.469-.469-.704-1.025-.704-1.667s.235-1.198.704-1.667c.469-.469 1.025-.704 1.667-.704s1.198.235 1.667.704c.469.469.704 1.025.704 1.667s-.235 1.198-.704 1.667zm.704-11.148H3.556v-.555c0-.161.055-.296.167-.408l3.611-3.611a.555.555 0 0 1 .408-.167h2.926v4.741zm15.888 11.148c-.469.469-1.025.704-1.667.704s-1.198-.235-1.667-.704c-.469-.469-.704-1.025-.704-1.667s.235-1.198.704-1.667c.469-.469 1.025-.704 1.667-.704s1.198.235 1.667.704c.469.469.704 1.025.704 1.667s-.234 1.198-.704 1.667z" />
    </symbol>
    <symbol id="svg-icon__twitter" viewBox="0 0 56.693 56.693">
      <path d="M52.837 15.065a20.11 20.11 0 0 1-5.805 1.591 10.125 10.125 0 0 0 4.444-5.592 20.232 20.232 0 0 1-6.418 2.454 10.093 10.093 0 0 0-7.377-3.192c-5.581 0-10.106 4.525-10.106 10.107 0 .791.089 1.562.262 2.303-8.4-.422-15.848-4.445-20.833-10.56a10.055 10.055 0 0 0-1.368 5.082c0 3.506 1.784 6.6 4.496 8.412a10.078 10.078 0 0 1-4.578-1.265l-.001.128c0 4.896 3.484 8.98 8.108 9.91a10.162 10.162 0 0 1-4.565.172c1.287 4.015 5.019 6.938 9.441 7.019a20.276 20.276 0 0 1-12.552 4.327c-.815 0-1.62-.048-2.411-.142a28.6 28.6 0 0 0 15.493 4.541c18.591 0 28.756-15.4 28.756-28.756 0-.438-.009-.875-.028-1.309a20.47 20.47 0 0 0 5.042-5.23z" />
    </symbol>
    <symbol id="svg-icon__vk-logo" viewBox="0 0 304.36 304.36">
      <path
        d="M261.945 175.576c10.096 9.857 20.752 19.131 29.807 29.982 4 4.822 7.787 9.798 10.684 15.394 4.105 7.955.387 16.709-6.746 17.184l-44.34-.02c-11.436.949-20.559-3.655-28.23-11.474-6.139-6.253-11.824-12.908-17.727-19.372-2.42-2.642-4.953-5.128-7.979-7.093-6.053-3.929-11.307-2.726-14.766 3.587-3.523 6.421-4.322 13.531-4.668 20.687-.475 10.441-3.631 13.186-14.119 13.664-22.414 1.057-43.686-2.334-63.447-13.641-17.422-9.968-30.932-24.04-42.691-39.971-22.895-31.021-40.428-65.108-56.186-100.15C-2.01 76.458.584 72.22 9.295 72.07c14.465-.281 28.928-.261 43.41-.02 5.879.086 9.771 3.458 12.041 9.012 7.826 19.243 17.402 37.551 29.422 54.521 3.201 4.518 6.465 9.036 11.113 12.216 5.142 3.521 9.057 2.354 11.476-3.374 1.535-3.632 2.207-7.544 2.553-11.434 1.146-13.383 1.297-26.743-.713-40.079-1.234-8.323-5.922-13.711-14.227-15.286-4.238-.803-3.607-2.38-1.555-4.799 3.564-4.172 6.916-6.769 13.598-6.769h50.111c7.889 1.557 9.641 5.101 10.721 13.039l.043 55.663c-.086 3.073 1.535 12.192 7.07 14.226 4.43 1.448 7.35-2.096 10.008-4.905 11.998-12.734 20.561-27.783 28.211-43.366 3.395-6.852 6.314-13.968 9.143-21.078 2.096-5.276 5.385-7.872 11.328-7.757l48.229.043c1.43 0 2.877.021 4.262.258 8.127 1.385 10.354 4.881 7.844 12.817-3.955 12.451-11.65 22.827-19.174 33.251-8.043 11.129-16.645 21.877-24.621 33.072-7.328 10.223-6.746 15.376 2.357 24.255zm0 0"
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </symbol>
    <symbol id="svg-icon__vkontakte" viewBox="0 0 30 30">
      <path
        clipRule="evenodd"
        d="M16 24c1 0 1-1.441 1-2 0-1 1-2 2-2s2.715 1.715 4 3c1 1 1 1 2 1h3s2-.076 2-2c0-.625-.685-1.685-3-4-2-2-3.026-.967 0-5 1.843-2.456 3.184-4.681 2.954-5.323C29.734 7.064 24.608 6.088 24 7c-2 3-2.367 3.735-3 5-1 2-1.099 3-2 3-.909 0-1-1.941-1-3 0-3.306.479-5.644-1-6h-3c-1.61 0-3 1-3 1s-1.241.968-1 1c.298.04 2-.414 2 1v2s.009 4-1 4c-1 0-3-4-5-7-.785-1.177-1-1-2-1-1.072 0-1.999.042-3 .042-1 0-1.128.637-1 .958 2 5 3.437 8.14 7.237 12.096C10.722 23.725 13.05 23.918 15 24c.5.021 0 0 1 0z"
        fillRule="evenodd"
      />
    </symbol>
    <symbol id="svg-icon__yandex-logo" viewBox="0 0 320 320">
      <path d="M227.184 0h-45.466c-46.063 0-92.541 34.014-92.541 110.004 0 39.361 16.683 70.017 47.263 87.451L80.468 298.762c-2.653 4.791-2.722 10.221-.185 14.525C82.76 317.49 87.288 320 92.395 320h28.311c6.432 0 11.448-3.109 13.852-8.555L187.037 208.8h3.83v98.407c0 6.935 5.852 12.793 12.778 12.793h24.735c7.767 0 13.191-5.424 13.191-13.19V14.002C241.572 5.758 235.656 0 227.184 0zm-36.316 163.204h-6.756c-26.197 0-41.837-21.384-41.837-57.202 0-44.537 19.757-60.405 38.247-60.405h10.346v117.607z" />
    </symbol>
    <symbol id="svg-icon__zoom-in" viewBox="0 0 32 32">
      <path d="M31.289 27.808l-6.596-6.596c1.59-2.295 2.385-4.852 2.385-7.673 0-1.833-.356-3.587-1.068-5.26s-1.673-3.115-2.885-4.327-2.654-2.173-4.326-2.885C17.126.355 15.372 0 13.539 0s-3.587.356-5.26 1.067c-1.673.711-3.115 1.673-4.327 2.885S1.779 6.606 1.067 8.279C.355 9.952 0 11.705 0 13.539s.356 3.586 1.067 5.259c.711 1.673 1.673 3.115 2.885 4.327s2.654 2.173 4.327 2.885a13.294 13.294 0 0 0 5.26 1.067c2.821 0 5.379-.795 7.673-2.385l6.596 6.577c.462.487 1.038.731 1.731.731.68 0 1.259-.24 1.74-.721s.721-1.061.721-1.74-.237-1.256-.711-1.731zm-11.664-8.183c-1.686 1.686-3.715 2.529-6.087 2.529s-4.4-.843-6.086-2.529-2.529-3.714-2.529-6.086.843-4.401 2.529-6.086c1.686-1.686 3.715-2.529 6.086-2.529s4.4.843 6.087 2.529c1.686 1.686 2.529 3.715 2.529 6.086s-.843 4.4-2.529 6.086z" />
      <path d="M19.078 12.308H14.77V8c0-.167-.061-.311-.183-.433s-.266-.183-.433-.183h-1.231c-.167 0-.311.061-.433.183s-.183.266-.183.433v4.308H7.999c-.167 0-.311.061-.433.183s-.183.266-.183.433v1.231c0 .167.061.311.183.433s.266.183.433.183h4.308v4.308c0 .167.061.311.183.433s.266.182.433.182h1.231c.167 0 .311-.061.433-.182s.183-.266.183-.433v-4.308h4.308c.167 0 .311-.061.433-.183s.182-.266.182-.433v-1.231c0-.167-.061-.311-.182-.433s-.266-.183-.433-.183z" />
    </symbol>
    <symbol id="svg-icon__zoom-out" viewBox="0 0 32 32">
      <path d="M31.289 27.808l-6.596-6.596c1.59-2.295 2.385-4.852 2.385-7.673 0-1.833-.356-3.587-1.068-5.26s-1.673-3.115-2.885-4.327-2.654-2.173-4.326-2.885C17.126.355 15.372 0 13.539 0s-3.587.356-5.26 1.067c-1.673.711-3.115 1.673-4.327 2.885S1.779 6.606 1.067 8.279C.355 9.952 0 11.705 0 13.539s.356 3.586 1.067 5.259c.711 1.673 1.673 3.115 2.885 4.327s2.654 2.173 4.327 2.885a13.294 13.294 0 0 0 5.26 1.067c2.821 0 5.379-.795 7.673-2.385l6.596 6.577c.462.487 1.038.731 1.731.731.68 0 1.259-.24 1.74-.721s.721-1.061.721-1.74-.237-1.256-.711-1.731zm-11.664-8.183c-1.686 1.686-3.715 2.529-6.087 2.529s-4.4-.843-6.086-2.529-2.529-3.714-2.529-6.086.843-4.401 2.529-6.086c1.686-1.686 3.715-2.529 6.086-2.529s4.4.843 6.087 2.529c1.686 1.686 2.529 3.715 2.529 6.086s-.843 4.4-2.529 6.086z" />
      <path d="M19.077 12.308H8c-.167 0-.311.061-.433.183s-.183.266-.183.433v1.231c0 .167.061.311.183.433s.266.183.433.183h11.077c.167 0 .311-.061.433-.183s.182-.266.182-.433v-1.231c0-.167-.061-.311-.182-.433s-.266-.183-.433-.183z" />
    </symbol>
  </svg>
);

export default Sprite;
